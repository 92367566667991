import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, mapTo, take, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {environment} from '../../../environments/environment';
import {UserDataToken} from '../models/user-data-token';
import {AuthState} from '../../xstore/states/auth.state';
import {LoginUser, LogoutUser, SetToken} from '../../xstore/actions/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  @Select(AuthState.getUser) user$: Observable<UserDataToken>;
  currentUser: UserDataToken = {} as UserDataToken;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store
  ) {
    // --
    this.user$.subscribe(_ => {
      this.currentUser = _;
    });
  }

  login(user: { email: string, password: string }): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}api/login`, user)
      .pipe(
        tap((userData: UserDataToken) => this.doLoginUser(userData)),
        mapTo(true),
        catchError(error => {
          console.log(error);
          return of(false);
        }));
  }

  doLoginUser(userData: UserDataToken): void {
    console.log(JSON.stringify(userData));
    // this.currentUser.token = userData.token;
    // --
    this.store.dispatch(new LoginUser(userData));
  }


  isLoggedIn() {
    if (this.currentUser?.token) {
      return !!this.currentUser.token;
    }
    return false;
  }

  isContact() {
    return !!this.currentUser?.user.is_client;
  }

  logout() {
    // Ez kell, ha kidobja a session miatt
    this.store.dispatch(new LogoutUser());
    this.router.navigate(['auth/login']);
  }

  doServerLogout(): Promise<any> {
    // Ezt kell meghivni ha jobb fent a kilépésre nyom
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.apiUrl}api/logout/${this.user.sessionKey}`)
        .pipe(take(1))
        .subscribe(res => {
          this.logout();
          localStorage.clear();
          resolve(res);
        }, error => {
          reject(error);
        });
    });
  }

  // refreshToken() {
  //   return this.http.post<any>(`${environment.apiUrl}api/refresh`, {
  //     token: this.getJwtToken
  //   }).pipe(tap((data) => {
  //     this.store.dispatch(new SetToken(data.access_token));
  //   }));
  // }

  get getJwtToken() {
    return this.currentUser.token;
  }

  get user() {
    return this.currentUser;
  }

}
