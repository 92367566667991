<div fxLayout="column">
  <span mat-dialog-title>{{data?.action === 'set' ? 'Set' : 'Update'}} status</span>
  <div [formGroup]="form" mat-dialog-content>
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option *ngIf="data?.type === 0" value="1">Successfully loaded</mat-option>
        <mat-option *ngIf="data?.type === 1" value="1">Successfully delivered</mat-option>
        <mat-option *ngIf="data?.type === 2" value="1">Successful clearance</mat-option>
        <mat-option value="2">No information</mat-option>
        <mat-option value="3">Late</mat-option>
        <mat-option value="4">Truck malfunction</mat-option>
        <mat-option value="5">Damage event happened</mat-option>
        <mat-option value="6">Failed delivery</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Status Description</mat-label>
      <input aria-label="status description" formControlName="status_data" matInput required type="text"/>
    </mat-form-field>
  </div>
  <div fxFlex fxLayoutAlign="flex-end center" mat-dialog-actions>
    <button (click)="send()" [disabled]="!this.form.valid" color="primary" mat-raised-button>
      {{data?.action || 'Set'}}
      status
    </button>
  </div>
</div>