<div class="grid-container">
  <div fxLayoutAlign="flex-end center">
  <button style="color:gray;" mat-icon-button (click)="refresh()" aria-label="Toggle important" ngx-tooltip=""><mat-icon>refresh</mat-icon></button>
  </div>


    <img style="width: 450px;" src=" assets/img/maintrance.gif" alt="Maintenance">

  <div style="padding-left: 30px; padding-right: 30px; padding-bottom: 30px" class="secondary-container">
    <span class="secondary-text"> Oops! The developers are now working!</span>
    <div class="timer">
      <h4>After this time, we are expected to complete:</h4>
      <span id="days"> {{daysToDday}} </span>Day(s)
      <span id="hours"> {{hoursToDday}} </span>Hrs
      <span id="minutes"> {{minutesToDday}} </span>Min
      <span id="seconds"> {{secondsToDday}} </span>S
    </div>
  </div>

<!--  <div style="width: 100%; padding-top: 15px; padding-bottom: 35px;">-->
<!--&lt;!&ndash;    <div style="width: 50%;float:left;">&ndash;&gt;-->
<!--&lt;!&ndash;      <button mat-button class="issues-text" (click)="openDashboard()">&ndash;&gt;-->
<!--&lt;!&ndash;        Back to dashboard&ndash;&gt;-->
<!--&lt;!&ndash;      </button>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div style="width: 50%;float:right; padding-left: 65px">&ndash;&gt;-->
<!--&lt;!&ndash;      <button mat-raised-button color="accent" style="background-color: #1a73e8;" class="submit-button"&ndash;&gt;-->
<!--&lt;!&ndash;              aria-label="Refresh"&ndash;&gt;-->
<!--&lt;!&ndash;      (click)="refresh()">&ndash;&gt;-->
<!--&lt;!&ndash;        Refresh&ndash;&gt;-->
<!--&lt;!&ndash;      </button>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
</div>