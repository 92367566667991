import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './shared/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // private isRefreshing = false;
  // private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('INTERCEPTOR ACTIVE');

    // Ha login | logout, ahhoz nem kell header, return
    if (request.url.includes('login')) {
      return next.handle(request);
    }

    if (this.authService.getJwtToken) {
      request = this.addToken(request, this.authService.getJwtToken);
    }

    return next.handle(request).pipe(catchError(error => {
      // if (error instanceof HttpErrorResponse && error.status === 401) {
      //   return this.handle401Error(request, next);
      // } else {
        return throwError(error);
      // }
    }));
  }

  addToken(request: HttpRequest<any>, token: string) {
    // console.log('hozzáadom req.-headerbe a tokent');
    // console.log(token);
    // --
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  // handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);
  //
  //     return this.authService.refreshToken().pipe(
  //       switchMap((userData: { access_token: string, expires_in: number, token_type: string }) => {
  //         this.isRefreshing = false;
  //         this.refreshTokenSubject.next(userData.access_token);
  //         return next.handle(this.addToken(request, userData.access_token));
  //       }));
  //
  //   } else {
  //     return this.refreshTokenSubject.pipe(
  //       filter(token => token != null),
  //       take(1),
  //       switchMap(jwt => {
  //         return next.handle(this.addToken(request, jwt));
  //       }));
  //   }
  // }
}
