import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Pusher from 'pusher-js';
import {AuthService} from './auth.service';
import {environment} from '../../../environments/environment';
import {NoPermissionService} from '../dialogs/no-permission/no-permission.service';
import {InvalidSessionService} from '../dialogs/invalid-session/invalid-session.service';
import {LateStatusService} from '../dialogs/late-status/late-status.service';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  addressChannel: any;
  usersChannel: any;
  companiesChannel: any;
  mandatesChannel: any;
  maintenanceChannel: any;
  notificationChannel: any;
  sessionChanel: any;
  excelInvoiceChanel: any;
  // --
  pusher: any;
  benceChanel: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private noPermissionService: NoPermissionService,
    private invalidSession: InvalidSessionService,
  ) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
    });

    this.addressChannel = this.pusher.subscribe('adress');
    this.usersChannel = this.pusher.subscribe('users');
    this.companiesChannel = this.pusher.subscribe('companies');
    this.mandatesChannel = this.pusher.subscribe('mandates');
    this.maintenanceChannel = this.pusher.subscribe('maintenance');
    this.excelInvoiceChanel = this.pusher.subscribe('excelinvoice');
  }

  subscribeNotificationChannel() {
    this.notificationChannel = this.pusher.subscribe('not-' + this.getUserId());
  }

  subscribeSessionChannel() {
    console.log('SUBSCRIBED TO SESSSION CHANEL WITH SESSION KEY: ', this.getSessionKey());
    this.sessionChanel = this.pusher.subscribe('session-' + this.getSessionKey());
    this.sessionChanel.bind('expired', (_: any) => this.invalidSession.open());
  }

  subscribePermissionSyncChannel() {
    this.benceChanel = this.pusher.subscribe('auth-' + this.getUserId());
    this.benceChanel.bind('r-auth', (_: { p: string }) => {
      this.noPermissionService.open(_.p);
    });
  }

  getUserId() {
    return this.authService.user.user.id;
  }

  getSessionKey(): string {
    return this.authService.user.sessionKey;
  }


  // getAll() {
  //   return this.http.get<User[]>(`${environment.apiUrl}/api/users`);
  // }
}
