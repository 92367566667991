import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SetDefaultTableColumns, SetSelectedCompanyView, SetSelectedTableColumns} from '../actions/companies.actions';

export class CompaniesStateModel {
  // 0 => NATIVE || 1 => TABLE
  selectedCompanyView: number;
  selectedTableColumns: string[];
}

@State<CompaniesStateModel>({
  name: 'CompaniesState',
  defaults: {
    selectedCompanyView: 1,
    selectedTableColumns: ['tipus', 'cegnev', 'orszag', 'varos', 'iranyitoszam', 'address'],
  } as CompaniesStateModel,
})

@Injectable()
export class CompaniesState {
  @Selector()
  static getSelectedCompanyView(state: CompaniesStateModel): any {
    return state.selectedCompanyView;
  }

  @Selector()
  static getSelectedTableColumns(state: CompaniesStateModel): any {
    return state.selectedTableColumns;
  }

  @Action(SetSelectedCompanyView)
  setSelectedCompanyView({getState, patchState}: StateContext<CompaniesStateModel>, {payload}: SetSelectedCompanyView) {
    return patchState({
      selectedCompanyView: payload,
    });
  }

  @Action(SetSelectedTableColumns)
  setSelectedTableColumns({getState, patchState}: StateContext<CompaniesStateModel>, {payload}: SetSelectedTableColumns) {
    return patchState({
      selectedTableColumns: payload,
    });
  }
  @Action(SetDefaultTableColumns)
  setDefaultTableColumns({getState, patchState}: StateContext<CompaniesStateModel>) {
    return patchState({
      // company name, country, city, irsz, address
      selectedTableColumns: ['tipus', 'cegnev', 'orszag', 'varos', 'iranyitoszam', 'address'],
    });
  }
}
