import {Component, Inject, OnInit} from '@angular/core';
import {fuseAnimations} from '../../../../@fuse/animations';
import {BaseComponent} from '../../components/base/base.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormErrors} from '../../enums/form-errors-enum';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-share-pdf-send-dialog',
  templateUrl: './share-pdf-send-dialog.component.html',
  styleUrls: ['./share-pdf-send-dialog.component.scss'],
  animations: fuseAnimations,
})
export class SharePdfSendDialogComponent extends BaseComponent implements OnInit {

  constructor(
    public snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<SharePdfSendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: { doc_type: number, doc_id: number },
  ) {
    super(snackBar);
    this.shareFileForm = SharePdfSendDialogComponent.createShareFileForm();
  }
  shareFileForm: FormGroup;
  filteredContacts: Observable<any>;

  private static createShareFileForm() {
    return new FormGroup ({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      temp: new FormControl(false),
      show_creator: new FormControl(false),
    });
  }

  ngOnInit(): void {
  }

  sendFile() {
    console.log(this.shareFileForm.value);
    console.log(this._data.doc_id);      // #TODO valamiert a doc_id-n bellül-i data-ba kerülnek az adatok és nem értem hogyan
  }

  getErrorMessage(control: AbstractControl) {
    if (control.hasError('required')) {
      return FormErrors.REQUIRED;
    } else if (control.hasError('email')) {
      return FormErrors.EMAIL;
    }
  }
}
