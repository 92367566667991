export class ProjectDashboardDb {
  public static projects = [
    {
      'name': 'Carimmo Handels AG'
    },
    {
      'name': 'Carimmo Handels AG RS d.o.o. '
    },

  ];

  public static widgets = {
    'widget1': {
      'ranges': {
        'DY': 'Yesterday',
        'DT': 'Today',
        'DTM': 'Tomorrow'
      },
      'currentRange': 'DT',
      'data': {
        'label': 'Befejezett megbízás',
        'count': {
          'DY': 0,
          'DT': 0,
          'DTM': 0
        },
        'extra': {
          'label': 'Előző Héten',
          'count': {
            'DY': 0,
            'DT': 0,
            'DTM': '-'
          }

        }
      },
      'detail': 'Ez a widget megmutatja, hogy hány olyan megbízással van amit sikeresen befejeztünk'
    },
    'widget2': {
      'title': 'Aktív Megbízások',
      'data': {
        'label': 'Megbízás',
        'count': 4,
        'extra': {
          'label': 'Ebből szállító kirendelve',
          'count': 2
        }
      },
      'detail': 'Ez a widget megmutatja, hány megbízás teljesítése van jelenleg folyamatban.'
    },
    'widget3': {
      'title': 'Kiállított Dokumentum',
      'data': {
        'label': 'Dokumentum',
        'count': 0,
        'extra': {
          'label': 'Tervezett',
          'count': 0
        }
      },
      'detail': 'Ez a widget megmutatja, hány dokumentum került kiállításra, a tervezett érték megadja azt, hogy a rendszer szerint hány dokumentumra lett volna szükség.'
    },
    'widget4': {
      'title': 'Fizetettlen számla',
      'data': {
        'label': 'Számla',
        'count': 0,
        'extra': {
          'label': 'Kifizetésre került',
          'count': 0
        }
      },
      'detail': 'Ez a widget megmutatja, hány olyan számla van amely nem lett kifizetve, illetve hány olyan amelyet kifizettek a mai napon.'
    },
    'widget5': {
      'title': 'Teljesítési/Lezárási arány',
      'ranges': {
        'TW': 'This Week',
        'LW': 'Last Week',
        '2W': '2 Weeks Ago'
      },
      'mainChart': {
        '2W': [
          {
            'name': 'Mon',
            'series': [
              {
                'name': 'aktiv',
                'value': 4
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Tue',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Wed',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Thu',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Fri',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Sat',
            'series': [
              {
                'name': 'aktiv',
                'value': 1
              },
              {
                'name': 'lezart/inaktiv',
                'value': 1
              }
            ]
          },
          {
            'name': 'Sun',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          }
        ],
        'LW': [
          {
            'name': 'Mon',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Tue',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Wed',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Thu',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Fri',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Sat',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Sun',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          }
        ],
        'TW': [
          {
            'name': 'Mon',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Tue',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Wed',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Thu',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Fri',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Sat',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          },
          {
            'name': 'Sun',
            'series': [
              {
                'name': 'aktiv',
                'value': 0
              },
              {
                'name': 'lezart/inaktiv',
                'value': 0
              }
            ]
          }
        ]
      },
      'supporting': {
        'created': {
          'label': 'Összes',
          'count': {
            '2W': 5,
            'LW': 0,
            'TW': 0
          },
          'chart': {
            '2W': [
              {
                'name': 'CREATED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 5
                  },
                  {
                    'name': 'Tue',
                    'value': 8
                  },
                  {
                    'name': 'Wed',
                    'value': 5
                  },
                  {
                    'name': 'Thu',
                    'value': 6
                  },
                  {
                    'name': 'Fri',
                    'value': 7
                  },
                  {
                    'name': 'Sat',
                    'value': 8
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'Created',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 3
                  },
                  {
                    'name': 'Wed',
                    'value': 7
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'Created',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 3
                  },
                  {
                    'name': 'Tue',
                    'value': 2
                  },
                  {
                    'name': 'Wed',
                    'value': 1
                  },
                  {
                    'name': 'Thu',
                    'value': 4
                  },
                  {
                    'name': 'Fri',
                    'value': 8
                  },
                  {
                    'name': 'Sat',
                    'value': 8
                  },
                  {
                    'name': 'Sun',
                    'value': 4
                  }
                ]
              }
            ]
          }
        },
        'closed': {
          'label': 'Lezárt',
          'count': {
            '2W': 1,
            'LW': 0,
            'TW': 0
          },
          'chart': {
            '2W': [
              {
                'name': 'Lezárt',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 3
                  },
                  {
                    'name': 'Tue',
                    'value': 2
                  },
                  {
                    'name': 'Wed',
                    'value': 1
                  },
                  {
                    'name': 'Thu',
                    'value': 4
                  },
                  {
                    'name': 'Fri',
                    'value': 8
                  },
                  {
                    'name': 'Sat',
                    'value': 8
                  },
                  {
                    'name': 'Sun',
                    'value': 4
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'CLOSED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 5
                  },
                  {
                    'name': 'Wed',
                    'value': 4
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 7
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'CLOSED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 3
                  },
                  {
                    'name': 'Wed',
                    'value': 7
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ]
          }
        },
        'reOpened': {
          'label': 'Kiszállított',
          'count': {
            '2W': 0,
            'LW': 0,
            'TW': 0
          },
          'chart': {
            '2W': [
              {
                'name': 'RE-OPENED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 0
                  },
                  {
                    'name': 'Tue',
                    'value': 0
                  },
                  {
                    'name': 'Wed',
                    'value': 0
                  },
                  {
                    'name': 'Thu',
                    'value': 0
                  },
                  {
                    'name': 'Fri',
                    'value': 0
                  },
                  {
                    'name': 'Sat',
                    'value': 0
                  },
                  {
                    'name': 'Sun',
                    'value': 0
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'RE-OPENED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 5
                  },
                  {
                    'name': 'Tue',
                    'value': 7
                  },
                  {
                    'name': 'Wed',
                    'value': 8
                  },
                  {
                    'name': 'Thu',
                    'value': 8
                  },
                  {
                    'name': 'Fri',
                    'value': 6
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 1
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'RE-OPENED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 3
                  },
                  {
                    'name': 'Tue',
                    'value': 2
                  },
                  {
                    'name': 'Wed',
                    'value': 1
                  },
                  {
                    'name': 'Thu',
                    'value': 4
                  },
                  {
                    'name': 'Fri',
                    'value': 8
                  },
                  {
                    'name': 'Sat',
                    'value': 8
                  },
                  {
                    'name': 'Sun',
                    'value': 4
                  }
                ]
              }
            ]
          }
        },
        'wontFix': {
          'label': 'Törölt',
          'count': {
            '2W': 1,
            'LW': 0,
            'TW': 0
          },
          'chart': {
            '2W': [
              {
                'name': 'WON\'T FIX',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 0
                  },
                  {
                    'name': 'Tue',
                    'value': 0
                  },
                  {
                    'name': 'Wed',
                    'value': 0
                  },
                  {
                    'name': 'Thu',
                    'value': 0
                  },
                  {
                    'name': 'Fri',
                    'value': 0
                  },
                  {
                    'name': 'Sat',
                    'value': 1
                  },
                  {
                    'name': 'Sun',
                    'value': 0
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'WON\'T FIX',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 0
                  },
                  {
                    'name': 'Tue',
                    'value': 0
                  },
                  {
                    'name': 'Wed',
                    'value': 0
                  },
                  {
                    'name': 'Thu',
                    'value': 0
                  },
                  {
                    'name': 'Fri',
                    'value': 0
                  },
                  {
                    'name': 'Sat',
                    'value': 0
                  },
                  {
                    'name': 'Sun',
                    'value': 0
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'WON\'T FIX',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 0
                  },
                  {
                    'name': 'Tue',
                    'value': 0
                  },
                  {
                    'name': 'Wed',
                    'value': 0
                  },
                  {
                    'name': 'Thu',
                    'value': 0
                  },
                  {
                    'name': 'Fri',
                    'value': 0
                  },
                  {
                    'name': 'Sat',
                    'value': 0
                  },
                  {
                    'name': 'Sun',
                    'value': 0
                  }
                ]
              }
            ]
          }
        },
      }
    },
    'widget6': {
      'title': 'Task Distribution (példa)',
      'ranges': {
        'TW': 'This Week',
        'LW': 'Last Week',
        '2W': '2 Weeks Ago'
      },
      'mainChart': {
        'TW': [
          {
            'name': 'Frontend',
            'value': 15
          },
          {
            'name': 'Backend',
            'value': 20
          },
          {
            'name': 'API',
            'value': 38
          },
          {
            'name': 'aktiv',
            'value': 27
          }
        ],
        'LW': [
          {
            'name': 'Frontend',
            'value': 19
          },
          {
            'name': 'Backend',
            'value': 16
          },
          {
            'name': 'API',
            'value': 42
          },
          {
            'name': 'aktiv',
            'value': 23
          }
        ],
        '2W': [
          {
            'name': 'Frontend',
            'value': 18
          },
          {
            'name': 'Backend',
            'value': 17
          },
          {
            'name': 'API',
            'value': 40
          },
          {
            'name': 'aktiv',
            'value': 25
          }
        ]
      },
      'footerLeft': {
        'title': 'Tasks Added',
        'count': {
          '2W': 487,
          'LW': 526,
          'TW': 594
        }
      },
      'footerRight': {
        'title': 'Tasks Completed',
        'count': {
          '2W': 193,
          'LW': 260,
          'TW': 287
        }
      }
    },
    'widget7': {
      'title': 'Dokumentumok',
      'ranges': {
        'T': 'Today',
        'TM': 'Tomorrow'
      },
      'schedule': {
        'T': [

        ],
        'TM': [

        ]
      }
    },
    'widget8': {
      'title': 'Összeg Szerinti Besorolás',
      'mainChart': [
        {
          'name': 'A',
          'value': 2
        },
        {
          'name': 'B',
          'value': 0
        },
        {
          'name': 'C',
          'value': 0
        },
        {
          'name': 'D',
          'value': 0
        },
        {
          'name': '?',
          'value': 2
        },
      ]
    },
    'widget9': {
      'title': 'Bevételtábla (példa)',
      'ranges': {
        'TW': 'Today',
        'LW': 'Last Week',
      },
      'weeklySpent': {
        'title': 'WEEKLY SPENT',
        'count': {
          '2W': '2,682.85',
          'LW': '1,445.34',
          'TW': '3,630.15'
        },
        'chart': {
          '2W': [
            {
              'name': 'CREATED',
              'series': [
                {
                  'name': 'Mon',
                  'value': 6
                },
                {
                  'name': 'Tue',
                  'value': 1
                },
                {
                  'name': 'Wed',
                  'value': 3
                },
                {
                  'name': 'Thu',
                  'value': 4
                },
                {
                  'name': 'Fri',
                  'value': 5
                },
                {
                  'name': 'Sat',
                  'value': 5
                },
                {
                  'name': 'Sun',
                  'value': 2
                }
              ]
            }
          ],
          'LW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 4
                },
                {
                  'name': 'Tue',
                  'value': 6
                },
                {
                  'name': 'Wed',
                  'value': 2
                },
                {
                  'name': 'Thu',
                  'value': 2
                },
                {
                  'name': 'Fri',
                  'value': 1
                },
                {
                  'name': 'Sat',
                  'value': 3
                },
                {
                  'name': 'Sun',
                  'value': 4
                }
              ]
            }
          ],
          'TW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 2
                },
                {
                  'name': 'Tue',
                  'value': 6
                },
                {
                  'name': 'Wed',
                  'value': 5
                },
                {
                  'name': 'Thu',
                  'value': 4
                },
                {
                  'name': 'Fri',
                  'value': 5
                },
                {
                  'name': 'Sat',
                  'value': 3
                },
                {
                  'name': 'Sun',
                  'value': 6
                }
              ]
            }
          ]
        }
      },
      'totalSpent': {
        'title': 'TOTAL SPENT',
        'count': {
          '2W': '29,682.85',
          'LW': '31,128.19',
          'TW': '34,758.34'
        },
        'chart': {
          '2W': [
            {
              'name': 'CREATED',
              'series': [
                {
                  'name': 'Mon',
                  'value': 3
                },
                {
                  'name': 'Tue',
                  'value': 2
                },
                {
                  'name': 'Wed',
                  'value': 2
                },
                {
                  'name': 'Thu',
                  'value': 4
                },
                {
                  'name': 'Fri',
                  'value': 7
                },
                {
                  'name': 'Sat',
                  'value': 7
                },
                {
                  'name': 'Sun',
                  'value': 4
                }
              ]
            }
          ],
          'LW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 5
                },
                {
                  'name': 'Tue',
                  'value': 7
                },
                {
                  'name': 'Wed',
                  'value': 8
                },
                {
                  'name': 'Thu',
                  'value': 8
                },
                {
                  'name': 'Fri',
                  'value': 6
                },
                {
                  'name': 'Sat',
                  'value': 4
                },
                {
                  'name': 'Sun',
                  'value': 1
                }
              ]
            }
          ],
          'TW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 6
                },
                {
                  'name': 'Tue',
                  'value': 4
                },
                {
                  'name': 'Wed',
                  'value': 7
                },
                {
                  'name': 'Thu',
                  'value': 5
                },
                {
                  'name': 'Fri',
                  'value': 5
                },
                {
                  'name': 'Sat',
                  'value': 4
                },
                {
                  'name': 'Sun',
                  'value': 7
                }
              ]
            }
          ]
        }
      },
      'remaining': {
        'title': 'REMAINING',
        'count': {
          '2W': '94.317,15',
          'LW': '92.871,81',
          'TW': '89.241,66'
        },
        'chart': {
          '2W': [
            {
              'name': 'CREATED',
              'series': [
                {
                  'name': 'Mon',
                  'value': 1
                },
                {
                  'name': 'Tue',
                  'value': 4
                },
                {
                  'name': 'Wed',
                  'value': 5
                },
                {
                  'name': 'Thu',
                  'value': 7
                },
                {
                  'name': 'Fri',
                  'value': 8
                },
                {
                  'name': 'Sat',
                  'value': 2
                },
                {
                  'name': 'Sun',
                  'value': 4
                }
              ]
            }
          ],
          'LW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 3
                },
                {
                  'name': 'Tue',
                  'value': 2
                },
                {
                  'name': 'Wed',
                  'value': 1
                },
                {
                  'name': 'Thu',
                  'value': 4
                },
                {
                  'name': 'Fri',
                  'value': 8
                },
                {
                  'name': 'Sat',
                  'value': 8
                },
                {
                  'name': 'Sun',
                  'value': 4
                }
              ]
            }
          ],
          'TW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 2
                },
                {
                  'name': 'Tue',
                  'value': 4
                },
                {
                  'name': 'Wed',
                  'value': 8
                },
                {
                  'name': 'Thu',
                  'value': 6
                },
                {
                  'name': 'Fri',
                  'value': 2
                },
                {
                  'name': 'Sat',
                  'value': 5
                },
                {
                  'name': 'Sun',
                  'value': 1
                }
              ]
            }
          ]
        }
      },
      'totalRemaining': {
        'title': 'TOTAL BUDGET',
        'count': '124.000,00'
      },
      'totalBudget': {
        'title': 'TOTAL BUDGET',
        'count': '124.000,00'
      }
    },
    'widget10': {
      'title': 'Budget Details',
      'table': {
        'columns': [
          {
            'title': 'Budget Type'
          },
          {
            'title': 'Total Budget'
          },
          {
            'title': 'Spent ($)'
          },
          {
            'title': 'Spent (%)'
          },
          {
            'title': 'Remaining ($)'
          },
          {
            'title': 'Remaining (%)'
          }
        ],
        'rows': [
          [
            {
              'value': 'Wireframing',
              'classes': 'primary',
              'icon': ''
            },
            {
              'value': '$14,880.00',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': '$14,000.00',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%94.08',
              'classes': 'text-success',
              'icon': 'trending_up'
            },
            {
              'value': '$880.00',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%5.92',
              'classes': '',
              'icon': ''
            }
          ],
          [
            {
              'value': 'Design',
              'classes': 'green',
              'icon': ''
            },
            {
              'value': '$21,080.00',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': '$17,240.34',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%81.78',
              'classes': 'text-success',
              'icon': 'trending_up'
            },
            {
              'value': '$3,839.66',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%18.22',
              'classes': '',
              'icon': ''
            }
          ],
          [
            {
              'value': 'Coding',
              'classes': 'red',
              'icon': ''
            },
            {
              'value': '$34,720.00',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': '$3,518.00',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%10.13',
              'classes': 'text-danger',
              'icon': 'trending_down'
            },
            {
              'value': '$31,202.00',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%89.87',
              'classes': '',
              'icon': ''
            }
          ],
          [
            {
              'value': 'Marketing',
              'classes': 'accent',
              'icon': ''
            },
            {
              'value': '$34,720.00',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': '$0.00',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%0.00',
              'classes': 'text-info',
              'icon': 'trending_flat'
            },
            {
              'value': '$34,720.00',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%100.00',
              'classes': '',
              'icon': ''
            }
          ],
          [
            {
              'value': 'Extra',
              'classes': 'orange',
              'icon': ''
            },
            {
              'value': '$18,600.00',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': '$0.00',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%0.00',
              'classes': 'text-info',
              'icon': 'trending_flat'
            },
            {
              'value': '$34,720.00',
              'classes': '',
              'icon': ''
            },
            {
              'value': '%100.00',
              'classes': '',
              'icon': ''
            }
          ]
        ]
      }
    },
    'widget11': {
      'title': 'Team Members',
      'table': {
        'columns': ['avatar', 'name', 'position', 'office', 'email', 'phone'],
        'rows': [
          {
            avatar: 'assets/images/avatars/james.jpg',
            name: 'Jack Gilbert',
            position: 'Design Manager',
            office: 'Johor Bahru',
            email: 'jgilbert48@mail.com',
            phone: '+16 298 032 7774'
          },
          {
            avatar: 'assets/images/avatars/katherine.jpg',
            name: 'Kathy Anderson',
            position: 'Recruiting Manager',
            office: 'Solţānābād',
            email: 'kanderson49@mail.com.br',
            phone: '+23 572 311 1136'
          },
          {
            avatar: 'assets/images/avatars/andrew.jpg',
            name: 'Mark Turner',
            position: 'Recruiting Manager',
            office: 'Neftegorsk',
            email: 'mturner4a@mail.com',
            phone: '+01 139 803 9263'
          },
          {
            avatar: 'assets/images/avatars/jane.jpg',
            name: 'Kathryn Martinez',
            position: 'Director of Sales',
            office: 'Palekastro',
            email: 'kmartinez4b@mail.com',
            phone: '+25 467 022 5393'
          },
          {
            avatar: 'assets/images/avatars/alice.jpg',
            name: 'Annie Gonzales',
            position: 'Actuary',
            office: 'Candon',
            email: 'agonzales4c@mail.edu',
            phone: '+99 891 619 7138'
          },
          {
            avatar: 'assets/images/avatars/vincent.jpg',
            name: 'Howard King',
            position: 'Human Resources',
            office: 'Bergen op Zoom',
            email: 'hking4d@mail.gov',
            phone: '+46 984 348 1409'
          },
          {
            avatar: 'assets/images/avatars/joyce.jpg',
            name: 'Elizabeth Dixon',
            position: 'Electrical Engineer',
            office: 'Písečná',
            email: 'edixon4e@mail.gov',
            phone: '+33 332 067 9063'
          },
          {
            avatar: 'assets/images/avatars/danielle.jpg',
            name: 'Dorothy Morris',
            position: 'Office Assistant',
            office: 'Magsaysay',
            email: 'dmorris4f@mail.com',
            phone: '+05 490 958 6120'
          },
          {
            avatar: 'assets/images/avatars/carl.jpg',
            name: 'Mark Gonzales',
            position: 'Quality Control',
            office: 'Matsue-shi',
            email: 'mgonzales4g@mail.com',
            phone: '+03 168 394 9935'
          },
          {
            avatar: 'assets/images/avatars/profile.jpg',
            name: 'Catherine Rogers',
            position: 'Programmer Analyst',
            office: 'Kangar',
            email: 'crogers4h@mail.com',
            phone: '+86 235 407 5373'
          },
          {
            avatar: 'assets/images/avatars/garry.jpg',
            name: 'Ruth Grant',
            position: 'Community Outreach',
            office: 'Beaune',
            email: 'rgrant4i@mail.pl',
            phone: '+36 288 083 8460'
          },
          {
            avatar: 'assets/images/avatars/james.jpg',
            name: 'Phyllis Gutierrez',
            position: 'Administrative Assistant',
            office: 'Shlissel’burg',
            email: 'pgutierrez4j@mail.net',
            phone: '+52 749 861 9304'
          }, {
            avatar: 'assets/images/avatars/alice.jpg',
            name: 'Lillian Morris',
            position: 'Media Planner',
            office: 'Berlin',
            email: 'lmorris4k@mail.de',
            phone: '+59 695 110 3856'
          }, {
            avatar: 'assets/images/avatars/vincent.jpg',
            name: 'Jeremy Anderson',
            position: 'Systems Engineer',
            office: 'Lũng Hồ',
            email: 'janderson4l@mail.uk',
            phone: '+40 384 115 1448'
          },
          {
            avatar: 'assets/images/avatars/carl.jpg',
            name: 'Arthur Lawrence',
            position: 'Nurse Practicioner',
            office: 'Sarkanjut',
            email: 'alawrence4m@mail.com',
            phone: '+36 631 599 7867'
          }, {
            avatar: 'assets/images/avatars/andrew.jpg',
            name: 'David Simmons',
            position: 'Social Worker',
            office: 'Ushumun',
            email: 'dsimmons4n@mail.com',
            phone: '+01 189 681 4417'
          }, {
            avatar: 'assets/images/avatars/danielle.jpg',
            name: 'Daniel Johnston',
            position: 'Help Desk',
            office: 'São Carlos',
            email: 'djohnston4o@mail.gov',
            phone: '+60 028 943 7919'
          },

          {
            avatar: 'assets/images/avatars/joyce.jpg',
            name: 'Ann King',
            position: 'Internal Auditor',
            office: 'Liren',
            email: 'aking4p@mail.com',
            phone: '+91 103 932 6545'
          },
          {
            avatar: 'assets/images/avatars/james.jpg',
            name: 'Phillip Franklin',
            position: 'VP Accounting',
            office: 'Soba',
            email: 'pfranklin4q@mail.com',
            phone: '+25 820 986 7626'
          },
          {
            avatar: 'assets/images/avatars/garry.jpg',
            name: 'Gary Gonzalez',
            position: 'Speech Pathologist',
            office: 'Gangkou',
            email: 'ggonzalez4r@mail.cc',
            phone: '+10 862 046 7916'
          }
        ]
      }
    },
    'weatherWidget': {
      'locations': {
        'NewYork': {
          'name': 'New York',
          'icon': 'icon-rainy2',
          'temp': {
            'C': '10',
            'F': '72'
          },
          'windSpeed': {
            'KMH': 12,
            'MPH': 7.5
          },
          'windDirection': 'NW',
          'rainProbability': '13%',
          'next3Days': [
            {
              'name': 'Sunday',
              'icon': 'icon-rainy',
              'temp': {
                'C': '14',
                'F': '70'
              }
            },
            {
              'name': 'Sunday',
              'icon': 'icon-rainy',
              'temp': {
                'C': '12',
                'F': '66'
              }
            },
            {
              'name': 'Tuesday',
              'icon': 'icon-rainy',
              'temp': {
                'C': '13',
                'F': '75'
              }
            }
          ]
        }
      },
      'currentLocation': 'NewYork',
      'tempUnit': 'C',
      'speedUnit': 'KMH'
    }
  };
}
