import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ToggleMute} from '../actions/interface.actions';

export class UiStateModel {
  // 0 => NATIVE || 1 => TABLE
  muteNotification: boolean;
}

@State<UiStateModel>({
  name: 'InterfaceState',
  defaults: {
    muteNotification: false,
  } as UiStateModel,
  // }
})

@Injectable()
export class InterfaceState {
  @Selector()
  static getUi(state: UiStateModel) {
    return state.muteNotification;
  }

  @Selector()
  static isNotificationMuted(state: UiStateModel) {
    return state.muteNotification;
  }

  @Action(ToggleMute)
  toggleMute({getState, patchState}: StateContext<UiStateModel>, {payload}: ToggleMute) {
    return patchState({
      muteNotification: payload,
    });
  }

}
