<div class="grid-container">
  <img style="width: 450px;" src="assets/img/invalid-session.svg" alt="session invalid">
  <div style="padding: 2em" class="secondary-container">
    <span class="secondary-text">Your working session has expired!</span>
    <p style="text-align: justify;font-size: medium;">The session has expired. You need be logged out now! Please click
      the logout button to exit!</p>
  </div>

  <div fxLayoutAlign="flex-end center">
    <button mat-raised-button color="primary" class="submit-button"
            aria-label="logout" (click)="logout()">
      Logout
    </button>
  </div>
</div>