import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {fuseAnimations} from '../../../../../@fuse/animations';

export interface MatConfirmDialogData {
  icon?: string;
  title?: string;
  confirmMessage: string;
  confirmTextButton?: string;
  cancelTextButton?: string;
}

@Component({
  selector: 'mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: ['./mat-confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class MatConfirmDialogComponent implements OnInit {
  icon: string;
  title: string;
  confirmMessage: string;
  confirmTextButton: string;
  cancelTextButton: string;

  constructor(
    public dialogRef: MatDialogRef<MatConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatConfirmDialogData,
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.icon = this.data.icon ? this.data.icon : 'warning';
      this.title = this.data.title ? this.data.title : 'Are you sure?';
      this.confirmMessage = this.data.confirmMessage ? this.data.confirmMessage : 'Confirm to proceed';
      this.confirmTextButton = this.data.confirmTextButton ? this.data.confirmTextButton : 'Yes';
      this.cancelTextButton = this.data.cancelTextButton ? this.data.cancelTextButton : 'No';
    }
  }

  close(value: boolean): void {
    this.dialogRef.close(value);
  }
}
