import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {NotificationService} from '../../../../shared/services/notification.service';
import {Notification} from '../../../../shared/models/notification';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {ErrorService} from '../../../../shared/services/error.service';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {
  @Input() notification: Notification;
  @Output() delete = new EventEmitter<number>();
  notificationColorClass = 'p-16 ';

  error = 'error-color';
  warn = 'warn-color';
  normal = 'light-blue-600';

  constructor(
    private notificationService: NotificationService,
    public snackBar: MatSnackBar,
    private errorService: ErrorService,
  ) {
    super(snackBar);
  }

  ngOnInit(): void {
    switch (this.notification.type) {
      // case 1:
      //   this.notificationColorClass += this.error;
      //   break;
      // case 2:
      //   this.notificationColorClass += this.warn;
      //   break;
      // case 3:
      //   this.notificationColorClass += this.normal;
      //   break;
      default:
        this.notificationColorClass += this.normal;
        break;
    }
  }

  deleteNotification() {
    this.notificationService.deleteNotification(this.notification.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(_ => {
        this.delete.emit(this.notification.id);
      }, error => {
        this.errorService.onError(error);
      });
  }

  moment(date) {
    return moment(date);
  }
}
