import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './shared/guards/auth.guard';
import {LayoutComponent} from './layout/layout/layout.component';
import {NgxPermissionsGuard} from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../app/main/apps/dashboards/project/project.module').then(m => m.ProjectDashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'statements',
        loadChildren: () => import('./main/pages/statements/statements.module').then(m => m.StatementsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'calendar',
        loadChildren: () => import('./main/pages/calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'locations',
        loadChildren: () => import('./main/pages/locations/locations.module').then(m => m.LocationsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'companies',
        loadChildren: () => import('./main/pages/companies/companies.module').then(m => m.CompaniesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'orders',
        loadChildren: () => import('./main/pages/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'documents',
        loadChildren: () => import('./main/pages/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./main/pages/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'Developer'
          }
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./main/pages/users/users.module').then(m => m.UsersModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'mail-templates',
        loadChildren: () => import('./main/pages/mail-templates/mail-templates.module').then(m => m.MailTemplatesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./main/pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'my-company',
        loadChildren: () => import('./main/pages/my-company/my-company.module').then(m => m.MyCompanyModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'invoicing',
        loadChildren: () => import('./main/pages/invoicing/invoicing.module').then(m => m.InvoicingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'changelog',
        loadChildren: () => import('./main/pages/change-log/change-log.module').then(m => m.ChangeLogModule),
        canActivate: [AuthGuard]
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      // {path: '**', redirectTo: 'auth/login'}
    ],
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./main/pages/authentication/login-2/login.module').then(m => m.LoginModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
