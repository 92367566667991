import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {InMemoryWebApiModule} from 'angular-in-memory-web-api';
import {TranslateModule} from '@ngx-translate/core';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';
import {fuseConfig} from 'app/fuse-config';
import {FakeDbService} from 'app/fake-db/fake-db.service';
import {AppComponent} from 'app/app.component';
// import {AppStoreModule} from 'app/store/store.module';
import {NgxsWrapperModule} from './shared/modules/ngxs.module';
import {AuthInterceptor} from './authInterceptor';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing-module';
import {BaseComponent} from './shared/components/base/base.component';
import {LayoutModule} from './layout/layout/layout.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {NgxPermissionsModule} from 'ngx-permissions';
import {NgxHotjarModule, NgxHotjarRouterModule} from 'ngx-hotjar';
import {MaintenanceModule} from './shared/dialogs/maintenance/maintenance.module';
import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';
import {JoyrideModule} from 'ngx-joyride';
import {MyDateAdapter} from './my-date-adapter';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter} from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaintenanceModule,

    NgxPermissionsModule.forRoot(),

    TranslateModule.forRoot(),
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),

    NgxsWrapperModule,

    // Material moment date module
    // MatMomentDateModule,
    MatNativeDateModule,

    NgxHotjarModule.forRoot(environment.hj),
    NgxHotjarRouterModule,


    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places']
    }),

    JoyrideModule.forRoot(),

    // Material
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCardModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    // LayoutModule,
    // AppStoreModule,
    LayoutModule,


    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,


    ButtonModule,
    ToastModule,

    ToastrModule.forRoot(),
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MyDateAdapter,
    },
  ],
})

export class AppModule {

}
