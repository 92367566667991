import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PdfViewerComponent} from './pdf-viewer.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first} from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerService {
  dialogRef: MatDialogRef<PdfViewerComponent>;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
  ) {
  }

  /**
   * OPENS THE DIALOG
   * @param name: string
   * @param url: string
   * @param fileType: any
   */
  public open(name, url, fileType?) {
    this.dialogRef = this.dialog.open(PdfViewerComponent, {
      data: {
        url,
        title: name,
        type: fileType ? fileType : null
      },
      panelClass: 'fullscreen-dialog',
      width: '80%',
    });
  }

  /**
   * RETURNS A FILE AS A BLOB
   * @param location: string
   */
  public getFileFromLocation(location) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(location,
      {headers: headers, responseType: 'blob'}
    );
  }

  /**
   * DOWNLOADS A FILE
   * @param location: string
   * @param name: string
   */
  public downloadFile(location, name) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    this.http.get(`${environment.apiUrl}api/download-documents?file=${location}`, {headers: headers, responseType: 'blob'})
      .pipe(first())
      .subscribe(file => {
        fileSaver.saveAs(file, name);
      }, error =>  {
      });
  }
}
