export class IconsFakeDb {
  public static icons = [
    {
      'name': '3d_rotation',
      'ligature': '3d_rotation',
      'tags': ['3d_rotation']
    },
    {
      'name': '4k',
      'ligature': '4k',
      'tags': ['4k']
    },
    {
      'name': '360',
      'ligature': '360',
      'tags': ['360']
    },
    {
      'name': 'ac_unit',
      'ligature': 'ac_unit',
      'tags': ['ac_unit']
    },
    {
      'name': 'access_alarm',
      'ligature': 'access_alarm',
      'tags': ['access_alarm']
    },
    {
      'name': 'access_alarms',
      'ligature': 'access_alarms',
      'tags': ['access_alarms']
    },
    {
      'name': 'access_time',
      'ligature': 'access_time',
      'tags': ['access_time']
    },
    {
      'name': 'accessibility_new',
      'ligature': 'accessibility_new',
      'tags': ['accessibility_new']
    },
    {
      'name': 'accessibility',
      'ligature': 'accessibility',
      'tags': ['accessibility']
    },
    {
      'name': 'accessible_forward',
      'ligature': 'accessible_forward',
      'tags': ['accessible_forward']
    },
    {
      'name': 'accessible',
      'ligature': 'accessible',
      'tags': ['accessible']
    },
    {
      'name': 'account_balance_wallet',
      'ligature': 'account_balance_wallet',
      'tags': ['account_balance_wallet']
    },
    {
      'name': 'account_balance',
      'ligature': 'account_balance',
      'tags': ['account_balance']
    },
    {
      'name': 'account_box',
      'ligature': 'account_box',
      'tags': ['account_box']
    },
    {
      'name': 'account_circle',
      'ligature': 'account_circle',
      'tags': ['account_circle']
    },
    {
      'name': 'adb',
      'ligature': 'adb',
      'tags': ['adb']
    },
    {
      'name': 'add_a_photo',
      'ligature': 'add_a_photo',
      'tags': ['add_a_photo']
    },
    {
      'name': 'add_alarm',
      'ligature': 'add_alarm',
      'tags': ['add_alarm']
    },
    {
      'name': 'add_alert',
      'ligature': 'add_alert',
      'tags': ['add_alert']
    },
    {
      'name': 'add_box',
      'ligature': 'add_box',
      'tags': ['add_box']
    },
    {
      'name': 'add_circle_outline',
      'ligature': 'add_circle_outline',
      'tags': ['add_circle_outline']
    },
    {
      'name': 'add_circle',
      'ligature': 'add_circle',
      'tags': ['add_circle']
    },
    {
      'name': 'add_comment',
      'ligature': 'add_comment',
      'tags': ['add_comment']
    },
    {
      'name': 'add_location',
      'ligature': 'add_location',
      'tags': ['add_location']
    },
    {
      'name': 'add_photo_alternate',
      'ligature': 'add_photo_alternate',
      'tags': ['add_photo_alternate']
    },
    {
      'name': 'add_shopping_cart',
      'ligature': 'add_shopping_cart',
      'tags': ['add_shopping_cart']
    },
    {
      'name': 'add_to_home_screen',
      'ligature': 'add_to_home_screen',
      'tags': ['add_to_home_screen']
    },
    {
      'name': 'add_to_photos',
      'ligature': 'add_to_photos',
      'tags': ['add_to_photos']
    },
    {
      'name': 'add_to_queue',
      'ligature': 'add_to_queue',
      'tags': ['add_to_queue']
    },
    {
      'name': 'add',
      'ligature': 'add',
      'tags': ['add']
    },
    {
      'name': 'adjust',
      'ligature': 'adjust',
      'tags': ['adjust']
    },
    {
      'name': 'airline_seat_flat_angled',
      'ligature': 'airline_seat_flat_angled',
      'tags': ['airline_seat_flat_angled']
    },
    {
      'name': 'airline_seat_flat',
      'ligature': 'airline_seat_flat',
      'tags': ['airline_seat_flat']
    },
    {
      'name': 'airline_seat_individual_suite',
      'ligature': 'airline_seat_individual_suite',
      'tags': ['airline_seat_individual_suite']
    },
    {
      'name': 'airline_seat_legroom_extra',
      'ligature': 'airline_seat_legroom_extra',
      'tags': ['airline_seat_legroom_extra']
    },
    {
      'name': 'airline_seat_legroom_normal',
      'ligature': 'airline_seat_legroom_normal',
      'tags': ['airline_seat_legroom_normal']
    },
    {
      'name': 'airline_seat_legroom_reduced',
      'ligature': 'airline_seat_legroom_reduced',
      'tags': ['airline_seat_legroom_reduced']
    },
    {
      'name': 'airline_seat_recline_extra',
      'ligature': 'airline_seat_recline_extra',
      'tags': ['airline_seat_recline_extra']
    },
    {
      'name': 'airline_seat_recline_normal',
      'ligature': 'airline_seat_recline_normal',
      'tags': ['airline_seat_recline_normal']
    },
    {
      'name': 'airplanemode_active',
      'ligature': 'airplanemode_active',
      'tags': ['airplanemode_active']
    },
    {
      'name': 'airplanemode_inactive',
      'ligature': 'airplanemode_inactive',
      'tags': ['airplanemode_inactive']
    },
    {
      'name': 'airplay',
      'ligature': 'airplay',
      'tags': ['airplay']
    },
    {
      'name': 'airport_shuttle',
      'ligature': 'airport_shuttle',
      'tags': ['airport_shuttle']
    },
    {
      'name': 'alarm_add',
      'ligature': 'alarm_add',
      'tags': ['alarm_add']
    },
    {
      'name': 'alarm_off',
      'ligature': 'alarm_off',
      'tags': ['alarm_off']
    },
    {
      'name': 'alarm_on',
      'ligature': 'alarm_on',
      'tags': ['alarm_on']
    },
    {
      'name': 'alarm',
      'ligature': 'alarm',
      'tags': ['alarm']
    },
    {
      'name': 'album',
      'ligature': 'album',
      'tags': ['album']
    },
    {
      'name': 'all_inbox',
      'ligature': 'all_inbox',
      'tags': ['all_inbox']
    },
    {
      'name': 'all_inclusive',
      'ligature': 'all_inclusive',
      'tags': ['all_inclusive']
    },
    {
      'name': 'all_out',
      'ligature': 'all_out',
      'tags': ['all_out']
    },
    {
      'name': 'alternate_email',
      'ligature': 'alternate_email',
      'tags': ['alternate_email']
    },
    {
      'name': 'android',
      'ligature': 'android',
      'tags': ['android']
    },
    {
      'name': 'announcement',
      'ligature': 'announcement',
      'tags': ['announcement']
    },
    {
      'name': 'apps',
      'ligature': 'apps',
      'tags': ['apps']
    },
    {
      'name': 'archive',
      'ligature': 'archive',
      'tags': ['archive']
    },
    {
      'name': 'arrow_back_ios',
      'ligature': 'arrow_back_ios',
      'tags': ['arrow_back_ios']
    },
    {
      'name': 'arrow_back',
      'ligature': 'arrow_back',
      'tags': ['arrow_back']
    },
    {
      'name': 'arrow_downward',
      'ligature': 'arrow_downward',
      'tags': ['arrow_downward']
    },
    {
      'name': 'arrow_drop_down_circle',
      'ligature': 'arrow_drop_down_circle',
      'tags': ['arrow_drop_down_circle']
    },
    {
      'name': 'arrow_drop_down',
      'ligature': 'arrow_drop_down',
      'tags': ['arrow_drop_down']
    },
    {
      'name': 'arrow_drop_up',
      'ligature': 'arrow_drop_up',
      'tags': ['arrow_drop_up']
    },
    {
      'name': 'arrow_forward_ios',
      'ligature': 'arrow_forward_ios',
      'tags': ['arrow_forward_ios']
    },
    {
      'name': 'arrow_forward',
      'ligature': 'arrow_forward',
      'tags': ['arrow_forward']
    },
    {
      'name': 'arrow_left',
      'ligature': 'arrow_left',
      'tags': ['arrow_left']
    },
    {
      'name': 'arrow_right_alt',
      'ligature': 'arrow_right_alt',
      'tags': ['arrow_right_alt']
    },
    {
      'name': 'arrow_right',
      'ligature': 'arrow_right',
      'tags': ['arrow_right']
    },
    {
      'name': 'arrow_upward',
      'ligature': 'arrow_upward',
      'tags': ['arrow_upward']
    },
    {
      'name': 'art_track',
      'ligature': 'art_track',
      'tags': ['art_track']
    },
    {
      'name': 'aspect_ratio',
      'ligature': 'aspect_ratio',
      'tags': ['aspect_ratio']
    },
    {
      'name': 'assessment',
      'ligature': 'assessment',
      'tags': ['assessment']
    },
    {
      'name': 'assignment_ind',
      'ligature': 'assignment_ind',
      'tags': ['assignment_ind']
    },
    {
      'name': 'assignment_late',
      'ligature': 'assignment_late',
      'tags': ['assignment_late']
    },
    {
      'name': 'assignment_return',
      'ligature': 'assignment_return',
      'tags': ['assignment_return']
    },
    {
      'name': 'assignment_returned',
      'ligature': 'assignment_returned',
      'tags': ['assignment_returned']
    },
    {
      'name': 'assignment_turned_in',
      'ligature': 'assignment_turned_in',
      'tags': ['assignment_turned_in']
    },
    {
      'name': 'assignment',
      'ligature': 'assignment',
      'tags': ['assignment']
    },
    {
      'name': 'assistant_photo',
      'ligature': 'assistant_photo',
      'tags': ['assistant_photo']
    },
    {
      'name': 'assistant',
      'ligature': 'assistant',
      'tags': ['assistant']
    },
    {
      'name': 'atm',
      'ligature': 'atm',
      'tags': ['atm']
    },
    {
      'name': 'attach_file',
      'ligature': 'attach_file',
      'tags': ['attach_file']
    },
    {
      'name': 'attach_money',
      'ligature': 'attach_money',
      'tags': ['attach_money']
    },
    {
      'name': 'attachment',
      'ligature': 'attachment',
      'tags': ['attachment']
    },
    {
      'name': 'audiotrack',
      'ligature': 'audiotrack',
      'tags': ['audiotrack']
    },
    {
      'name': 'autorenew',
      'ligature': 'autorenew',
      'tags': ['autorenew']
    },
    {
      'name': 'av_timer',
      'ligature': 'av_timer',
      'tags': ['av_timer']
    },
    {
      'name': 'backspace',
      'ligature': 'backspace',
      'tags': ['backspace']
    },
    {
      'name': 'backup',
      'ligature': 'backup',
      'tags': ['backup']
    },
    {
      'name': 'ballot',
      'ligature': 'ballot',
      'tags': ['ballot']
    },
    {
      'name': 'bar_chart',
      'ligature': 'bar_chart',
      'tags': ['bar_chart']
    },
    {
      'name': 'battery_alert',
      'ligature': 'battery_alert',
      'tags': ['battery_alert']
    },
    {
      'name': 'battery_charging_full',
      'ligature': 'battery_charging_full',
      'tags': ['battery_charging_full']
    },
    {
      'name': 'battery_full',
      'ligature': 'battery_full',
      'tags': ['battery_full']
    },
    {
      'name': 'battery_std',
      'ligature': 'battery_std',
      'tags': ['battery_std']
    },
    {
      'name': 'battery_unknown',
      'ligature': 'battery_unknown',
      'tags': ['battery_unknown']
    },
    {
      'name': 'beach_access',
      'ligature': 'beach_access',
      'tags': ['beach_access']
    },
    {
      'name': 'beenhere',
      'ligature': 'beenhere',
      'tags': ['beenhere']
    },
    {
      'name': 'block',
      'ligature': 'block',
      'tags': ['block']
    },
    {
      'name': 'bluetooth_audio',
      'ligature': 'bluetooth_audio',
      'tags': ['bluetooth_audio']
    },
    {
      'name': 'bluetooth_connected',
      'ligature': 'bluetooth_connected',
      'tags': ['bluetooth_connected']
    },
    {
      'name': 'bluetooth_disabled',
      'ligature': 'bluetooth_disabled',
      'tags': ['bluetooth_disabled']
    },
    {
      'name': 'bluetooth_searching',
      'ligature': 'bluetooth_searching',
      'tags': ['bluetooth_searching']
    },
    {
      'name': 'bluetooth',
      'ligature': 'bluetooth',
      'tags': ['bluetooth']
    },
    {
      'name': 'blur_circular',
      'ligature': 'blur_circular',
      'tags': ['blur_circular']
    },
    {
      'name': 'blur_linear',
      'ligature': 'blur_linear',
      'tags': ['blur_linear']
    },
    {
      'name': 'blur_off',
      'ligature': 'blur_off',
      'tags': ['blur_off']
    },
    {
      'name': 'blur_on',
      'ligature': 'blur_on',
      'tags': ['blur_on']
    },
    {
      'name': 'book',
      'ligature': 'book',
      'tags': ['book']
    },
    {
      'name': 'bookmark_border',
      'ligature': 'bookmark_border',
      'tags': ['bookmark_border']
    },
    {
      'name': 'bookmark',
      'ligature': 'bookmark',
      'tags': ['bookmark']
    },
    {
      'name': 'bookmarks',
      'ligature': 'bookmarks',
      'tags': ['bookmarks']
    },
    {
      'name': 'border_all',
      'ligature': 'border_all',
      'tags': ['border_all']
    },
    {
      'name': 'border_bottom',
      'ligature': 'border_bottom',
      'tags': ['border_bottom']
    },
    {
      'name': 'border_clear',
      'ligature': 'border_clear',
      'tags': ['border_clear']
    },
    {
      'name': 'border_horizontal',
      'ligature': 'border_horizontal',
      'tags': ['border_horizontal']
    },
    {
      'name': 'border_inner',
      'ligature': 'border_inner',
      'tags': ['border_inner']
    },
    {
      'name': 'border_left',
      'ligature': 'border_left',
      'tags': ['border_left']
    },
    {
      'name': 'border_outer',
      'ligature': 'border_outer',
      'tags': ['border_outer']
    },
    {
      'name': 'border_right',
      'ligature': 'border_right',
      'tags': ['border_right']
    },
    {
      'name': 'border_style',
      'ligature': 'border_style',
      'tags': ['border_style']
    },
    {
      'name': 'border_top',
      'ligature': 'border_top',
      'tags': ['border_top']
    },
    {
      'name': 'border_vertical',
      'ligature': 'border_vertical',
      'tags': ['border_vertical']
    },
    {
      'name': 'branding_watermark',
      'ligature': 'branding_watermark',
      'tags': ['branding_watermark']
    },
    {
      'name': 'brightness_1',
      'ligature': 'brightness_1',
      'tags': ['brightness_1']
    },
    {
      'name': 'brightness_2',
      'ligature': 'brightness_2',
      'tags': ['brightness_2']
    },
    {
      'name': 'brightness_3',
      'ligature': 'brightness_3',
      'tags': ['brightness_3']
    },
    {
      'name': 'brightness_4',
      'ligature': 'brightness_4',
      'tags': ['brightness_4']
    },
    {
      'name': 'brightness_5',
      'ligature': 'brightness_5',
      'tags': ['brightness_5']
    },
    {
      'name': 'brightness_6',
      'ligature': 'brightness_6',
      'tags': ['brightness_6']
    },
    {
      'name': 'brightness_7',
      'ligature': 'brightness_7',
      'tags': ['brightness_7']
    },
    {
      'name': 'brightness_auto',
      'ligature': 'brightness_auto',
      'tags': ['brightness_auto']
    },
    {
      'name': 'brightness_high',
      'ligature': 'brightness_high',
      'tags': ['brightness_high']
    },
    {
      'name': 'brightness_low',
      'ligature': 'brightness_low',
      'tags': ['brightness_low']
    },
    {
      'name': 'brightness_medium',
      'ligature': 'brightness_medium',
      'tags': ['brightness_medium']
    },
    {
      'name': 'broken_image',
      'ligature': 'broken_image',
      'tags': ['broken_image']
    },
    {
      'name': 'brush',
      'ligature': 'brush',
      'tags': ['brush']
    },
    {
      'name': 'bubble_chart',
      'ligature': 'bubble_chart',
      'tags': ['bubble_chart']
    },
    {
      'name': 'bug_report',
      'ligature': 'bug_report',
      'tags': ['bug_report']
    },
    {
      'name': 'build',
      'ligature': 'build',
      'tags': ['build']
    },
    {
      'name': 'burst_mode',
      'ligature': 'burst_mode',
      'tags': ['burst_mode']
    },
    {
      'name': 'business_center',
      'ligature': 'business_center',
      'tags': ['business_center']
    },
    {
      'name': 'business',
      'ligature': 'business',
      'tags': ['business']
    },
    {
      'name': 'cached',
      'ligature': 'cached',
      'tags': ['cached']
    },
    {
      'name': 'cake',
      'ligature': 'cake',
      'tags': ['cake']
    },
    {
      'name': 'calendar_today',
      'ligature': 'calendar_today',
      'tags': ['calendar_today']
    },
    {
      'name': 'calendar_view_day',
      'ligature': 'calendar_view_day',
      'tags': ['calendar_view_day']
    },
    {
      'name': 'call_end',
      'ligature': 'call_end',
      'tags': ['call_end']
    },
    {
      'name': 'call_made',
      'ligature': 'call_made',
      'tags': ['call_made']
    },
    {
      'name': 'call_merge',
      'ligature': 'call_merge',
      'tags': ['call_merge']
    },
    {
      'name': 'call_missed_outgoing',
      'ligature': 'call_missed_outgoing',
      'tags': ['call_missed_outgoing']
    },
    {
      'name': 'call_missed',
      'ligature': 'call_missed',
      'tags': ['call_missed']
    },
    {
      'name': 'call_received',
      'ligature': 'call_received',
      'tags': ['call_received']
    },
    {
      'name': 'call_split',
      'ligature': 'call_split',
      'tags': ['call_split']
    },
    {
      'name': 'call_to_action',
      'ligature': 'call_to_action',
      'tags': ['call_to_action']
    },
    {
      'name': 'call',
      'ligature': 'call',
      'tags': ['call']
    },
    {
      'name': 'camera_alt',
      'ligature': 'camera_alt',
      'tags': ['camera_alt']
    },
    {
      'name': 'camera_enhance',
      'ligature': 'camera_enhance',
      'tags': ['camera_enhance']
    },
    {
      'name': 'camera_front',
      'ligature': 'camera_front',
      'tags': ['camera_front']
    },
    {
      'name': 'camera_rear',
      'ligature': 'camera_rear',
      'tags': ['camera_rear']
    },
    {
      'name': 'camera_roll',
      'ligature': 'camera_roll',
      'tags': ['camera_roll']
    },
    {
      'name': 'camera',
      'ligature': 'camera',
      'tags': ['camera']
    },
    {
      'name': 'cancel_presentation',
      'ligature': 'cancel_presentation',
      'tags': ['cancel_presentation']
    },
    {
      'name': 'cancel',
      'ligature': 'cancel',
      'tags': ['cancel']
    },
    {
      'name': 'card_giftcard',
      'ligature': 'card_giftcard',
      'tags': ['card_giftcard']
    },
    {
      'name': 'card_membership',
      'ligature': 'card_membership',
      'tags': ['card_membership']
    },
    {
      'name': 'card_travel',
      'ligature': 'card_travel',
      'tags': ['card_travel']
    },
    {
      'name': 'casino',
      'ligature': 'casino',
      'tags': ['casino']
    },
    {
      'name': 'cast_connected',
      'ligature': 'cast_connected',
      'tags': ['cast_connected']
    },
    {
      'name': 'cast_for_education',
      'ligature': 'cast_for_education',
      'tags': ['cast_for_education']
    },
    {
      'name': 'cast',
      'ligature': 'cast',
      'tags': ['cast']
    },
    {
      'name': 'category',
      'ligature': 'category',
      'tags': ['category']
    },
    {
      'name': 'center_focus_strong',
      'ligature': 'center_focus_strong',
      'tags': ['center_focus_strong']
    },
    {
      'name': 'center_focus_weak',
      'ligature': 'center_focus_weak',
      'tags': ['center_focus_weak']
    },
    {
      'name': 'change_history',
      'ligature': 'change_history',
      'tags': ['change_history']
    },
    {
      'name': 'chat_bubble_outline',
      'ligature': 'chat_bubble_outline',
      'tags': ['chat_bubble_outline']
    },
    {
      'name': 'chat_bubble',
      'ligature': 'chat_bubble',
      'tags': ['chat_bubble']
    },
    {
      'name': 'chat',
      'ligature': 'chat',
      'tags': ['chat']
    },
    {
      'name': 'check_box_outline_blank',
      'ligature': 'check_box_outline_blank',
      'tags': ['check_box_outline_blank']
    },
    {
      'name': 'check_box',
      'ligature': 'check_box',
      'tags': ['check_box']
    },
    {
      'name': 'check_circle_outline',
      'ligature': 'check_circle_outline',
      'tags': ['check_circle_outline']
    },
    {
      'name': 'check_circle',
      'ligature': 'check_circle',
      'tags': ['check_circle']
    },
    {
      'name': 'check',
      'ligature': 'check',
      'tags': ['check']
    },
    {
      'name': 'chevron_left',
      'ligature': 'chevron_left',
      'tags': ['chevron_left']
    },
    {
      'name': 'chevron_right',
      'ligature': 'chevron_right',
      'tags': ['chevron_right']
    },
    {
      'name': 'child_care',
      'ligature': 'child_care',
      'tags': ['child_care']
    },
    {
      'name': 'child_friendly',
      'ligature': 'child_friendly',
      'tags': ['child_friendly']
    },
    {
      'name': 'chrome_reader_mode',
      'ligature': 'chrome_reader_mode',
      'tags': ['chrome_reader_mode']
    },
    {
      'name': 'class',
      'ligature': 'class',
      'tags': ['class']
    },
    {
      'name': 'clear_all',
      'ligature': 'clear_all',
      'tags': ['clear_all']
    },
    {
      'name': 'clear',
      'ligature': 'clear',
      'tags': ['clear']
    },
    {
      'name': 'close',
      'ligature': 'close',
      'tags': ['close']
    },
    {
      'name': 'closed_caption',
      'ligature': 'closed_caption',
      'tags': ['closed_caption']
    },
    {
      'name': 'cloud_circle',
      'ligature': 'cloud_circle',
      'tags': ['cloud_circle']
    },
    {
      'name': 'cloud_done',
      'ligature': 'cloud_done',
      'tags': ['cloud_done']
    },
    {
      'name': 'cloud_download',
      'ligature': 'cloud_download',
      'tags': ['cloud_download']
    },
    {
      'name': 'cloud_off',
      'ligature': 'cloud_off',
      'tags': ['cloud_off']
    },
    {
      'name': 'cloud_queue',
      'ligature': 'cloud_queue',
      'tags': ['cloud_queue']
    },
    {
      'name': 'cloud_upload',
      'ligature': 'cloud_upload',
      'tags': ['cloud_upload']
    },
    {
      'name': 'cloud',
      'ligature': 'cloud',
      'tags': ['cloud']
    },
    {
      'name': 'code',
      'ligature': 'code',
      'tags': ['code']
    },
    {
      'name': 'collections_bookmark',
      'ligature': 'collections_bookmark',
      'tags': ['collections_bookmark']
    },
    {
      'name': 'collections',
      'ligature': 'collections',
      'tags': ['collections']
    },
    {
      'name': 'color_lens',
      'ligature': 'color_lens',
      'tags': ['color_lens']
    },
    {
      'name': 'colorize',
      'ligature': 'colorize',
      'tags': ['colorize']
    },
    {
      'name': 'comment',
      'ligature': 'comment',
      'tags': ['comment']
    },
    {
      'name': 'commute',
      'ligature': 'commute',
      'tags': ['commute']
    },
    {
      'name': 'compare_arrows',
      'ligature': 'compare_arrows',
      'tags': ['compare_arrows']
    },
    {
      'name': 'compare',
      'ligature': 'compare',
      'tags': ['compare']
    },
    {
      'name': 'compass_calibration',
      'ligature': 'compass_calibration',
      'tags': ['compass_calibration']
    },
    {
      'name': 'computer',
      'ligature': 'computer',
      'tags': ['computer']
    },
    {
      'name': 'confirmation_number',
      'ligature': 'confirmation_number',
      'tags': ['confirmation_number']
    },
    {
      'name': 'contact_mail',
      'ligature': 'contact_mail',
      'tags': ['contact_mail']
    },
    {
      'name': 'contact_phone',
      'ligature': 'contact_phone',
      'tags': ['contact_phone']
    },
    {
      'name': 'contact_support',
      'ligature': 'contact_support',
      'tags': ['contact_support']
    },
    {
      'name': 'contacts',
      'ligature': 'contacts',
      'tags': ['contacts']
    },
    {
      'name': 'control_camera',
      'ligature': 'control_camera',
      'tags': ['control_camera']
    },
    {
      'name': 'control_point_duplicate',
      'ligature': 'control_point_duplicate',
      'tags': ['control_point_duplicate']
    },
    {
      'name': 'control_point',
      'ligature': 'control_point',
      'tags': ['control_point']
    },
    {
      'name': 'copyright',
      'ligature': 'copyright',
      'tags': ['copyright']
    },
    {
      'name': 'create_new_folder',
      'ligature': 'create_new_folder',
      'tags': ['create_new_folder']
    },
    {
      'name': 'create',
      'ligature': 'create',
      'tags': ['create']
    },
    {
      'name': 'credit_card',
      'ligature': 'credit_card',
      'tags': ['credit_card']
    },
    {
      'name': 'crop_3_2',
      'ligature': 'crop_3_2',
      'tags': ['crop_3_2']
    },
    {
      'name': 'crop_5_4',
      'ligature': 'crop_5_4',
      'tags': ['crop_5_4']
    },
    {
      'name': 'crop_7_5',
      'ligature': 'crop_7_5',
      'tags': ['crop_7_5']
    },
    {
      'name': 'crop_16_9',
      'ligature': 'crop_16_9',
      'tags': ['crop_16_9']
    },
    {
      'name': 'crop_din',
      'ligature': 'crop_din',
      'tags': ['crop_din']
    },
    {
      'name': 'crop_free',
      'ligature': 'crop_free',
      'tags': ['crop_free']
    },
    {
      'name': 'crop_landscape',
      'ligature': 'crop_landscape',
      'tags': ['crop_landscape']
    },
    {
      'name': 'crop_original',
      'ligature': 'crop_original',
      'tags': ['crop_original']
    },
    {
      'name': 'crop_portrait',
      'ligature': 'crop_portrait',
      'tags': ['crop_portrait']
    },
    {
      'name': 'crop_rotate',
      'ligature': 'crop_rotate',
      'tags': ['crop_rotate']
    },
    {
      'name': 'crop_square',
      'ligature': 'crop_square',
      'tags': ['crop_square']
    },
    {
      'name': 'crop',
      'ligature': 'crop',
      'tags': ['crop']
    },
    {
      'name': 'dashboard',
      'ligature': 'dashboard',
      'tags': ['dashboard']
    },
    {
      'name': 'data_usage',
      'ligature': 'data_usage',
      'tags': ['data_usage']
    },
    {
      'name': 'date_range',
      'ligature': 'date_range',
      'tags': ['date_range']
    },
    {
      'name': 'dehaze',
      'ligature': 'dehaze',
      'tags': ['dehaze']
    },
    {
      'name': 'delete_forever',
      'ligature': 'delete_forever',
      'tags': ['delete_forever']
    },
    {
      'name': 'delete_outline',
      'ligature': 'delete_outline',
      'tags': ['delete_outline']
    },
    {
      'name': 'delete_sweep',
      'ligature': 'delete_sweep',
      'tags': ['delete_sweep']
    },
    {
      'name': 'delete',
      'ligature': 'delete',
      'tags': ['delete']
    },
    {
      'name': 'departure_board',
      'ligature': 'departure_board',
      'tags': ['departure_board']
    },
    {
      'name': 'description',
      'ligature': 'description',
      'tags': ['description']
    },
    {
      'name': 'desktop_access_disabled',
      'ligature': 'desktop_access_disabled',
      'tags': ['desktop_access_disabled']
    },
    {
      'name': 'desktop_mac',
      'ligature': 'desktop_mac',
      'tags': ['desktop_mac']
    },
    {
      'name': 'desktop_windows',
      'ligature': 'desktop_windows',
      'tags': ['desktop_windows']
    },
    {
      'name': 'details',
      'ligature': 'details',
      'tags': ['details']
    },
    {
      'name': 'developer_board',
      'ligature': 'developer_board',
      'tags': ['developer_board']
    },
    {
      'name': 'developer_mode',
      'ligature': 'developer_mode',
      'tags': ['developer_mode']
    },
    {
      'name': 'device_hub',
      'ligature': 'device_hub',
      'tags': ['device_hub']
    },
    {
      'name': 'device_unknown',
      'ligature': 'device_unknown',
      'tags': ['device_unknown']
    },
    {
      'name': 'devices_other',
      'ligature': 'devices_other',
      'tags': ['devices_other']
    },
    {
      'name': 'devices',
      'ligature': 'devices',
      'tags': ['devices']
    },
    {
      'name': 'dialer_sip',
      'ligature': 'dialer_sip',
      'tags': ['dialer_sip']
    },
    {
      'name': 'dialpad',
      'ligature': 'dialpad',
      'tags': ['dialpad']
    },
    {
      'name': 'directions_bike',
      'ligature': 'directions_bike',
      'tags': ['directions_bike']
    },
    {
      'name': 'directions_boat',
      'ligature': 'directions_boat',
      'tags': ['directions_boat']
    },
    {
      'name': 'directions_bus',
      'ligature': 'directions_bus',
      'tags': ['directions_bus']
    },
    {
      'name': 'directions_car',
      'ligature': 'directions_car',
      'tags': ['directions_car']
    },
    {
      'name': 'directions_railway',
      'ligature': 'directions_railway',
      'tags': ['directions_railway']
    },
    {
      'name': 'directions_run',
      'ligature': 'directions_run',
      'tags': ['directions_run']
    },
    {
      'name': 'directions_subway',
      'ligature': 'directions_subway',
      'tags': ['directions_subway']
    },
    {
      'name': 'directions_transit',
      'ligature': 'directions_transit',
      'tags': ['directions_transit']
    },
    {
      'name': 'directions_walk',
      'ligature': 'directions_walk',
      'tags': ['directions_walk']
    },
    {
      'name': 'directions',
      'ligature': 'directions',
      'tags': ['directions']
    },
    {
      'name': 'disc_full',
      'ligature': 'disc_full',
      'tags': ['disc_full']
    },
    {
      'name': 'dns',
      'ligature': 'dns',
      'tags': ['dns']
    },
    {
      'name': 'dock',
      'ligature': 'dock',
      'tags': ['dock']
    },
    {
      'name': 'domain_disabled',
      'ligature': 'domain_disabled',
      'tags': ['domain_disabled']
    },
    {
      'name': 'domain',
      'ligature': 'domain',
      'tags': ['domain']
    },
    {
      'name': 'done_all',
      'ligature': 'done_all',
      'tags': ['done_all']
    },
    {
      'name': 'done_outline',
      'ligature': 'done_outline',
      'tags': ['done_outline']
    },
    {
      'name': 'done',
      'ligature': 'done',
      'tags': ['done']
    },
    {
      'name': 'donut_large',
      'ligature': 'donut_large',
      'tags': ['donut_large']
    },
    {
      'name': 'donut_small',
      'ligature': 'donut_small',
      'tags': ['donut_small']
    },
    {
      'name': 'drafts',
      'ligature': 'drafts',
      'tags': ['drafts']
    },
    {
      'name': 'drag_handle',
      'ligature': 'drag_handle',
      'tags': ['drag_handle']
    },
    {
      'name': 'drag_indicator',
      'ligature': 'drag_indicator',
      'tags': ['drag_indicator']
    },
    {
      'name': 'drive_eta',
      'ligature': 'drive_eta',
      'tags': ['drive_eta']
    },
    {
      'name': 'duo',
      'ligature': 'duo',
      'tags': ['duo']
    },
    {
      'name': 'dvr',
      'ligature': 'dvr',
      'tags': ['dvr']
    },
    {
      'name': 'edit_attributes',
      'ligature': 'edit_attributes',
      'tags': ['edit_attributes']
    },
    {
      'name': 'edit_location',
      'ligature': 'edit_location',
      'tags': ['edit_location']
    },
    {
      'name': 'edit',
      'ligature': 'edit',
      'tags': ['edit']
    },
    {
      'name': 'eject',
      'ligature': 'eject',
      'tags': ['eject']
    },
    {
      'name': 'email',
      'ligature': 'email',
      'tags': ['email']
    },
    {
      'name': 'enhanced_encryption',
      'ligature': 'enhanced_encryption',
      'tags': ['enhanced_encryption']
    },
    {
      'name': 'equalizer',
      'ligature': 'equalizer',
      'tags': ['equalizer']
    },
    {
      'name': 'error_outline',
      'ligature': 'error_outline',
      'tags': ['error_outline']
    },
    {
      'name': 'error',
      'ligature': 'error',
      'tags': ['error']
    },
    {
      'name': 'euro_symbol',
      'ligature': 'euro_symbol',
      'tags': ['euro_symbol']
    },
    {
      'name': 'ev_station',
      'ligature': 'ev_station',
      'tags': ['ev_station']
    },
    {
      'name': 'event_available',
      'ligature': 'event_available',
      'tags': ['event_available']
    },
    {
      'name': 'event_busy',
      'ligature': 'event_busy',
      'tags': ['event_busy']
    },
    {
      'name': 'event_note',
      'ligature': 'event_note',
      'tags': ['event_note']
    },
    {
      'name': 'event_seat',
      'ligature': 'event_seat',
      'tags': ['event_seat']
    },
    {
      'name': 'event',
      'ligature': 'event',
      'tags': ['event']
    },
    {
      'name': 'exit_to_app',
      'ligature': 'exit_to_app',
      'tags': ['exit_to_app']
    },
    {
      'name': 'expand_less',
      'ligature': 'expand_less',
      'tags': ['expand_less']
    },
    {
      'name': 'expand_more',
      'ligature': 'expand_more',
      'tags': ['expand_more']
    },
    {
      'name': 'explicit',
      'ligature': 'explicit',
      'tags': ['explicit']
    },
    {
      'name': 'explore_off',
      'ligature': 'explore_off',
      'tags': ['explore_off']
    },
    {
      'name': 'explore',
      'ligature': 'explore',
      'tags': ['explore']
    },
    {
      'name': 'exposure_neg_1',
      'ligature': 'exposure_neg_1',
      'tags': ['exposure_neg_1']
    },
    {
      'name': 'exposure_neg_2',
      'ligature': 'exposure_neg_2',
      'tags': ['exposure_neg_2']
    },
    {
      'name': 'exposure_plus_1',
      'ligature': 'exposure_plus_1',
      'tags': ['exposure_plus_1']
    },
    {
      'name': 'exposure_plus_2',
      'ligature': 'exposure_plus_2',
      'tags': ['exposure_plus_2']
    },
    {
      'name': 'exposure_zero',
      'ligature': 'exposure_zero',
      'tags': ['exposure_zero']
    },
    {
      'name': 'exposure',
      'ligature': 'exposure',
      'tags': ['exposure']
    },
    {
      'name': 'extension',
      'ligature': 'extension',
      'tags': ['extension']
    },
    {
      'name': 'face',
      'ligature': 'face',
      'tags': ['face']
    },
    {
      'name': 'fast_forward',
      'ligature': 'fast_forward',
      'tags': ['fast_forward']
    },
    {
      'name': 'fast_rewind',
      'ligature': 'fast_rewind',
      'tags': ['fast_rewind']
    },
    {
      'name': 'fastfood',
      'ligature': 'fastfood',
      'tags': ['fastfood']
    },
    {
      'name': 'favorite_border',
      'ligature': 'favorite_border',
      'tags': ['favorite_border']
    },
    {
      'name': 'favorite',
      'ligature': 'favorite',
      'tags': ['favorite']
    },
    {
      'name': 'featured_play_list',
      'ligature': 'featured_play_list',
      'tags': ['featured_play_list']
    },
    {
      'name': 'featured_video',
      'ligature': 'featured_video',
      'tags': ['featured_video']
    },
    {
      'name': 'feedback',
      'ligature': 'feedback',
      'tags': ['feedback']
    },
    {
      'name': 'fiber_dvr',
      'ligature': 'fiber_dvr',
      'tags': ['fiber_dvr']
    },
    {
      'name': 'fiber_manual_record',
      'ligature': 'fiber_manual_record',
      'tags': ['fiber_manual_record']
    },
    {
      'name': 'fiber_new',
      'ligature': 'fiber_new',
      'tags': ['fiber_new']
    },
    {
      'name': 'fiber_pin',
      'ligature': 'fiber_pin',
      'tags': ['fiber_pin']
    },
    {
      'name': 'fiber_smart_record',
      'ligature': 'fiber_smart_record',
      'tags': ['fiber_smart_record']
    },
    {
      'name': 'file_copy',
      'ligature': 'file_copy',
      'tags': ['file_copy']
    },
    {
      'name': 'filter_1',
      'ligature': 'filter_1',
      'tags': ['filter_1']
    },
    {
      'name': 'filter_2',
      'ligature': 'filter_2',
      'tags': ['filter_2']
    },
    {
      'name': 'filter_3',
      'ligature': 'filter_3',
      'tags': ['filter_3']
    },
    {
      'name': 'filter_4',
      'ligature': 'filter_4',
      'tags': ['filter_4']
    },
    {
      'name': 'filter_5',
      'ligature': 'filter_5',
      'tags': ['filter_5']
    },
    {
      'name': 'filter_6',
      'ligature': 'filter_6',
      'tags': ['filter_6']
    },
    {
      'name': 'filter_7',
      'ligature': 'filter_7',
      'tags': ['filter_7']
    },
    {
      'name': 'filter_8',
      'ligature': 'filter_8',
      'tags': ['filter_8']
    },
    {
      'name': 'filter_9_plus',
      'ligature': 'filter_9_plus',
      'tags': ['filter_9_plus']
    },
    {
      'name': 'filter_9',
      'ligature': 'filter_9',
      'tags': ['filter_9']
    },
    {
      'name': 'filter_b_and_w',
      'ligature': 'filter_b_and_w',
      'tags': ['filter_b_and_w']
    },
    {
      'name': 'filter_center_focus',
      'ligature': 'filter_center_focus',
      'tags': ['filter_center_focus']
    },
    {
      'name': 'filter_drama',
      'ligature': 'filter_drama',
      'tags': ['filter_drama']
    },
    {
      'name': 'filter_frames',
      'ligature': 'filter_frames',
      'tags': ['filter_frames']
    },
    {
      'name': 'filter_hdr',
      'ligature': 'filter_hdr',
      'tags': ['filter_hdr']
    },
    {
      'name': 'filter_list',
      'ligature': 'filter_list',
      'tags': ['filter_list']
    },
    {
      'name': 'filter_none',
      'ligature': 'filter_none',
      'tags': ['filter_none']
    },
    {
      'name': 'filter_tilt_shift',
      'ligature': 'filter_tilt_shift',
      'tags': ['filter_tilt_shift']
    },
    {
      'name': 'filter_vintage',
      'ligature': 'filter_vintage',
      'tags': ['filter_vintage']
    },
    {
      'name': 'filter',
      'ligature': 'filter',
      'tags': ['filter']
    },
    {
      'name': 'find_in_page',
      'ligature': 'find_in_page',
      'tags': ['find_in_page']
    },
    {
      'name': 'find_replace',
      'ligature': 'find_replace',
      'tags': ['find_replace']
    },
    {
      'name': 'fingerprint',
      'ligature': 'fingerprint',
      'tags': ['fingerprint']
    },
    {
      'name': 'first_page',
      'ligature': 'first_page',
      'tags': ['first_page']
    },
    {
      'name': 'fitness_center',
      'ligature': 'fitness_center',
      'tags': ['fitness_center']
    },
    {
      'name': 'flag',
      'ligature': 'flag',
      'tags': ['flag']
    },
    {
      'name': 'flare',
      'ligature': 'flare',
      'tags': ['flare']
    },
    {
      'name': 'flash_auto',
      'ligature': 'flash_auto',
      'tags': ['flash_auto']
    },
    {
      'name': 'flash_off',
      'ligature': 'flash_off',
      'tags': ['flash_off']
    },
    {
      'name': 'flash_on',
      'ligature': 'flash_on',
      'tags': ['flash_on']
    },
    {
      'name': 'flight_land',
      'ligature': 'flight_land',
      'tags': ['flight_land']
    },
    {
      'name': 'flight_takeoff',
      'ligature': 'flight_takeoff',
      'tags': ['flight_takeoff']
    },
    {
      'name': 'flight',
      'ligature': 'flight',
      'tags': ['flight']
    },
    {
      'name': 'flip_to_back',
      'ligature': 'flip_to_back',
      'tags': ['flip_to_back']
    },
    {
      'name': 'flip_to_front',
      'ligature': 'flip_to_front',
      'tags': ['flip_to_front']
    },
    {
      'name': 'flip',
      'ligature': 'flip',
      'tags': ['flip']
    },
    {
      'name': 'folder_open',
      'ligature': 'folder_open',
      'tags': ['folder_open']
    },
    {
      'name': 'folder_shared',
      'ligature': 'folder_shared',
      'tags': ['folder_shared']
    },
    {
      'name': 'folder_special',
      'ligature': 'folder_special',
      'tags': ['folder_special']
    },
    {
      'name': 'folder',
      'ligature': 'folder',
      'tags': ['folder']
    },
    {
      'name': 'font_download',
      'ligature': 'font_download',
      'tags': ['font_download']
    },
    {
      'name': 'format_align_center',
      'ligature': 'format_align_center',
      'tags': ['format_align_center']
    },
    {
      'name': 'format_align_justify',
      'ligature': 'format_align_justify',
      'tags': ['format_align_justify']
    },
    {
      'name': 'format_align_left',
      'ligature': 'format_align_left',
      'tags': ['format_align_left']
    },
    {
      'name': 'format_align_right',
      'ligature': 'format_align_right',
      'tags': ['format_align_right']
    },
    {
      'name': 'format_bold',
      'ligature': 'format_bold',
      'tags': ['format_bold']
    },
    {
      'name': 'format_clear',
      'ligature': 'format_clear',
      'tags': ['format_clear']
    },
    {
      'name': 'format_color_reset',
      'ligature': 'format_color_reset',
      'tags': ['format_color_reset']
    },
    {
      'name': 'format_indent_decrease',
      'ligature': 'format_indent_decrease',
      'tags': ['format_indent_decrease']
    },
    {
      'name': 'format_indent_increase',
      'ligature': 'format_indent_increase',
      'tags': ['format_indent_increase']
    },
    {
      'name': 'format_italic',
      'ligature': 'format_italic',
      'tags': ['format_italic']
    },
    {
      'name': 'format_line_spacing',
      'ligature': 'format_line_spacing',
      'tags': ['format_line_spacing']
    },
    {
      'name': 'format_list_bulleted',
      'ligature': 'format_list_bulleted',
      'tags': ['format_list_bulleted']
    },
    {
      'name': 'format_list_numbered_rtl',
      'ligature': 'format_list_numbered_rtl',
      'tags': ['format_list_numbered_rtl']
    },
    {
      'name': 'format_list_numbered',
      'ligature': 'format_list_numbered',
      'tags': ['format_list_numbered']
    },
    {
      'name': 'format_paint',
      'ligature': 'format_paint',
      'tags': ['format_paint']
    },
    {
      'name': 'format_quote',
      'ligature': 'format_quote',
      'tags': ['format_quote']
    },
    {
      'name': 'format_shapes',
      'ligature': 'format_shapes',
      'tags': ['format_shapes']
    },
    {
      'name': 'format_size',
      'ligature': 'format_size',
      'tags': ['format_size']
    },
    {
      'name': 'format_strikethrough',
      'ligature': 'format_strikethrough',
      'tags': ['format_strikethrough']
    },
    {
      'name': 'format_textdirection_l_to_r',
      'ligature': 'format_textdirection_l_to_r',
      'tags': ['format_textdirection_l_to_r']
    },
    {
      'name': 'format_textdirection_r_to_l',
      'ligature': 'format_textdirection_r_to_l',
      'tags': ['format_textdirection_r_to_l']
    },
    {
      'name': 'format_underlined',
      'ligature': 'format_underlined',
      'tags': ['format_underlined']
    },
    {
      'name': 'forum',
      'ligature': 'forum',
      'tags': ['forum']
    },
    {
      'name': 'forward_5',
      'ligature': 'forward_5',
      'tags': ['forward_5']
    },
    {
      'name': 'forward_10',
      'ligature': 'forward_10',
      'tags': ['forward_10']
    },
    {
      'name': 'forward_30',
      'ligature': 'forward_30',
      'tags': ['forward_30']
    },
    {
      'name': 'forward',
      'ligature': 'forward',
      'tags': ['forward']
    },
    {
      'name': 'free_breakfast',
      'ligature': 'free_breakfast',
      'tags': ['free_breakfast']
    },
    {
      'name': 'fullscreen_exit',
      'ligature': 'fullscreen_exit',
      'tags': ['fullscreen_exit']
    },
    {
      'name': 'fullscreen',
      'ligature': 'fullscreen',
      'tags': ['fullscreen']
    },
    {
      'name': 'functions',
      'ligature': 'functions',
      'tags': ['functions']
    },
    {
      'name': 'g_translate',
      'ligature': 'g_translate',
      'tags': ['g_translate']
    },
    {
      'name': 'gamepad',
      'ligature': 'gamepad',
      'tags': ['gamepad']
    },
    {
      'name': 'games',
      'ligature': 'games',
      'tags': ['games']
    },
    {
      'name': 'gavel',
      'ligature': 'gavel',
      'tags': ['gavel']
    },
    {
      'name': 'gesture',
      'ligature': 'gesture',
      'tags': ['gesture']
    },
    {
      'name': 'get_app',
      'ligature': 'get_app',
      'tags': ['get_app']
    },
    {
      'name': 'gif',
      'ligature': 'gif',
      'tags': ['gif']
    },
    {
      'name': 'golf_course',
      'ligature': 'golf_course',
      'tags': ['golf_course']
    },
    {
      'name': 'gps_fixed',
      'ligature': 'gps_fixed',
      'tags': ['gps_fixed']
    },
    {
      'name': 'gps_not_fixed',
      'ligature': 'gps_not_fixed',
      'tags': ['gps_not_fixed']
    },
    {
      'name': 'gps_off',
      'ligature': 'gps_off',
      'tags': ['gps_off']
    },
    {
      'name': 'grade',
      'ligature': 'grade',
      'tags': ['grade']
    },
    {
      'name': 'gradient',
      'ligature': 'gradient',
      'tags': ['gradient']
    },
    {
      'name': 'grain',
      'ligature': 'grain',
      'tags': ['grain']
    },
    {
      'name': 'graphic_eq',
      'ligature': 'graphic_eq',
      'tags': ['graphic_eq']
    },
    {
      'name': 'grid_off',
      'ligature': 'grid_off',
      'tags': ['grid_off']
    },
    {
      'name': 'grid_on',
      'ligature': 'grid_on',
      'tags': ['grid_on']
    },
    {
      'name': 'group_add',
      'ligature': 'group_add',
      'tags': ['group_add']
    },
    {
      'name': 'group_work',
      'ligature': 'group_work',
      'tags': ['group_work']
    },
    {
      'name': 'group',
      'ligature': 'group',
      'tags': ['group']
    },
    {
      'name': 'hd',
      'ligature': 'hd',
      'tags': ['hd']
    },
    {
      'name': 'hdr_off',
      'ligature': 'hdr_off',
      'tags': ['hdr_off']
    },
    {
      'name': 'hdr_on',
      'ligature': 'hdr_on',
      'tags': ['hdr_on']
    },
    {
      'name': 'hdr_strong',
      'ligature': 'hdr_strong',
      'tags': ['hdr_strong']
    },
    {
      'name': 'hdr_weak',
      'ligature': 'hdr_weak',
      'tags': ['hdr_weak']
    },
    {
      'name': 'headset_mic',
      'ligature': 'headset_mic',
      'tags': ['headset_mic']
    },
    {
      'name': 'headset',
      'ligature': 'headset',
      'tags': ['headset']
    },
    {
      'name': 'healing',
      'ligature': 'healing',
      'tags': ['healing']
    },
    {
      'name': 'hearing',
      'ligature': 'hearing',
      'tags': ['hearing']
    },
    {
      'name': 'help_outline',
      'ligature': 'help_outline',
      'tags': ['help_outline']
    },
    {
      'name': 'help',
      'ligature': 'help',
      'tags': ['help']
    },
    {
      'name': 'high_quality',
      'ligature': 'high_quality',
      'tags': ['high_quality']
    },
    {
      'name': 'highlight_off',
      'ligature': 'highlight_off',
      'tags': ['highlight_off']
    },
    {
      'name': 'highlight',
      'ligature': 'highlight',
      'tags': ['highlight']
    },
    {
      'name': 'history',
      'ligature': 'history',
      'tags': ['history']
    },
    {
      'name': 'home',
      'ligature': 'home',
      'tags': ['home']
    },
    {
      'name': 'horizontal_split',
      'ligature': 'horizontal_split',
      'tags': ['horizontal_split']
    },
    {
      'name': 'hot_tub',
      'ligature': 'hot_tub',
      'tags': ['hot_tub']
    },
    {
      'name': 'hotel',
      'ligature': 'hotel',
      'tags': ['hotel']
    },
    {
      'name': 'hourglass_empty',
      'ligature': 'hourglass_empty',
      'tags': ['hourglass_empty']
    },
    {
      'name': 'hourglass_full',
      'ligature': 'hourglass_full',
      'tags': ['hourglass_full']
    },
    {
      'name': 'how_to_reg',
      'ligature': 'how_to_reg',
      'tags': ['how_to_reg']
    },
    {
      'name': 'how_to_vote',
      'ligature': 'how_to_vote',
      'tags': ['how_to_vote']
    },
    {
      'name': 'http',
      'ligature': 'http',
      'tags': ['http']
    },
    {
      'name': 'https',
      'ligature': 'https',
      'tags': ['https']
    },
    {
      'name': 'image_aspect_ratio',
      'ligature': 'image_aspect_ratio',
      'tags': ['image_aspect_ratio']
    },
    {
      'name': 'image_search',
      'ligature': 'image_search',
      'tags': ['image_search']
    },
    {
      'name': 'image',
      'ligature': 'image',
      'tags': ['image']
    },
    {
      'name': 'import_contacts',
      'ligature': 'import_contacts',
      'tags': ['import_contacts']
    },
    {
      'name': 'import_export',
      'ligature': 'import_export',
      'tags': ['import_export']
    },
    {
      'name': 'important_devices',
      'ligature': 'important_devices',
      'tags': ['important_devices']
    },
    {
      'name': 'inbox',
      'ligature': 'inbox',
      'tags': ['inbox']
    },
    {
      'name': 'indeterminate_check_box',
      'ligature': 'indeterminate_check_box',
      'tags': ['indeterminate_check_box']
    },
    {
      'name': 'info',
      'ligature': 'info',
      'tags': ['info']
    },
    {
      'name': 'input',
      'ligature': 'input',
      'tags': ['input']
    },
    {
      'name': 'insert_chart_outlined',
      'ligature': 'insert_chart_outlined',
      'tags': ['insert_chart_outlined']
    },
    {
      'name': 'insert_chart',
      'ligature': 'insert_chart',
      'tags': ['insert_chart']
    },
    {
      'name': 'insert_comment',
      'ligature': 'insert_comment',
      'tags': ['insert_comment']
    },
    {
      'name': 'insert_drive_file',
      'ligature': 'insert_drive_file',
      'tags': ['insert_drive_file']
    },
    {
      'name': 'insert_emoticon',
      'ligature': 'insert_emoticon',
      'tags': ['insert_emoticon']
    },
    {
      'name': 'insert_invitation',
      'ligature': 'insert_invitation',
      'tags': ['insert_invitation']
    },
    {
      'name': 'insert_link',
      'ligature': 'insert_link',
      'tags': ['insert_link']
    },
    {
      'name': 'insert_photo',
      'ligature': 'insert_photo',
      'tags': ['insert_photo']
    },
    {
      'name': 'invert_colors_off',
      'ligature': 'invert_colors_off',
      'tags': ['invert_colors_off']
    },
    {
      'name': 'invert_colors',
      'ligature': 'invert_colors',
      'tags': ['invert_colors']
    },
    {
      'name': 'iso',
      'ligature': 'iso',
      'tags': ['iso']
    },
    {
      'name': 'keyboard_arrow_down',
      'ligature': 'keyboard_arrow_down',
      'tags': ['keyboard_arrow_down']
    },
    {
      'name': 'keyboard_arrow_left',
      'ligature': 'keyboard_arrow_left',
      'tags': ['keyboard_arrow_left']
    },
    {
      'name': 'keyboard_arrow_right',
      'ligature': 'keyboard_arrow_right',
      'tags': ['keyboard_arrow_right']
    },
    {
      'name': 'keyboard_arrow_up',
      'ligature': 'keyboard_arrow_up',
      'tags': ['keyboard_arrow_up']
    },
    {
      'name': 'keyboard_backspace',
      'ligature': 'keyboard_backspace',
      'tags': ['keyboard_backspace']
    },
    {
      'name': 'keyboard_capslock',
      'ligature': 'keyboard_capslock',
      'tags': ['keyboard_capslock']
    },
    {
      'name': 'keyboard_hide',
      'ligature': 'keyboard_hide',
      'tags': ['keyboard_hide']
    },
    {
      'name': 'keyboard_return',
      'ligature': 'keyboard_return',
      'tags': ['keyboard_return']
    },
    {
      'name': 'keyboard_tab',
      'ligature': 'keyboard_tab',
      'tags': ['keyboard_tab']
    },
    {
      'name': 'keyboard_voice',
      'ligature': 'keyboard_voice',
      'tags': ['keyboard_voice']
    },
    {
      'name': 'keyboard',
      'ligature': 'keyboard',
      'tags': ['keyboard']
    },
    {
      'name': 'kitchen',
      'ligature': 'kitchen',
      'tags': ['kitchen']
    },
    {
      'name': 'label_important',
      'ligature': 'label_important',
      'tags': ['label_important']
    },
    {
      'name': 'label_off',
      'ligature': 'label_off',
      'tags': ['label_off']
    },
    {
      'name': 'label',
      'ligature': 'label',
      'tags': ['label']
    },
    {
      'name': 'landscape',
      'ligature': 'landscape',
      'tags': ['landscape']
    },
    {
      'name': 'language',
      'ligature': 'language',
      'tags': ['language']
    },
    {
      'name': 'laptop_chromebook',
      'ligature': 'laptop_chromebook',
      'tags': ['laptop_chromebook']
    },
    {
      'name': 'laptop_mac',
      'ligature': 'laptop_mac',
      'tags': ['laptop_mac']
    },
    {
      'name': 'laptop_windows',
      'ligature': 'laptop_windows',
      'tags': ['laptop_windows']
    },
    {
      'name': 'laptop',
      'ligature': 'laptop',
      'tags': ['laptop']
    },
    {
      'name': 'last_page',
      'ligature': 'last_page',
      'tags': ['last_page']
    },
    {
      'name': 'launch',
      'ligature': 'launch',
      'tags': ['launch']
    },
    {
      'name': 'layers_clear',
      'ligature': 'layers_clear',
      'tags': ['layers_clear']
    },
    {
      'name': 'layers',
      'ligature': 'layers',
      'tags': ['layers']
    },
    {
      'name': 'leak_add',
      'ligature': 'leak_add',
      'tags': ['leak_add']
    },
    {
      'name': 'leak_remove',
      'ligature': 'leak_remove',
      'tags': ['leak_remove']
    },
    {
      'name': 'lens',
      'ligature': 'lens',
      'tags': ['lens']
    },
    {
      'name': 'library_add',
      'ligature': 'library_add',
      'tags': ['library_add']
    },
    {
      'name': 'library_books',
      'ligature': 'library_books',
      'tags': ['library_books']
    },
    {
      'name': 'library_music',
      'ligature': 'library_music',
      'tags': ['library_music']
    },
    {
      'name': 'line_style',
      'ligature': 'line_style',
      'tags': ['line_style']
    },
    {
      'name': 'line_weight',
      'ligature': 'line_weight',
      'tags': ['line_weight']
    },
    {
      'name': 'linear_scale',
      'ligature': 'linear_scale',
      'tags': ['linear_scale']
    },
    {
      'name': 'link_off',
      'ligature': 'link_off',
      'tags': ['link_off']
    },
    {
      'name': 'link',
      'ligature': 'link',
      'tags': ['link']
    },
    {
      'name': 'linked_camera',
      'ligature': 'linked_camera',
      'tags': ['linked_camera']
    },
    {
      'name': 'list_alt',
      'ligature': 'list_alt',
      'tags': ['list_alt']
    },
    {
      'name': 'list',
      'ligature': 'list',
      'tags': ['list']
    },
    {
      'name': 'live_help',
      'ligature': 'live_help',
      'tags': ['live_help']
    },
    {
      'name': 'live_tv',
      'ligature': 'live_tv',
      'tags': ['live_tv']
    },
    {
      'name': 'local_activity',
      'ligature': 'local_activity',
      'tags': ['local_activity']
    },
    {
      'name': 'local_airport',
      'ligature': 'local_airport',
      'tags': ['local_airport']
    },
    {
      'name': 'local_atm',
      'ligature': 'local_atm',
      'tags': ['local_atm']
    },
    {
      'name': 'local_bar',
      'ligature': 'local_bar',
      'tags': ['local_bar']
    },
    {
      'name': 'local_cafe',
      'ligature': 'local_cafe',
      'tags': ['local_cafe']
    },
    {
      'name': 'local_car_wash',
      'ligature': 'local_car_wash',
      'tags': ['local_car_wash']
    },
    {
      'name': 'local_convenience_store',
      'ligature': 'local_convenience_store',
      'tags': ['local_convenience_store']
    },
    {
      'name': 'local_dining',
      'ligature': 'local_dining',
      'tags': ['local_dining']
    },
    {
      'name': 'local_drink',
      'ligature': 'local_drink',
      'tags': ['local_drink']
    },
    {
      'name': 'local_florist',
      'ligature': 'local_florist',
      'tags': ['local_florist']
    },
    {
      'name': 'local_gas_station',
      'ligature': 'local_gas_station',
      'tags': ['local_gas_station']
    },
    {
      'name': 'local_grocery_store',
      'ligature': 'local_grocery_store',
      'tags': ['local_grocery_store']
    },
    {
      'name': 'local_hospital',
      'ligature': 'local_hospital',
      'tags': ['local_hospital']
    },
    {
      'name': 'local_hotel',
      'ligature': 'local_hotel',
      'tags': ['local_hotel']
    },
    {
      'name': 'local_laundry_service',
      'ligature': 'local_laundry_service',
      'tags': ['local_laundry_service']
    },
    {
      'name': 'local_library',
      'ligature': 'local_library',
      'tags': ['local_library']
    },
    {
      'name': 'local_mall',
      'ligature': 'local_mall',
      'tags': ['local_mall']
    },
    {
      'name': 'local_movies',
      'ligature': 'local_movies',
      'tags': ['local_movies']
    },
    {
      'name': 'local_offer',
      'ligature': 'local_offer',
      'tags': ['local_offer']
    },
    {
      'name': 'local_parking',
      'ligature': 'local_parking',
      'tags': ['local_parking']
    },
    {
      'name': 'local_pharmacy',
      'ligature': 'local_pharmacy',
      'tags': ['local_pharmacy']
    },
    {
      'name': 'local_phone',
      'ligature': 'local_phone',
      'tags': ['local_phone']
    },
    {
      'name': 'local_pizza',
      'ligature': 'local_pizza',
      'tags': ['local_pizza']
    },
    {
      'name': 'local_play',
      'ligature': 'local_play',
      'tags': ['local_play']
    },
    {
      'name': 'local_post_office',
      'ligature': 'local_post_office',
      'tags': ['local_post_office']
    },
    {
      'name': 'local_printshop',
      'ligature': 'local_printshop',
      'tags': ['local_printshop']
    },
    {
      'name': 'local_see',
      'ligature': 'local_see',
      'tags': ['local_see']
    },
    {
      'name': 'local_shipping',
      'ligature': 'local_shipping',
      'tags': ['local_shipping']
    },
    {
      'name': 'local_taxi',
      'ligature': 'local_taxi',
      'tags': ['local_taxi']
    },
    {
      'name': 'location_city',
      'ligature': 'location_city',
      'tags': ['location_city']
    },
    {
      'name': 'location_disabled',
      'ligature': 'location_disabled',
      'tags': ['location_disabled']
    },
    {
      'name': 'location_off',
      'ligature': 'location_off',
      'tags': ['location_off']
    },
    {
      'name': 'location_on',
      'ligature': 'location_on',
      'tags': ['location_on']
    },
    {
      'name': 'location_searching',
      'ligature': 'location_searching',
      'tags': ['location_searching']
    },
    {
      'name': 'lock_open',
      'ligature': 'lock_open',
      'tags': ['lock_open']
    },
    {
      'name': 'lock',
      'ligature': 'lock',
      'tags': ['lock']
    },
    {
      'name': 'looks_3',
      'ligature': 'looks_3',
      'tags': ['looks_3']
    },
    {
      'name': 'looks_4',
      'ligature': 'looks_4',
      'tags': ['looks_4']
    },
    {
      'name': 'looks_5',
      'ligature': 'looks_5',
      'tags': ['looks_5']
    },
    {
      'name': 'looks_6',
      'ligature': 'looks_6',
      'tags': ['looks_6']
    },
    {
      'name': 'looks_one',
      'ligature': 'looks_one',
      'tags': ['looks_one']
    },
    {
      'name': 'looks_two',
      'ligature': 'looks_two',
      'tags': ['looks_two']
    },
    {
      'name': 'looks',
      'ligature': 'looks',
      'tags': ['looks']
    },
    {
      'name': 'loop',
      'ligature': 'loop',
      'tags': ['loop']
    },
    {
      'name': 'loupe',
      'ligature': 'loupe',
      'tags': ['loupe']
    },
    {
      'name': 'low_priority',
      'ligature': 'low_priority',
      'tags': ['low_priority']
    },
    {
      'name': 'loyalty',
      'ligature': 'loyalty',
      'tags': ['loyalty']
    },
    {
      'name': 'mail_outline',
      'ligature': 'mail_outline',
      'tags': ['mail_outline']
    },
    {
      'name': 'mail',
      'ligature': 'mail',
      'tags': ['mail']
    },
    {
      'name': 'map',
      'ligature': 'map',
      'tags': ['map']
    },
    {
      'name': 'markunread_mailbox',
      'ligature': 'markunread_mailbox',
      'tags': ['markunread_mailbox']
    },
    {
      'name': 'markunread',
      'ligature': 'markunread',
      'tags': ['markunread']
    },
    {
      'name': 'maximize',
      'ligature': 'maximize',
      'tags': ['maximize']
    },
    {
      'name': 'meeting_room',
      'ligature': 'meeting_room',
      'tags': ['meeting_room']
    },
    {
      'name': 'memory',
      'ligature': 'memory',
      'tags': ['memory']
    },
    {
      'name': 'menu',
      'ligature': 'menu',
      'tags': ['menu']
    },
    {
      'name': 'merge_type',
      'ligature': 'merge_type',
      'tags': ['merge_type']
    },
    {
      'name': 'message',
      'ligature': 'message',
      'tags': ['message']
    },
    {
      'name': 'mic_none',
      'ligature': 'mic_none',
      'tags': ['mic_none']
    },
    {
      'name': 'mic_off',
      'ligature': 'mic_off',
      'tags': ['mic_off']
    },
    {
      'name': 'mic',
      'ligature': 'mic',
      'tags': ['mic']
    },
    {
      'name': 'minimize',
      'ligature': 'minimize',
      'tags': ['minimize']
    },
    {
      'name': 'missed_video_call',
      'ligature': 'missed_video_call',
      'tags': ['missed_video_call']
    },
    {
      'name': 'mms',
      'ligature': 'mms',
      'tags': ['mms']
    },
    {
      'name': 'mobile_friendly',
      'ligature': 'mobile_friendly',
      'tags': ['mobile_friendly']
    },
    {
      'name': 'mobile_off',
      'ligature': 'mobile_off',
      'tags': ['mobile_off']
    },
    {
      'name': 'mobile_screen_share',
      'ligature': 'mobile_screen_share',
      'tags': ['mobile_screen_share']
    },
    {
      'name': 'mode_comment',
      'ligature': 'mode_comment',
      'tags': ['mode_comment']
    },
    {
      'name': 'monetization_on',
      'ligature': 'monetization_on',
      'tags': ['monetization_on']
    },
    {
      'name': 'money_off',
      'ligature': 'money_off',
      'tags': ['money_off']
    },
    {
      'name': 'money',
      'ligature': 'money',
      'tags': ['money']
    },
    {
      'name': 'monochrome_photos',
      'ligature': 'monochrome_photos',
      'tags': ['monochrome_photos']
    },
    {
      'name': 'mood_bad',
      'ligature': 'mood_bad',
      'tags': ['mood_bad']
    },
    {
      'name': 'mood',
      'ligature': 'mood',
      'tags': ['mood']
    },
    {
      'name': 'more_horiz',
      'ligature': 'more_horiz',
      'tags': ['more_horiz']
    },
    {
      'name': 'more_vert',
      'ligature': 'more_vert',
      'tags': ['more_vert']
    },
    {
      'name': 'more',
      'ligature': 'more',
      'tags': ['more']
    },
    {
      'name': 'motorcycle',
      'ligature': 'motorcycle',
      'tags': ['motorcycle']
    },
    {
      'name': 'mouse',
      'ligature': 'mouse',
      'tags': ['mouse']
    },
    {
      'name': 'move_to_inbox',
      'ligature': 'move_to_inbox',
      'tags': ['move_to_inbox']
    },
    {
      'name': 'movie_creation',
      'ligature': 'movie_creation',
      'tags': ['movie_creation']
    },
    {
      'name': 'movie_filter',
      'ligature': 'movie_filter',
      'tags': ['movie_filter']
    },
    {
      'name': 'movie',
      'ligature': 'movie',
      'tags': ['movie']
    },
    {
      'name': 'multiline_chart',
      'ligature': 'multiline_chart',
      'tags': ['multiline_chart']
    },
    {
      'name': 'music_note',
      'ligature': 'music_note',
      'tags': ['music_note']
    },
    {
      'name': 'music_off',
      'ligature': 'music_off',
      'tags': ['music_off']
    },
    {
      'name': 'music_video',
      'ligature': 'music_video',
      'tags': ['music_video']
    },
    {
      'name': 'my_location',
      'ligature': 'my_location',
      'tags': ['my_location']
    },
    {
      'name': 'nature_people',
      'ligature': 'nature_people',
      'tags': ['nature_people']
    },
    {
      'name': 'nature',
      'ligature': 'nature',
      'tags': ['nature']
    },
    {
      'name': 'navigate_before',
      'ligature': 'navigate_before',
      'tags': ['navigate_before']
    },
    {
      'name': 'navigate_next',
      'ligature': 'navigate_next',
      'tags': ['navigate_next']
    },
    {
      'name': 'navigation',
      'ligature': 'navigation',
      'tags': ['navigation']
    },
    {
      'name': 'near_me',
      'ligature': 'near_me',
      'tags': ['near_me']
    },
    {
      'name': 'network_check',
      'ligature': 'network_check',
      'tags': ['network_check']
    },
    {
      'name': 'network_locked',
      'ligature': 'network_locked',
      'tags': ['network_locked']
    },
    {
      'name': 'new_releases',
      'ligature': 'new_releases',
      'tags': ['new_releases']
    },
    {
      'name': 'next_week',
      'ligature': 'next_week',
      'tags': ['next_week']
    },
    {
      'name': 'nfc',
      'ligature': 'nfc',
      'tags': ['nfc']
    },
    {
      'name': 'no_encryption',
      'ligature': 'no_encryption',
      'tags': ['no_encryption']
    },
    {
      'name': 'no_meeting_room',
      'ligature': 'no_meeting_room',
      'tags': ['no_meeting_room']
    },
    {
      'name': 'no_sim',
      'ligature': 'no_sim',
      'tags': ['no_sim']
    },
    {
      'name': 'not_interested',
      'ligature': 'not_interested',
      'tags': ['not_interested']
    },
    {
      'name': 'not_listed_location',
      'ligature': 'not_listed_location',
      'tags': ['not_listed_location']
    },
    {
      'name': 'note_add',
      'ligature': 'note_add',
      'tags': ['note_add']
    },
    {
      'name': 'note',
      'ligature': 'note',
      'tags': ['note']
    },
    {
      'name': 'notes',
      'ligature': 'notes',
      'tags': ['notes']
    },
    {
      'name': 'notification_important',
      'ligature': 'notification_important',
      'tags': ['notification_important']
    },
    {
      'name': 'notifications_active',
      'ligature': 'notifications_active',
      'tags': ['notifications_active']
    },
    {
      'name': 'notifications_none',
      'ligature': 'notifications_none',
      'tags': ['notifications_none']
    },
    {
      'name': 'notifications_off',
      'ligature': 'notifications_off',
      'tags': ['notifications_off']
    },
    {
      'name': 'notifications_paused',
      'ligature': 'notifications_paused',
      'tags': ['notifications_paused']
    },
    {
      'name': 'notifications',
      'ligature': 'notifications',
      'tags': ['notifications']
    },
    {
      'name': 'offline_bolt',
      'ligature': 'offline_bolt',
      'tags': ['offline_bolt']
    },
    {
      'name': 'offline_pin',
      'ligature': 'offline_pin',
      'tags': ['offline_pin']
    },
    {
      'name': 'ondemand_video',
      'ligature': 'ondemand_video',
      'tags': ['ondemand_video']
    },
    {
      'name': 'opacity',
      'ligature': 'opacity',
      'tags': ['opacity']
    },
    {
      'name': 'open_in_browser',
      'ligature': 'open_in_browser',
      'tags': ['open_in_browser']
    },
    {
      'name': 'open_in_new',
      'ligature': 'open_in_new',
      'tags': ['open_in_new']
    },
    {
      'name': 'open_with',
      'ligature': 'open_with',
      'tags': ['open_with']
    },
    {
      'name': 'outlined_flag',
      'ligature': 'outlined_flag',
      'tags': ['outlined_flag']
    },
    {
      'name': 'pages',
      'ligature': 'pages',
      'tags': ['pages']
    },
    {
      'name': 'pageview',
      'ligature': 'pageview',
      'tags': ['pageview']
    },
    {
      'name': 'palette',
      'ligature': 'palette',
      'tags': ['palette']
    },
    {
      'name': 'pan_tool',
      'ligature': 'pan_tool',
      'tags': ['pan_tool']
    },
    {
      'name': 'panorama_fish_eye',
      'ligature': 'panorama_fish_eye',
      'tags': ['panorama_fish_eye']
    },
    {
      'name': 'panorama_horizontal',
      'ligature': 'panorama_horizontal',
      'tags': ['panorama_horizontal']
    },
    {
      'name': 'panorama_vertical',
      'ligature': 'panorama_vertical',
      'tags': ['panorama_vertical']
    },
    {
      'name': 'panorama_wide_angle',
      'ligature': 'panorama_wide_angle',
      'tags': ['panorama_wide_angle']
    },
    {
      'name': 'panorama',
      'ligature': 'panorama',
      'tags': ['panorama']
    },
    {
      'name': 'party_mode',
      'ligature': 'party_mode',
      'tags': ['party_mode']
    },
    {
      'name': 'pause_circle_filled',
      'ligature': 'pause_circle_filled',
      'tags': ['pause_circle_filled']
    },
    {
      'name': 'pause_circle_outline',
      'ligature': 'pause_circle_outline',
      'tags': ['pause_circle_outline']
    },
    {
      'name': 'pause_presentation',
      'ligature': 'pause_presentation',
      'tags': ['pause_presentation']
    },
    {
      'name': 'pause',
      'ligature': 'pause',
      'tags': ['pause']
    },
    {
      'name': 'payment',
      'ligature': 'payment',
      'tags': ['payment']
    },
    {
      'name': 'people_outline',
      'ligature': 'people_outline',
      'tags': ['people_outline']
    },
    {
      'name': 'people',
      'ligature': 'people',
      'tags': ['people']
    },
    {
      'name': 'perm_camera_mic',
      'ligature': 'perm_camera_mic',
      'tags': ['perm_camera_mic']
    },
    {
      'name': 'perm_contact_calendar',
      'ligature': 'perm_contact_calendar',
      'tags': ['perm_contact_calendar']
    },
    {
      'name': 'perm_data_setting',
      'ligature': 'perm_data_setting',
      'tags': ['perm_data_setting']
    },
    {
      'name': 'perm_device_information',
      'ligature': 'perm_device_information',
      'tags': ['perm_device_information']
    },
    {
      'name': 'perm_identity',
      'ligature': 'perm_identity',
      'tags': ['perm_identity']
    },
    {
      'name': 'perm_media',
      'ligature': 'perm_media',
      'tags': ['perm_media']
    },
    {
      'name': 'perm_phone_msg',
      'ligature': 'perm_phone_msg',
      'tags': ['perm_phone_msg']
    },
    {
      'name': 'perm_scan_wifi',
      'ligature': 'perm_scan_wifi',
      'tags': ['perm_scan_wifi']
    },
    {
      'name': 'person_add_disabled',
      'ligature': 'person_add_disabled',
      'tags': ['person_add_disabled']
    },
    {
      'name': 'person_add',
      'ligature': 'person_add',
      'tags': ['person_add']
    },
    {
      'name': 'person_outline',
      'ligature': 'person_outline',
      'tags': ['person_outline']
    },
    {
      'name': 'person_pin_circle',
      'ligature': 'person_pin_circle',
      'tags': ['person_pin_circle']
    },
    {
      'name': 'person_pin',
      'ligature': 'person_pin',
      'tags': ['person_pin']
    },
    {
      'name': 'person',
      'ligature': 'person',
      'tags': ['person']
    },
    {
      'name': 'personal_video',
      'ligature': 'personal_video',
      'tags': ['personal_video']
    },
    {
      'name': 'pets',
      'ligature': 'pets',
      'tags': ['pets']
    },
    {
      'name': 'phone_android',
      'ligature': 'phone_android',
      'tags': ['phone_android']
    },
    {
      'name': 'phone_bluetooth_speaker',
      'ligature': 'phone_bluetooth_speaker',
      'tags': ['phone_bluetooth_speaker']
    },
    {
      'name': 'phone_callback',
      'ligature': 'phone_callback',
      'tags': ['phone_callback']
    },
    {
      'name': 'phone_forwarded',
      'ligature': 'phone_forwarded',
      'tags': ['phone_forwarded']
    },
    {
      'name': 'phone_in_talk',
      'ligature': 'phone_in_talk',
      'tags': ['phone_in_talk']
    },
    {
      'name': 'phone_iphone',
      'ligature': 'phone_iphone',
      'tags': ['phone_iphone']
    },
    {
      'name': 'phone_locked',
      'ligature': 'phone_locked',
      'tags': ['phone_locked']
    },
    {
      'name': 'phone_missed',
      'ligature': 'phone_missed',
      'tags': ['phone_missed']
    },
    {
      'name': 'phone_paused',
      'ligature': 'phone_paused',
      'tags': ['phone_paused']
    },
    {
      'name': 'phone',
      'ligature': 'phone',
      'tags': ['phone']
    },
    {
      'name': 'phonelink_erase',
      'ligature': 'phonelink_erase',
      'tags': ['phonelink_erase']
    },
    {
      'name': 'phonelink_lock',
      'ligature': 'phonelink_lock',
      'tags': ['phonelink_lock']
    },
    {
      'name': 'phonelink_off',
      'ligature': 'phonelink_off',
      'tags': ['phonelink_off']
    },
    {
      'name': 'phonelink_ring',
      'ligature': 'phonelink_ring',
      'tags': ['phonelink_ring']
    },
    {
      'name': 'phonelink_setup',
      'ligature': 'phonelink_setup',
      'tags': ['phonelink_setup']
    },
    {
      'name': 'phonelink',
      'ligature': 'phonelink',
      'tags': ['phonelink']
    },
    {
      'name': 'photo_album',
      'ligature': 'photo_album',
      'tags': ['photo_album']
    },
    {
      'name': 'photo_camera',
      'ligature': 'photo_camera',
      'tags': ['photo_camera']
    },
    {
      'name': 'photo_filter',
      'ligature': 'photo_filter',
      'tags': ['photo_filter']
    },
    {
      'name': 'photo_library',
      'ligature': 'photo_library',
      'tags': ['photo_library']
    },
    {
      'name': 'photo_size_select_actual',
      'ligature': 'photo_size_select_actual',
      'tags': ['photo_size_select_actual']
    },
    {
      'name': 'photo_size_select_large',
      'ligature': 'photo_size_select_large',
      'tags': ['photo_size_select_large']
    },
    {
      'name': 'photo_size_select_small',
      'ligature': 'photo_size_select_small',
      'tags': ['photo_size_select_small']
    },
    {
      'name': 'photo',
      'ligature': 'photo',
      'tags': ['photo']
    },
    {
      'name': 'picture_as_pdf',
      'ligature': 'picture_as_pdf',
      'tags': ['picture_as_pdf']
    },
    {
      'name': 'picture_in_picture_alt',
      'ligature': 'picture_in_picture_alt',
      'tags': ['picture_in_picture_alt']
    },
    {
      'name': 'picture_in_picture',
      'ligature': 'picture_in_picture',
      'tags': ['picture_in_picture']
    },
    {
      'name': 'pie_chart',
      'ligature': 'pie_chart',
      'tags': ['pie_chart']
    },
    {
      'name': 'pin_drop',
      'ligature': 'pin_drop',
      'tags': ['pin_drop']
    },
    {
      'name': 'place',
      'ligature': 'place',
      'tags': ['place']
    },
    {
      'name': 'play_arrow',
      'ligature': 'play_arrow',
      'tags': ['play_arrow']
    },
    {
      'name': 'play_circle_filled_white',
      'ligature': 'play_circle_filled_white',
      'tags': ['play_circle_filled_white']
    },
    {
      'name': 'play_circle_filled',
      'ligature': 'play_circle_filled',
      'tags': ['play_circle_filled']
    },
    {
      'name': 'play_circle_outline',
      'ligature': 'play_circle_outline',
      'tags': ['play_circle_outline']
    },
    {
      'name': 'play_for_work',
      'ligature': 'play_for_work',
      'tags': ['play_for_work']
    },
    {
      'name': 'playlist_add_check',
      'ligature': 'playlist_add_check',
      'tags': ['playlist_add_check']
    },
    {
      'name': 'playlist_add',
      'ligature': 'playlist_add',
      'tags': ['playlist_add']
    },
    {
      'name': 'playlist_play',
      'ligature': 'playlist_play',
      'tags': ['playlist_play']
    },
    {
      'name': 'plus_one',
      'ligature': 'plus_one',
      'tags': ['plus_one']
    },
    {
      'name': 'poll',
      'ligature': 'poll',
      'tags': ['poll']
    },
    {
      'name': 'polymer',
      'ligature': 'polymer',
      'tags': ['polymer']
    },
    {
      'name': 'pool',
      'ligature': 'pool',
      'tags': ['pool']
    },
    {
      'name': 'portable_wifi_off',
      'ligature': 'portable_wifi_off',
      'tags': ['portable_wifi_off']
    },
    {
      'name': 'portrait',
      'ligature': 'portrait',
      'tags': ['portrait']
    },
    {
      'name': 'power_input',
      'ligature': 'power_input',
      'tags': ['power_input']
    },
    {
      'name': 'power_off',
      'ligature': 'power_off',
      'tags': ['power_off']
    },
    {
      'name': 'power_settings_new',
      'ligature': 'power_settings_new',
      'tags': ['power_settings_new']
    },
    {
      'name': 'power',
      'ligature': 'power',
      'tags': ['power']
    },
    {
      'name': 'pregnant_woman',
      'ligature': 'pregnant_woman',
      'tags': ['pregnant_woman']
    },
    {
      'name': 'present_to_all',
      'ligature': 'present_to_all',
      'tags': ['present_to_all']
    },
    {
      'name': 'print_disabled',
      'ligature': 'print_disabled',
      'tags': ['print_disabled']
    },
    {
      'name': 'print',
      'ligature': 'print',
      'tags': ['print']
    },
    {
      'name': 'priority_high',
      'ligature': 'priority_high',
      'tags': ['priority_high']
    },
    {
      'name': 'public',
      'ligature': 'public',
      'tags': ['public']
    },
    {
      'name': 'publish',
      'ligature': 'publish',
      'tags': ['publish']
    },
    {
      'name': 'query_builder',
      'ligature': 'query_builder',
      'tags': ['query_builder']
    },
    {
      'name': 'question_answer',
      'ligature': 'question_answer',
      'tags': ['question_answer']
    },
    {
      'name': 'queue_music',
      'ligature': 'queue_music',
      'tags': ['queue_music']
    },
    {
      'name': 'queue_play_next',
      'ligature': 'queue_play_next',
      'tags': ['queue_play_next']
    },
    {
      'name': 'queue',
      'ligature': 'queue',
      'tags': ['queue']
    },
    {
      'name': 'radio_button_checked',
      'ligature': 'radio_button_checked',
      'tags': ['radio_button_checked']
    },
    {
      'name': 'radio_button_unchecked',
      'ligature': 'radio_button_unchecked',
      'tags': ['radio_button_unchecked']
    },
    {
      'name': 'radio',
      'ligature': 'radio',
      'tags': ['radio']
    },
    {
      'name': 'rate_review',
      'ligature': 'rate_review',
      'tags': ['rate_review']
    },
    {
      'name': 'receipt',
      'ligature': 'receipt',
      'tags': ['receipt']
    },
    {
      'name': 'recent_actors',
      'ligature': 'recent_actors',
      'tags': ['recent_actors']
    },
    {
      'name': 'record_voice_over',
      'ligature': 'record_voice_over',
      'tags': ['record_voice_over']
    },
    {
      'name': 'redeem',
      'ligature': 'redeem',
      'tags': ['redeem']
    },
    {
      'name': 'redo',
      'ligature': 'redo',
      'tags': ['redo']
    },
    {
      'name': 'refresh',
      'ligature': 'refresh',
      'tags': ['refresh']
    },
    {
      'name': 'remove_circle_outline',
      'ligature': 'remove_circle_outline',
      'tags': ['remove_circle_outline']
    },
    {
      'name': 'remove_circle',
      'ligature': 'remove_circle',
      'tags': ['remove_circle']
    },
    {
      'name': 'remove_from_queue',
      'ligature': 'remove_from_queue',
      'tags': ['remove_from_queue']
    },
    {
      'name': 'remove_red_eye',
      'ligature': 'remove_red_eye',
      'tags': ['remove_red_eye']
    },
    {
      'name': 'remove_shopping_cart',
      'ligature': 'remove_shopping_cart',
      'tags': ['remove_shopping_cart']
    },
    {
      'name': 'remove',
      'ligature': 'remove',
      'tags': ['remove']
    },
    {
      'name': 'reorder',
      'ligature': 'reorder',
      'tags': ['reorder']
    },
    {
      'name': 'repeat_one',
      'ligature': 'repeat_one',
      'tags': ['repeat_one']
    },
    {
      'name': 'repeat',
      'ligature': 'repeat',
      'tags': ['repeat']
    },
    {
      'name': 'replay_5',
      'ligature': 'replay_5',
      'tags': ['replay_5']
    },
    {
      'name': 'replay_10',
      'ligature': 'replay_10',
      'tags': ['replay_10']
    },
    {
      'name': 'replay_30',
      'ligature': 'replay_30',
      'tags': ['replay_30']
    },
    {
      'name': 'replay',
      'ligature': 'replay',
      'tags': ['replay']
    },
    {
      'name': 'reply_all',
      'ligature': 'reply_all',
      'tags': ['reply_all']
    },
    {
      'name': 'reply',
      'ligature': 'reply',
      'tags': ['reply']
    },
    {
      'name': 'report_off',
      'ligature': 'report_off',
      'tags': ['report_off']
    },
    {
      'name': 'report_problem',
      'ligature': 'report_problem',
      'tags': ['report_problem']
    },
    {
      'name': 'report',
      'ligature': 'report',
      'tags': ['report']
    },
    {
      'name': 'restaurant_menu',
      'ligature': 'restaurant_menu',
      'tags': ['restaurant_menu']
    },
    {
      'name': 'restaurant',
      'ligature': 'restaurant',
      'tags': ['restaurant']
    },
    {
      'name': 'restore_from_trash',
      'ligature': 'restore_from_trash',
      'tags': ['restore_from_trash']
    },
    {
      'name': 'restore_page',
      'ligature': 'restore_page',
      'tags': ['restore_page']
    },
    {
      'name': 'restore',
      'ligature': 'restore',
      'tags': ['restore']
    },
    {
      'name': 'ring_volume',
      'ligature': 'ring_volume',
      'tags': ['ring_volume']
    },
    {
      'name': 'room_service',
      'ligature': 'room_service',
      'tags': ['room_service']
    },
    {
      'name': 'room',
      'ligature': 'room',
      'tags': ['room']
    },
    {
      'name': 'rotate_90_degrees_ccw',
      'ligature': 'rotate_90_degrees_ccw',
      'tags': ['rotate_90_degrees_ccw']
    },
    {
      'name': 'rotate_left',
      'ligature': 'rotate_left',
      'tags': ['rotate_left']
    },
    {
      'name': 'rotate_right',
      'ligature': 'rotate_right',
      'tags': ['rotate_right']
    },
    {
      'name': 'rounded_corner',
      'ligature': 'rounded_corner',
      'tags': ['rounded_corner']
    },
    {
      'name': 'router',
      'ligature': 'router',
      'tags': ['router']
    },
    {
      'name': 'rowing',
      'ligature': 'rowing',
      'tags': ['rowing']
    },
    {
      'name': 'rss_feed',
      'ligature': 'rss_feed',
      'tags': ['rss_feed']
    },
    {
      'name': 'rv_hookup',
      'ligature': 'rv_hookup',
      'tags': ['rv_hookup']
    },
    {
      'name': 'satellite',
      'ligature': 'satellite',
      'tags': ['satellite']
    },
    {
      'name': 'save_alt',
      'ligature': 'save_alt',
      'tags': ['save_alt']
    },
    {
      'name': 'save',
      'ligature': 'save',
      'tags': ['save']
    },
    {
      'name': 'scanner',
      'ligature': 'scanner',
      'tags': ['scanner']
    },
    {
      'name': 'scatter_plot',
      'ligature': 'scatter_plot',
      'tags': ['scatter_plot']
    },
    {
      'name': 'schedule',
      'ligature': 'schedule',
      'tags': ['schedule']
    },
    {
      'name': 'school',
      'ligature': 'school',
      'tags': ['school']
    },
    {
      'name': 'score',
      'ligature': 'score',
      'tags': ['score']
    },
    {
      'name': 'screen_lock_landscape',
      'ligature': 'screen_lock_landscape',
      'tags': ['screen_lock_landscape']
    },
    {
      'name': 'screen_lock_portrait',
      'ligature': 'screen_lock_portrait',
      'tags': ['screen_lock_portrait']
    },
    {
      'name': 'screen_lock_rotation',
      'ligature': 'screen_lock_rotation',
      'tags': ['screen_lock_rotation']
    },
    {
      'name': 'screen_rotation',
      'ligature': 'screen_rotation',
      'tags': ['screen_rotation']
    },
    {
      'name': 'screen_share',
      'ligature': 'screen_share',
      'tags': ['screen_share']
    },
    {
      'name': 'sd_card',
      'ligature': 'sd_card',
      'tags': ['sd_card']
    },
    {
      'name': 'sd_storage',
      'ligature': 'sd_storage',
      'tags': ['sd_storage']
    },
    {
      'name': 'search',
      'ligature': 'search',
      'tags': ['search']
    },
    {
      'name': 'security',
      'ligature': 'security',
      'tags': ['security']
    },
    {
      'name': 'select_all',
      'ligature': 'select_all',
      'tags': ['select_all']
    },
    {
      'name': 'send',
      'ligature': 'send',
      'tags': ['send']
    },
    {
      'name': 'sentiment_dissatisfied',
      'ligature': 'sentiment_dissatisfied',
      'tags': ['sentiment_dissatisfied']
    },
    {
      'name': 'sentiment_satisfied_alt',
      'ligature': 'sentiment_satisfied_alt',
      'tags': ['sentiment_satisfied_alt']
    },
    {
      'name': 'sentiment_satisfied',
      'ligature': 'sentiment_satisfied',
      'tags': ['sentiment_satisfied']
    },
    {
      'name': 'sentiment_very_dissatisfied',
      'ligature': 'sentiment_very_dissatisfied',
      'tags': ['sentiment_very_dissatisfied']
    },
    {
      'name': 'sentiment_very_satisfied',
      'ligature': 'sentiment_very_satisfied',
      'tags': ['sentiment_very_satisfied']
    },
    {
      'name': 'settings_applications',
      'ligature': 'settings_applications',
      'tags': ['settings_applications']
    },
    {
      'name': 'settings_backup_restore',
      'ligature': 'settings_backup_restore',
      'tags': ['settings_backup_restore']
    },
    {
      'name': 'settings_bluetooth',
      'ligature': 'settings_bluetooth',
      'tags': ['settings_bluetooth']
    },
    {
      'name': 'settings_brightness',
      'ligature': 'settings_brightness',
      'tags': ['settings_brightness']
    },
    {
      'name': 'settings_cell',
      'ligature': 'settings_cell',
      'tags': ['settings_cell']
    },
    {
      'name': 'settings_ethernet',
      'ligature': 'settings_ethernet',
      'tags': ['settings_ethernet']
    },
    {
      'name': 'settings_input_antenna',
      'ligature': 'settings_input_antenna',
      'tags': ['settings_input_antenna']
    },
    {
      'name': 'settings_input_component',
      'ligature': 'settings_input_component',
      'tags': ['settings_input_component']
    },
    {
      'name': 'settings_input_composite',
      'ligature': 'settings_input_composite',
      'tags': ['settings_input_composite']
    },
    {
      'name': 'settings_input_hdmi',
      'ligature': 'settings_input_hdmi',
      'tags': ['settings_input_hdmi']
    },
    {
      'name': 'settings_input_svideo',
      'ligature': 'settings_input_svideo',
      'tags': ['settings_input_svideo']
    },
    {
      'name': 'settings_overscan',
      'ligature': 'settings_overscan',
      'tags': ['settings_overscan']
    },
    {
      'name': 'settings_phone',
      'ligature': 'settings_phone',
      'tags': ['settings_phone']
    },
    {
      'name': 'settings_power',
      'ligature': 'settings_power',
      'tags': ['settings_power']
    },
    {
      'name': 'settings_remote',
      'ligature': 'settings_remote',
      'tags': ['settings_remote']
    },
    {
      'name': 'settings_system_daydream',
      'ligature': 'settings_system_daydream',
      'tags': ['settings_system_daydream']
    },
    {
      'name': 'settings_voice',
      'ligature': 'settings_voice',
      'tags': ['settings_voice']
    },
    {
      'name': 'settings',
      'ligature': 'settings',
      'tags': ['settings']
    },
    {
      'name': 'share',
      'ligature': 'share',
      'tags': ['share']
    },
    {
      'name': 'shop_two',
      'ligature': 'shop_two',
      'tags': ['shop_two']
    },
    {
      'name': 'shop',
      'ligature': 'shop',
      'tags': ['shop']
    },
    {
      'name': 'shopping_basket',
      'ligature': 'shopping_basket',
      'tags': ['shopping_basket']
    },
    {
      'name': 'shopping_cart',
      'ligature': 'shopping_cart',
      'tags': ['shopping_cart']
    },
    {
      'name': 'short_text',
      'ligature': 'short_text',
      'tags': ['short_text']
    },
    {
      'name': 'show_chart',
      'ligature': 'show_chart',
      'tags': ['show_chart']
    },
    {
      'name': 'shuffle',
      'ligature': 'shuffle',
      'tags': ['shuffle']
    },
    {
      'name': 'shutter_speed',
      'ligature': 'shutter_speed',
      'tags': ['shutter_speed']
    },
    {
      'name': 'signal_cellular_0_bar',
      'ligature': 'signal_cellular_0_bar',
      'tags': ['signal_cellular_0_bar']
    },
    {
      'name': 'signal_cellular_4_bar',
      'ligature': 'signal_cellular_4_bar',
      'tags': ['signal_cellular_4_bar']
    },
    {
      'name': 'signal_cellular_alt',
      'ligature': 'signal_cellular_alt',
      'tags': ['signal_cellular_alt']
    },
    {
      'name': 'signal_cellular_connected_no_internet_4_bar',
      'ligature': 'signal_cellular_connected_no_internet_4_bar',
      'tags': ['signal_cellular_connected_no_internet_4_bar']
    },
    {
      'name': 'signal_cellular_no_sim',
      'ligature': 'signal_cellular_no_sim',
      'tags': ['signal_cellular_no_sim']
    },
    {
      'name': 'signal_cellular_null',
      'ligature': 'signal_cellular_null',
      'tags': ['signal_cellular_null']
    },
    {
      'name': 'signal_cellular_off',
      'ligature': 'signal_cellular_off',
      'tags': ['signal_cellular_off']
    },
    {
      'name': 'signal_wifi_0_bar',
      'ligature': 'signal_wifi_0_bar',
      'tags': ['signal_wifi_0_bar']
    },
    {
      'name': 'signal_wifi_4_bar_lock',
      'ligature': 'signal_wifi_4_bar_lock',
      'tags': ['signal_wifi_4_bar_lock']
    },
    {
      'name': 'signal_wifi_4_bar',
      'ligature': 'signal_wifi_4_bar',
      'tags': ['signal_wifi_4_bar']
    },
    {
      'name': 'signal_wifi_off',
      'ligature': 'signal_wifi_off',
      'tags': ['signal_wifi_off']
    },
    {
      'name': 'sim_card',
      'ligature': 'sim_card',
      'tags': ['sim_card']
    },
    {
      'name': 'skip_next',
      'ligature': 'skip_next',
      'tags': ['skip_next']
    },
    {
      'name': 'skip_previous',
      'ligature': 'skip_previous',
      'tags': ['skip_previous']
    },
    {
      'name': 'slideshow',
      'ligature': 'slideshow',
      'tags': ['slideshow']
    },
    {
      'name': 'slow_motion_video',
      'ligature': 'slow_motion_video',
      'tags': ['slow_motion_video']
    },
    {
      'name': 'smartphone',
      'ligature': 'smartphone',
      'tags': ['smartphone']
    },
    {
      'name': 'smoke_free',
      'ligature': 'smoke_free',
      'tags': ['smoke_free']
    },
    {
      'name': 'smoking_rooms',
      'ligature': 'smoking_rooms',
      'tags': ['smoking_rooms']
    },
    {
      'name': 'sms_failed',
      'ligature': 'sms_failed',
      'tags': ['sms_failed']
    },
    {
      'name': 'sms',
      'ligature': 'sms',
      'tags': ['sms']
    },
    {
      'name': 'snooze',
      'ligature': 'snooze',
      'tags': ['snooze']
    },
    {
      'name': 'sort_by_alpha',
      'ligature': 'sort_by_alpha',
      'tags': ['sort_by_alpha']
    },
    {
      'name': 'sort',
      'ligature': 'sort',
      'tags': ['sort']
    },
    {
      'name': 'spa',
      'ligature': 'spa',
      'tags': ['spa']
    },
    {
      'name': 'space_bar',
      'ligature': 'space_bar',
      'tags': ['space_bar']
    },
    {
      'name': 'speaker_group',
      'ligature': 'speaker_group',
      'tags': ['speaker_group']
    },
    {
      'name': 'speaker_notes_off',
      'ligature': 'speaker_notes_off',
      'tags': ['speaker_notes_off']
    },
    {
      'name': 'speaker_notes',
      'ligature': 'speaker_notes',
      'tags': ['speaker_notes']
    },
    {
      'name': 'speaker_phone',
      'ligature': 'speaker_phone',
      'tags': ['speaker_phone']
    },
    {
      'name': 'speaker',
      'ligature': 'speaker',
      'tags': ['speaker']
    },
    {
      'name': 'spellcheck',
      'ligature': 'spellcheck',
      'tags': ['spellcheck']
    },
    {
      'name': 'star_border',
      'ligature': 'star_border',
      'tags': ['star_border']
    },
    {
      'name': 'star_half',
      'ligature': 'star_half',
      'tags': ['star_half']
    },
    {
      'name': 'star_rate',
      'ligature': 'star_rate',
      'tags': ['star_rate']
    },
    {
      'name': 'star',
      'ligature': 'star',
      'tags': ['star']
    },
    {
      'name': 'stars',
      'ligature': 'stars',
      'tags': ['stars']
    },
    {
      'name': 'stay_current_landscape',
      'ligature': 'stay_current_landscape',
      'tags': ['stay_current_landscape']
    },
    {
      'name': 'stay_current_portrait',
      'ligature': 'stay_current_portrait',
      'tags': ['stay_current_portrait']
    },
    {
      'name': 'stay_primary_landscape',
      'ligature': 'stay_primary_landscape',
      'tags': ['stay_primary_landscape']
    },
    {
      'name': 'stay_primary_portrait',
      'ligature': 'stay_primary_portrait',
      'tags': ['stay_primary_portrait']
    },
    {
      'name': 'stop_screen_share',
      'ligature': 'stop_screen_share',
      'tags': ['stop_screen_share']
    },
    {
      'name': 'stop',
      'ligature': 'stop',
      'tags': ['stop']
    },
    {
      'name': 'storage',
      'ligature': 'storage',
      'tags': ['storage']
    },
    {
      'name': 'store_mall_directory',
      'ligature': 'store_mall_directory',
      'tags': ['store_mall_directory']
    },
    {
      'name': 'store',
      'ligature': 'store',
      'tags': ['store']
    },
    {
      'name': 'straighten',
      'ligature': 'straighten',
      'tags': ['straighten']
    },
    {
      'name': 'streetview',
      'ligature': 'streetview',
      'tags': ['streetview']
    },
    {
      'name': 'strikethrough_s',
      'ligature': 'strikethrough_s',
      'tags': ['strikethrough_s']
    },
    {
      'name': 'style',
      'ligature': 'style',
      'tags': ['style']
    },
    {
      'name': 'subdirectory_arrow_left',
      'ligature': 'subdirectory_arrow_left',
      'tags': ['subdirectory_arrow_left']
    },
    {
      'name': 'subdirectory_arrow_right',
      'ligature': 'subdirectory_arrow_right',
      'tags': ['subdirectory_arrow_right']
    },
    {
      'name': 'subject',
      'ligature': 'subject',
      'tags': ['subject']
    },
    {
      'name': 'subscriptions',
      'ligature': 'subscriptions',
      'tags': ['subscriptions']
    },
    {
      'name': 'subtitles',
      'ligature': 'subtitles',
      'tags': ['subtitles']
    },
    {
      'name': 'subway',
      'ligature': 'subway',
      'tags': ['subway']
    },
    {
      'name': 'supervised_user_circle',
      'ligature': 'supervised_user_circle',
      'tags': ['supervised_user_circle']
    },
    {
      'name': 'supervisor_account',
      'ligature': 'supervisor_account',
      'tags': ['supervisor_account']
    },
    {
      'name': 'surround_sound',
      'ligature': 'surround_sound',
      'tags': ['surround_sound']
    },
    {
      'name': 'swap_calls',
      'ligature': 'swap_calls',
      'tags': ['swap_calls']
    },
    {
      'name': 'swap_horiz',
      'ligature': 'swap_horiz',
      'tags': ['swap_horiz']
    },
    {
      'name': 'swap_horizontal_circle',
      'ligature': 'swap_horizontal_circle',
      'tags': ['swap_horizontal_circle']
    },
    {
      'name': 'swap_vert',
      'ligature': 'swap_vert',
      'tags': ['swap_vert']
    },
    {
      'name': 'swap_vertical_circle',
      'ligature': 'swap_vertical_circle',
      'tags': ['swap_vertical_circle']
    },
    {
      'name': 'switch_camera',
      'ligature': 'switch_camera',
      'tags': ['switch_camera']
    },
    {
      'name': 'switch_video',
      'ligature': 'switch_video',
      'tags': ['switch_video']
    },
    {
      'name': 'sync_disabled',
      'ligature': 'sync_disabled',
      'tags': ['sync_disabled']
    },
    {
      'name': 'sync_problem',
      'ligature': 'sync_problem',
      'tags': ['sync_problem']
    },
    {
      'name': 'sync',
      'ligature': 'sync',
      'tags': ['sync']
    },
    {
      'name': 'system_update',
      'ligature': 'system_update',
      'tags': ['system_update']
    },
    {
      'name': 'tab_unselected',
      'ligature': 'tab_unselected',
      'tags': ['tab_unselected']
    },
    {
      'name': 'tab',
      'ligature': 'tab',
      'tags': ['tab']
    },
    {
      'name': 'table_chart',
      'ligature': 'table_chart',
      'tags': ['table_chart']
    },
    {
      'name': 'tablet_android',
      'ligature': 'tablet_android',
      'tags': ['tablet_android']
    },
    {
      'name': 'tablet_mac',
      'ligature': 'tablet_mac',
      'tags': ['tablet_mac']
    },
    {
      'name': 'tablet',
      'ligature': 'tablet',
      'tags': ['tablet']
    },
    {
      'name': 'tag_faces',
      'ligature': 'tag_faces',
      'tags': ['tag_faces']
    },
    {
      'name': 'tap_and_play',
      'ligature': 'tap_and_play',
      'tags': ['tap_and_play']
    },
    {
      'name': 'terrain',
      'ligature': 'terrain',
      'tags': ['terrain']
    },
    {
      'name': 'text_fields',
      'ligature': 'text_fields',
      'tags': ['text_fields']
    },
    {
      'name': 'text_format',
      'ligature': 'text_format',
      'tags': ['text_format']
    },
    {
      'name': 'text_rotate_up',
      'ligature': 'text_rotate_up',
      'tags': ['text_rotate_up']
    },
    {
      'name': 'text_rotate_vertical',
      'ligature': 'text_rotate_vertical',
      'tags': ['text_rotate_vertical']
    },
    {
      'name': 'text_rotation_down',
      'ligature': 'text_rotation_down',
      'tags': ['text_rotation_down']
    },
    {
      'name': 'text_rotation_none',
      'ligature': 'text_rotation_none',
      'tags': ['text_rotation_none']
    },
    {
      'name': 'textsms',
      'ligature': 'textsms',
      'tags': ['textsms']
    },
    {
      'name': 'texture',
      'ligature': 'texture',
      'tags': ['texture']
    },
    {
      'name': 'theaters',
      'ligature': 'theaters',
      'tags': ['theaters']
    },
    {
      'name': 'thumb_down_alt',
      'ligature': 'thumb_down_alt',
      'tags': ['thumb_down_alt']
    },
    {
      'name': 'thumb_down',
      'ligature': 'thumb_down',
      'tags': ['thumb_down']
    },
    {
      'name': 'thumb_up_alt',
      'ligature': 'thumb_up_alt',
      'tags': ['thumb_up_alt']
    },
    {
      'name': 'thumb_up',
      'ligature': 'thumb_up',
      'tags': ['thumb_up']
    },
    {
      'name': 'thumbs_up_down',
      'ligature': 'thumbs_up_down',
      'tags': ['thumbs_up_down']
    },
    {
      'name': 'time_to_leave',
      'ligature': 'time_to_leave',
      'tags': ['time_to_leave']
    },
    {
      'name': 'timelapse',
      'ligature': 'timelapse',
      'tags': ['timelapse']
    },
    {
      'name': 'timeline',
      'ligature': 'timeline',
      'tags': ['timeline']
    },
    {
      'name': 'timer_3',
      'ligature': 'timer_3',
      'tags': ['timer_3']
    },
    {
      'name': 'timer_10',
      'ligature': 'timer_10',
      'tags': ['timer_10']
    },
    {
      'name': 'timer_off',
      'ligature': 'timer_off',
      'tags': ['timer_off']
    },
    {
      'name': 'timer',
      'ligature': 'timer',
      'tags': ['timer']
    },
    {
      'name': 'title',
      'ligature': 'title',
      'tags': ['title']
    },
    {
      'name': 'toc',
      'ligature': 'toc',
      'tags': ['toc']
    },
    {
      'name': 'today',
      'ligature': 'today',
      'tags': ['today']
    },
    {
      'name': 'toggle_off',
      'ligature': 'toggle_off',
      'tags': ['toggle_off']
    },
    {
      'name': 'toggle_on',
      'ligature': 'toggle_on',
      'tags': ['toggle_on']
    },
    {
      'name': 'toll',
      'ligature': 'toll',
      'tags': ['toll']
    },
    {
      'name': 'tonality',
      'ligature': 'tonality',
      'tags': ['tonality']
    },
    {
      'name': 'touch_app',
      'ligature': 'touch_app',
      'tags': ['touch_app']
    },
    {
      'name': 'toys',
      'ligature': 'toys',
      'tags': ['toys']
    },
    {
      'name': 'track_changes',
      'ligature': 'track_changes',
      'tags': ['track_changes']
    },
    {
      'name': 'traffic',
      'ligature': 'traffic',
      'tags': ['traffic']
    },
    {
      'name': 'train',
      'ligature': 'train',
      'tags': ['train']
    },
    {
      'name': 'tram',
      'ligature': 'tram',
      'tags': ['tram']
    },
    {
      'name': 'transfer_within_a_station',
      'ligature': 'transfer_within_a_station',
      'tags': ['transfer_within_a_station']
    },
    {
      'name': 'transform',
      'ligature': 'transform',
      'tags': ['transform']
    },
    {
      'name': 'transit_enterexit',
      'ligature': 'transit_enterexit',
      'tags': ['transit_enterexit']
    },
    {
      'name': 'translate',
      'ligature': 'translate',
      'tags': ['translate']
    },
    {
      'name': 'trending_down',
      'ligature': 'trending_down',
      'tags': ['trending_down']
    },
    {
      'name': 'trending_flat',
      'ligature': 'trending_flat',
      'tags': ['trending_flat']
    },
    {
      'name': 'trending_up',
      'ligature': 'trending_up',
      'tags': ['trending_up']
    },
    {
      'name': 'trip_origin',
      'ligature': 'trip_origin',
      'tags': ['trip_origin']
    },
    {
      'name': 'tune',
      'ligature': 'tune',
      'tags': ['tune']
    },
    {
      'name': 'turned_in_not',
      'ligature': 'turned_in_not',
      'tags': ['turned_in_not']
    },
    {
      'name': 'turned_in',
      'ligature': 'turned_in',
      'tags': ['turned_in']
    },
    {
      'name': 'tv_off',
      'ligature': 'tv_off',
      'tags': ['tv_off']
    },
    {
      'name': 'tv',
      'ligature': 'tv',
      'tags': ['tv']
    },
    {
      'name': 'unarchive',
      'ligature': 'unarchive',
      'tags': ['unarchive']
    },
    {
      'name': 'undo',
      'ligature': 'undo',
      'tags': ['undo']
    },
    {
      'name': 'unfold_less',
      'ligature': 'unfold_less',
      'tags': ['unfold_less']
    },
    {
      'name': 'unfold_more',
      'ligature': 'unfold_more',
      'tags': ['unfold_more']
    },
    {
      'name': 'unsubscribe',
      'ligature': 'unsubscribe',
      'tags': ['unsubscribe']
    },
    {
      'name': 'update',
      'ligature': 'update',
      'tags': ['update']
    },
    {
      'name': 'usb',
      'ligature': 'usb',
      'tags': ['usb']
    },
    {
      'name': 'verified_user',
      'ligature': 'verified_user',
      'tags': ['verified_user']
    },
    {
      'name': 'vertical_align_bottom',
      'ligature': 'vertical_align_bottom',
      'tags': ['vertical_align_bottom']
    },
    {
      'name': 'vertical_align_center',
      'ligature': 'vertical_align_center',
      'tags': ['vertical_align_center']
    },
    {
      'name': 'vertical_align_top',
      'ligature': 'vertical_align_top',
      'tags': ['vertical_align_top']
    },
    {
      'name': 'vertical_split',
      'ligature': 'vertical_split',
      'tags': ['vertical_split']
    },
    {
      'name': 'vibration',
      'ligature': 'vibration',
      'tags': ['vibration']
    },
    {
      'name': 'video_call',
      'ligature': 'video_call',
      'tags': ['video_call']
    },
    {
      'name': 'video_label',
      'ligature': 'video_label',
      'tags': ['video_label']
    },
    {
      'name': 'video_library',
      'ligature': 'video_library',
      'tags': ['video_library']
    },
    {
      'name': 'videocam_off',
      'ligature': 'videocam_off',
      'tags': ['videocam_off']
    },
    {
      'name': 'videocam',
      'ligature': 'videocam',
      'tags': ['videocam']
    },
    {
      'name': 'videogame_asset',
      'ligature': 'videogame_asset',
      'tags': ['videogame_asset']
    },
    {
      'name': 'view_agenda',
      'ligature': 'view_agenda',
      'tags': ['view_agenda']
    },
    {
      'name': 'view_array',
      'ligature': 'view_array',
      'tags': ['view_array']
    },
    {
      'name': 'view_carousel',
      'ligature': 'view_carousel',
      'tags': ['view_carousel']
    },
    {
      'name': 'view_column',
      'ligature': 'view_column',
      'tags': ['view_column']
    },
    {
      'name': 'view_comfy',
      'ligature': 'view_comfy',
      'tags': ['view_comfy']
    },
    {
      'name': 'view_compact',
      'ligature': 'view_compact',
      'tags': ['view_compact']
    },
    {
      'name': 'view_day',
      'ligature': 'view_day',
      'tags': ['view_day']
    },
    {
      'name': 'view_headline',
      'ligature': 'view_headline',
      'tags': ['view_headline']
    },
    {
      'name': 'view_list',
      'ligature': 'view_list',
      'tags': ['view_list']
    },
    {
      'name': 'view_module',
      'ligature': 'view_module',
      'tags': ['view_module']
    },
    {
      'name': 'view_quilt',
      'ligature': 'view_quilt',
      'tags': ['view_quilt']
    },
    {
      'name': 'view_stream',
      'ligature': 'view_stream',
      'tags': ['view_stream']
    },
    {
      'name': 'view_week',
      'ligature': 'view_week',
      'tags': ['view_week']
    },
    {
      'name': 'vignette',
      'ligature': 'vignette',
      'tags': ['vignette']
    },
    {
      'name': 'visibility_off',
      'ligature': 'visibility_off',
      'tags': ['visibility_off']
    },
    {
      'name': 'visibility',
      'ligature': 'visibility',
      'tags': ['visibility']
    },
    {
      'name': 'voice_chat',
      'ligature': 'voice_chat',
      'tags': ['voice_chat']
    },
    {
      'name': 'voice_over_off',
      'ligature': 'voice_over_off',
      'tags': ['voice_over_off']
    },
    {
      'name': 'voicemail',
      'ligature': 'voicemail',
      'tags': ['voicemail']
    },
    {
      'name': 'volume_down',
      'ligature': 'volume_down',
      'tags': ['volume_down']
    },
    {
      'name': 'volume_mute',
      'ligature': 'volume_mute',
      'tags': ['volume_mute']
    },
    {
      'name': 'volume_off',
      'ligature': 'volume_off',
      'tags': ['volume_off']
    },
    {
      'name': 'volume_up',
      'ligature': 'volume_up',
      'tags': ['volume_up']
    },
    {
      'name': 'vpn_key',
      'ligature': 'vpn_key',
      'tags': ['vpn_key']
    },
    {
      'name': 'vpn_lock',
      'ligature': 'vpn_lock',
      'tags': ['vpn_lock']
    },
    {
      'name': 'wallpaper',
      'ligature': 'wallpaper',
      'tags': ['wallpaper']
    },
    {
      'name': 'warning',
      'ligature': 'warning',
      'tags': ['warning']
    },
    {
      'name': 'watch_later',
      'ligature': 'watch_later',
      'tags': ['watch_later']
    },
    {
      'name': 'watch',
      'ligature': 'watch',
      'tags': ['watch']
    },
    {
      'name': 'waves',
      'ligature': 'waves',
      'tags': ['waves']
    },
    {
      'name': 'wb_auto',
      'ligature': 'wb_auto',
      'tags': ['wb_auto']
    },
    {
      'name': 'wb_cloudy',
      'ligature': 'wb_cloudy',
      'tags': ['wb_cloudy']
    },
    {
      'name': 'wb_incandescent',
      'ligature': 'wb_incandescent',
      'tags': ['wb_incandescent']
    },
    {
      'name': 'wb_iridescent',
      'ligature': 'wb_iridescent',
      'tags': ['wb_iridescent']
    },
    {
      'name': 'wb_sunny',
      'ligature': 'wb_sunny',
      'tags': ['wb_sunny']
    },
    {
      'name': 'wc',
      'ligature': 'wc',
      'tags': ['wc']
    },
    {
      'name': 'web_asset',
      'ligature': 'web_asset',
      'tags': ['web_asset']
    },
    {
      'name': 'web',
      'ligature': 'web',
      'tags': ['web']
    },
    {
      'name': 'weekend',
      'ligature': 'weekend',
      'tags': ['weekend']
    },
    {
      'name': 'whatshot',
      'ligature': 'whatshot',
      'tags': ['whatshot']
    },
    {
      'name': 'where_to_vote',
      'ligature': 'where_to_vote',
      'tags': ['where_to_vote']
    },
    {
      'name': 'widgets',
      'ligature': 'widgets',
      'tags': ['widgets']
    },
    {
      'name': 'wifi_lock',
      'ligature': 'wifi_lock',
      'tags': ['wifi_lock']
    },
    {
      'name': 'wifi_off',
      'ligature': 'wifi_off',
      'tags': ['wifi_off']
    },
    {
      'name': 'wifi_tethering',
      'ligature': 'wifi_tethering',
      'tags': ['wifi_tethering']
    },
    {
      'name': 'wifi',
      'ligature': 'wifi',
      'tags': ['wifi']
    },
    {
      'name': 'work_off',
      'ligature': 'work_off',
      'tags': ['work_off']
    },
    {
      'name': 'work_outline',
      'ligature': 'work_outline',
      'tags': ['work_outline']
    },
    {
      'name': 'work',
      'ligature': 'work',
      'tags': ['work']
    },
    {
      'name': 'wrap_text',
      'ligature': 'wrap_text',
      'tags': ['wrap_text']
    },
    {
      'name': 'youtube_searched_for',
      'ligature': 'youtube_searched_for',
      'tags': ['youtube_searched_for']
    },
    {
      'name': 'zoom_in',
      'ligature': 'zoom_in',
      'tags': ['zoom_in']
    },
    {
      'name': 'zoom_out_map',
      'ligature': 'zoom_out_map',
      'tags': ['zoom_out_map']
    },
    {
      'name': 'zoom_out',
      'ligature': 'zoom_out',
      'tags': ['zoom_out']
    }
  ];
}
