import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {BugReportService} from '../../../shared/services/bug-report.service';
import {ErrorService} from '../../../shared/services/error.service';

@Component({
  selector: 'create-bug-reports',
  templateUrl: './create-bug-reports.component.html',
  styleUrls: ['./create-bug-reports.component.scss']
})
export class CreateBugReportsComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  url = 'url';

  constructor(
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private bugReportService: BugReportService,
    private dialogRef: MatDialogRef<CreateBugReportsComponent>,
    private error: ErrorService,
  ) {
    super(snackBar);
  }

  // form, cim, leirás, sikeres elküldés snackbar
  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', [Validators.required, Validators.maxLength(250)]],
    });
  }

  openSnack(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  sendReport() {
    const form = this.form.value;
    this.bugReportService.sendBugReport(form.title, form.description, this.url).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(_ => {
        this.openSnack('Report successfully sent!', null);
        this.dialogRef.close();
      }, error => {
        this.error.onError(error);
      });
  }
}
