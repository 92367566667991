import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {AuthState} from '../../xstore/states/auth.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {InterfaceState} from '../../xstore/states/interface.state';
import {OrdersState} from '../../xstore/states/orders.state';
import {LocationsState} from '../../xstore/states/locations.state';
import {CompaniesState} from '../../xstore/states/companies.state';
import {FinancialsState} from '../../xstore/states/financials.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      AuthState,
      InterfaceState,
      OrdersState,
      LocationsState,
      CompaniesState,
      FinancialsState
    ]),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
  ]
})
export class NgxsWrapperModule {
}
