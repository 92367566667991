<div class="notification">
  <div>
    <ngx-avatar size="48" [name]="notification?.title"></ngx-avatar>
  </div>

  <div>
    <p>{{notification?.title}}</p>
    <p>{{notification?.message}}</p>
    <p>{{moment(notification?.created_at).fromNow()}}</p>
  </div>

  <div>
    <button class="clear-notification-button" (click)="deleteNotification()" mat-icon-button aria-label="delete">
      <mat-icon [inline]="true" style="">clear</mat-icon>
    </button>
  </div>
</div>