<mat-toolbar class="m-0 " style="
  border-bottom: .0625rem solid #e0e0e0 !important;
  background: white !important;
  padding: 0.5rem 1rem;">
  <button matTooltip="Close" (click)="dialogRef.close()" aria-label="Close dialog" mat-icon-button
          style="margin-right: 2em; color: #5f6368;">
    <mat-icon>close</mat-icon>
  </button>

  <div style="margin-top: 2px;">
    <span class="mercuri-toolbar-title">Mercurius</span>
    <span class="mercuri-toolbar-subtitle">Forwarder</span>

    <span style="margin-left: 1em;">Document Viewer</span>
  </div>

  <span class="spacer"></span>

  <button matTooltip="Download the document" mat-icon-button (click)="download()">
    <mat-icon>get_app</mat-icon>
  </button>

  <button matTooltip="Send the document to someone" mat-icon-button [disabled]="true" (click)="send(1,2)">
    <mat-icon>send</mat-icon>
  </button>
</mat-toolbar>


<iframe *ngIf="shouldShowFile" style="height: calc(100% - 68px) !important;width: 100%;border-radius: 32px;" [src]="fileUrl"></iframe>