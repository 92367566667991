import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'mercuri_base_functions',
    title: 'Base functions',
    type: 'group',
    icon: 'apps',
    roles: ['User'],
    translate: 'NAV.BASE',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        translate: 'NAV.DASHBOARD',
        url: 'dashboard',
        roles: ['User']
      },
      {
        id: 'calendar',
        title: 'Calendar',
        type: 'item',
        icon: 'calendar_today',
        translate: 'NAV.CALENDAR',
        url: 'calendar',
        roles: ['User']
      },
      {
        id: 'locations',
        title: 'Locations',
        type: 'item',
        translate: 'NAV.LOCATIONS',
        icon: 'today',
        url: 'locations',
        roles: ['User']
      },
      {
        id: 'companies',
        title: 'Companies',
        type: 'item',
        translate: 'NAV.COMPANIES',
        icon: 'business',
        url: 'companies',
        roles: ['User']
      },
      {
        id: 'orders',
        title: 'Orders',
        type: 'item',
        icon: 'store',
        url: 'orders',
        translate: 'NAV.ORDERS',
        roles: ['User']
      },
      {
        id: 'documents',
        title: 'Documents',
        type: 'collapsable',
        translate: 'NAV.DOCUMENTS.TITLE',
        icon: 'folder',
        roles: ['User'],
        children: [
          {
            id: 'documents_view',
            title: 'View documents',
            type: 'item',
            translate: 'NAV.DOCUMENTS.VIEW',
            icon: 'folder',
            url: 'documents',
            roles: ['User'],
          },
          {
            id: 'upload_documents',
            title: 'Upload documents',
            type: 'item',
            translate: 'NAV.DOCUMENTS.UPLOAD',
            icon: 'folder',
            url: 'documents/upload-file',
            roles: ['User'],
          }
        ]
      },
      {
        id: 'myCompany',
        title: 'My Company',
        type: 'collapsable',
        translate: 'NAV.MY_COMPANY.TITLE',
        icon: 'business',
        roles: ['Chief Executive Officer', 'Developer'],
        children: [
          {
            id: 'balance',
            title: 'Financials',
            type: 'item',
            translate: 'NAV.MY_COMPANY.FINANCIALS',
            url: 'my-company/financials',
            icon: 'business',
            roles: ['Chief Executive Officer', 'Developer'],
          },
          {
            id: 'bank',
            title: 'Bank Details',
            type: 'item',
            translate: 'NAV.MY_COMPANY.BANK_DETAILS',
            url: 'my-company/bank-details',
            icon: 'business',
            roles: ['Chief Executive Officer', 'Developer'],
          }
        ]
      },
      {
        id: 'invoicing',
        title: 'Invoicing',
        type: 'item',
        translate: 'NAV.INVOICING',
        icon: 'send',
        url: 'invoicing',
        roles: ['Chief Executive Officer', 'Developer'],
      },
      {
        id: 'statements',
        title: 'Statements',
        type: 'item',
        translate: 'NAV.STATEMENTS',
        icon: 'assignment',
        url: 'statements',
        roles: ['User'],
      },
    ],
  },
  {
    id: 'mercuri_system_functions',
    title: 'System functions',
    type: 'group',
    icon: 'apps',
    translate: 'NAV.SYSTEM',
    roles: ['Developer'],
    children: [
      {
        id: 'settings',
        title: 'Settings',
        type: 'item',
        icon: 'settings',
        url: 'settings',
        translate: 'NAV.SETTINGS',
        roles: ['Developer']
      },
      {
        id: 'users',
        title: 'Users',
        type: 'collapsable',
        icon: 'supervised_user_circle',
        roles: ['Developer'],
        translate: 'NAV.USERS',
        children: [
          {
            id: 'view_users',
            title: 'Users',
            type: 'item',
            translate: 'NAV.USERS',
            url: 'users',
            icon: 'supervised_user_circle',
            roles: ['Developer']
          },
          {
            id: 'user_groups',
            title: 'Groups',
            translate: 'NAV.GROUPS',
            type: 'item',
            url: 'users/groups',
            icon: 'supervised_user_circle',
            roles: ['Developer']
          },
        ],
      },
      {
        id: 'mail-templates',
        title: 'E-Mail Templates',
        type: 'item',
        icon: 'mail',
        translate: 'NAV.EMAIL_TEMPLATES',
        url: 'mail-templates',
        roles: ['Developer']
      },
    ],
  },
];
