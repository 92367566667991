import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SharePdfSendDialogComponent} from './share-pdf-send-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SharePdfSendDialogService {
  dialogRef: MatDialogRef<SharePdfSendDialogComponent>;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  public open(docType, docId) {
    this.dialogRef = this.dialog.open(SharePdfSendDialogComponent, {
      data: {doc_type: docType, doc_id: docId},
      panelClass: 'fullscreen-dialog',
      width: '80%',
    });
  }

}
