import {InMemoryDbService} from 'angular-in-memory-web-api';
import {ProjectDashboardDb} from 'app/fake-db/dashboard-project';
import {AnalyticsDashboardDb} from 'app/fake-db/dashboard-analytics';
import {CalendarFakeDb} from 'app/fake-db/calendar';
import {ECommerceFakeDb} from 'app/fake-db/e-commerce';
import {ChatFakeDb} from 'app/fake-db/chat';
import {ContactsFakeDb} from 'app/fake-db/contacts';
import {ScrumboardFakeDb} from 'app/fake-db/scrumboard';
import {InvoiceFakeDb} from 'app/fake-db/invoice';
import {SearchFakeDb} from 'app/fake-db/search';
import {FaqFakeDb} from 'app/fake-db/faq';
import {KnowledgeBaseFakeDb} from 'app/fake-db/knowledge-base';
import {IconsFakeDb} from 'app/fake-db/icons';
import {ChatPanelFakeDb} from 'app/fake-db/chat-panel';
import {QuickPanelFakeDb} from 'app/fake-db/quick-panel';

export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {
      // Dashboards
      'project-dashboard-projects': ProjectDashboardDb.projects,
      'project-dashboard-widgets': ProjectDashboardDb.widgets,
      'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,

      // Calendar
      calendar: CalendarFakeDb.data,

      // E-Commerce
      'e-commerce-products': ECommerceFakeDb.products,
      'e-commerce-orders': ECommerceFakeDb.orders,

      // Chat
      'chat-contacts': ChatFakeDb.contacts,
      'chat-chats': ChatFakeDb.chats,
      'chat-user': ChatFakeDb.user,

      // Contacts
      'contacts-contacts': ContactsFakeDb.contacts,
      'contacts-user': ContactsFakeDb.user,

      // Scrumboard
      'scrumboard-boards': ScrumboardFakeDb.boards,

      // Invoice
      invoice: InvoiceFakeDb.invoice,

      // Search
      search: SearchFakeDb.search,

      // FAQ
      faq: FaqFakeDb.data,

      // Knowledge base
      'knowledge-base': KnowledgeBaseFakeDb.data,

      // Icons
      icons: IconsFakeDb.icons,

      // Chat Panel
      'chat-panel-contacts': ChatPanelFakeDb.contacts,
      'chat-panel-chats': ChatPanelFakeDb.chats,
      'chat-panel-user': ChatPanelFakeDb.user,

      // Quick Panel
      'quick-panel-notes': QuickPanelFakeDb.notes,
      'quick-panel-events': QuickPanelFakeDb.events
    };
  }
}
