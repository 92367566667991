export class SetSelectedCompanyView {
  static readonly type = '[Companies] Set Selected Company View';

  constructor(public payload: number) {
  }
}

export class SetSelectedTableColumns {
  static readonly type = '[Companies] Set Selected Company Table Columns';

  constructor(public payload: string[]) {
  }
}
export class SetDefaultTableColumns {
  static readonly type = '[Companies] Set Default Company Table Columns';

  constructor() {
  }
}
