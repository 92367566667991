<mat-dialog-content fxLayout="column">
  <mat-toolbar color="primary" style="margin-bottom: 1em; border-radius: 8px">
    <span>{{title}}</span>
  </mat-toolbar>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field style="width: 100%">
      <mat-label>options</mat-label>
      <mat-select #select>
        <mat-option *ngFor="let option of options" [value]="option.value">
          {{option.viewValue ? option.viewValue : option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="center center" style="float: right">
  <button mat-raised-button
          (click)="dialogRef.close(select.selected.value)"
          [color]="color"
  style="margin-bottom: 1em">
    {{textButton}}
  </button>
</mat-dialog-actions>

