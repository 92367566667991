import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private snack: MatSnackBar,
    private translate: TranslateService,
  ) {
  }

  onError(error: HttpErrorResponse) {
    if (error?.status === 500) {
      this.snack.open(this.translate.instant('ERROR_CODES.500'), null, {duration: 3000});
      return;
    }

    this.snack.open(this.translate.instant('ERROR_CODES.' + error?.error?.errorCode), null, {duration: 3000});
  }
}
