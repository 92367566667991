import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnDestroy {
  public ngUnsubscribe = new Subject<void>();

  constructor(
    public snackBar?: MatSnackBar,
  ) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public snack(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }
}
