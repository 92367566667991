import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {PdfViewerService} from './pdf-viewer.service';
import {FileShareService} from '../../services/file-share.service';
import {SharePdfSendDialogComponent} from '../share-pdf-send-dialog/share-pdf-send-dialog.component';
import {SharePdfSendDialogService} from '../share-pdf-send-dialog/share-pdf-send-dialog.service';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../components/base/base.component';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent extends BaseComponent implements OnInit {
  pdf;
  fileUrl: any;
  downloadUrl: any = 'https://carimmo.mercurius.network/public/api/download-documents?file=' + this.data.url;
  showPdf = false;
  shouldShowFile = false;

  constructor(
    public dialogRef: MatDialogRef<PdfViewerComponent>,
    private sanitizer: DomSanitizer,
    private sharepdfService: SharePdfSendDialogService,
    private pdfViewerService: PdfViewerService,
    private fileShareService: FileShareService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { url: string, title: string, type: number }
  ) {
    super();
  }

  ngOnInit(): void {
    this.getFile();
  }

  private getFile() {
    this.pdfViewerService.getFileFromLocation(this.data.url)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(_ => {
        const blobUrl = this.createbloburl(_, _?.type);
        if (blobUrl) {
          this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl + '#toolbar=0');
          this.shouldShowFile = true;
        }
      }, error => {
        console.log(error);
      });
  }

  createbloburl(file, type) {
    const blob = new Blob([file], {
      type: type || 'application/pdf'
    });
    file = window.URL.createObjectURL(blob);
    return file;
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url + '#toolbar=0');
  }

  close() {
    this.dialogRef.close();
  }


  send(docType: number, docId: number) {
    // this.fileShareService.shareFile('', '', 0, 0, false, false);

    this.sharepdfService.open(SharePdfSendDialogComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        doc_type: docType,
        doc_id: docId,
      }
    });


    /*
    doc type és doc id megkapjuk a dokumentumból
    minden egyéb beviteli mező
    megosztás 2 féle lehetőség: lekérni contact, cégnév+contact azonosító, abból automatikusan behuzni name + email
     */
  }

  download() {
    this.pdfViewerService.downloadFile(this.data.url, this.data.title);
  }
}
