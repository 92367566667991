<div class="grid-container">
  <div class="icon-container">
    <img style="width: 450px;" src=" assets/images/img/403.png" alt="permission denied">
  </div>
  <div style="padding: 30px" class="secondary-container">
    <span class="secondary-text">You have no permission to this site!</span>
    <p style="text-align: justify;">You have no permission for this site, or function.
      For the stability of our system, we restricted a few function.
      If you feel you should be able to use this feature, please request the function.</p>
    <p>Missing rang groups: {{message}}</p>
  </div>

  <div style="width: 100%; padding-top: 15px; padding-bottom: 35px;">
    <div style="width: 50%;float:left;">
      <button mat-button class="issues-text" (click)="openDashboard()">
        Back to dashboard
      </button>
    </div>
    <div style="width: 50%;float:right; padding-left: 65px">
      <button mat-raised-button color="accent" style="background-color: #1a73e8;" class="submit-button"
              aria-label="Login"
              (click)="send()">
        Request Permission
      </button>
    </div>
  </div>
</div>