export class SetSelectedIncomingTableColumns {
  static readonly type = '[Financials] Set Selected INCOMING Financials Columns';

  constructor(public incoming: string[]) {
  }
}

export class SetSelectedOutgoingTableColumns {
  static readonly type = '[Financials] Set Selected OUTGOING Financials Columns';

  constructor(public outgoing: string[]) {
  }
}

// ------------------

export class SetDefaultIncomingTableColumns {
  static readonly type = '[Financials] Set Default INCOMING Financials Columns';
}

export class SetDefaultOutgoingTableColumns {
  static readonly type = '[Financials] Set Default OUTGOING Financials Columns';
}
