<mat-toolbar class="google-importent-header">
  <div fxFlex fxFill fxLayoutGap="1em+6" fxLayout="row" fxLayoutAlign="flex-start center">
    <div fxFlex="1 0 fit-content" fxLayout="row">
      <button mat-icon-button class="navbar-toggle-button"
              (click)="toggleSidebarOpen('navbar')">
        <mat-icon class="secondary-text-bygl">menu</mat-icon>
      </button>

      <div fxLayout="row" fxLayoutAlign="flex-start center">
        <div fxHide.lt-sm class="logo ml-16" style="margin-top: 2px;">
          <span [routerLink]="'/dashboard'" style="cursor: pointer;" class="logo-text">Mercurius</span>
          <span [routerLink]="'/dashboard'" style="cursor: pointer;" class="secondary-mercuri-text">Forwarder</span>
        </div>
      </div>
    </div>

    <div fxFlex="1 1 auto" fxLayoutAlign="center center">
      <app-search-bar fxHide.lt-md></app-search-bar>
    </div>

    <div fxLayoutGap="5px" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="flex-start center">
      <app-mobile-search-bar fxHide.gt-sm></app-mobile-search-bar>
      <button mat-icon-button
              class="quick-panel-toggle-button" matTooltip="Create"
              [matMenuTriggerFor]="createMenu"
              aria-label="Toggle quick panel">
        <mat-icon class="secondary-text-bygl">add</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Mercurius SaaS Apps"
              class="quick-panel-toggle-button"
              [matMenuTriggerFor]="appsMenu"
              aria-label="Toggle quick panel">
        <mat-icon class="secondary-text-bygl">apps</mat-icon>
      </button>
      <button [matMenuTriggerFor]="notificationsMenu" mat-icon-button
              class="quick-panel-toggle-button" matTooltip="Notification Center"

              aria-label="Toggle quick panel">
        <mat-icon class="secondary-text-bygl">notifications</mat-icon>
      </button>

      <button mat-icon-button class="navbar-toggle-button"
              *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text-bygl">menu</mat-icon>
      </button>

      <div *ngIf="user$ | async as u">
        <ngx-avatar *ngIf="u?.user?.id !== 22 && u?.user?.id !== 21" class="user-button" [matMenuTriggerFor]="userMenu"
                    [matTooltip]="u?.user?.lastname+ ' ' + u?.user?.firstname" size="32"
                    [name]="u?.user?.lastname+ ' ' + u?.user?.firstname"></ngx-avatar>


        <img [matMenuTriggerFor]="userMenu"
             [matTooltip]="u?.user?.lastname+ ' ' + u?.user?.firstname" *ngIf="u?.user?.id === 22"
             style="height: 44px;border-radius: 50%;width: 44px;cursor: pointer;" src="assets/img/Marzhan_profile.jpg" alt="marzhan">


        <img [matMenuTriggerFor]="userMenu"
             [matTooltip]="u?.user?.lastname+ ' ' + u?.user?.firstname" *ngIf="u?.user?.id === 21"
             style="height: 44px;border-radius: 50%;width: 44px;cursor: pointer;" src="assets/img/Emiliya_profile.jpg" alt="emilia">
      </div>
    </div>
  </div>

</mat-toolbar>

<mat-menu #appsMenu="matMenu" [overlapTrigger]="false" class="mercurius-apps">
  <span style="margin-left: 23px;font-size: 1.3em;color: #464646;" (click)="$event.stopPropagation()">Mercurius Apps</span>
  <div (click)="$event.stopPropagation()" class="mercurius-apps-data">

    <div (click)="openWithSessionKey('https://mercurius-mail-list-sender-application.mercurius.cloud/auth/session/')" fxLayout="column" class="app card-lite">
      <mat-icon class="icon">email</mat-icon>
      <span class="name">Mercurius Mail List Sender</span>
    </div>
    <!--    <img src="/assets/images/img/noapps.png" style="width: 100%;">-->
    <!--    <span class="text">No Applications Found</span>-->
  </div>
</mat-menu>

<mat-menu #createMenu="matMenu" [overlapTrigger]="false">
  <div (click)="$event.stopPropagation()">
    <button (click)="createLocation()" mat-menu-item>
      <mat-icon>location_on</mat-icon>
      <span>Add Location</span>
    </button>

    <button (click)="createCompany()" mat-menu-item>
      <mat-icon>business</mat-icon>
      <span>Add Company</span>
    </button>

    <button mat-menu-item (click)="createOrder()">
      <mat-icon>assignment</mat-icon>
      <span>Create an Order</span>
    </button>
  </div>
</mat-menu>


<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="ga-user-menu">
  <div (click)="$event.stopPropagation()" class="user-elements" *ngIf="user$ | async as u">
    <ngx-avatar *ngIf="u?.user?.id !== 22 && u?.user?.id !== 21" size="80" [name]="u?.user?.lastname+ ' ' + u?.user?.firstname"></ngx-avatar>
    <img matTooltip="The developers liked this" *ngIf="u?.user?.id === 22" style="height: 150px;border-radius: 50%;width: 150px;" src="assets/img/Marzhan_profile.jpg" alt="marzhan">
    <img *ngIf="u?.user?.id === 21" style="height: 150px;border-radius: 50%;width: 150px;" src="assets/img/Emiliya_profile.jpg" alt="emilia">



    <span class="username">{{u?.user?.lastname + ' ' + u?.user?.firstname}}</span>
    <span class="user-mail">{{u?.user?.email}}</span>
    <button class="settings-button" mat-stroked-button [routerLink]="['/profile']">
      <span>Profile and Settings</span>
    </button>
    <span class="activity-menu">
<!--      <button mat-menu-item [routerLink]="['profile']">-->
      <!--        <mat-icon>schedule</mat-icon>-->
      <!--        <span>My Activity</span>-->
      <!--      </button>-->
      <!--         <button mat-menu-item [routerLink]="['profile']">-->
      <!--        <mat-icon>history</mat-icon>-->
      <!--        <span>My History</span>-->
      <!--      </button>-->
      <button mat-menu-item (click)="createBugReport()">
        <mat-icon>bug_report</mat-icon>
        <span>Report Issue</span>
      </button>

      <button mat-menu-item [routerLink]="['/changelog']">
        <mat-icon>track_changes</mat-icon>
        <span>Changelog</span>
      </button>
    </span>
    <button class="logout-button" mat-stroked-button (click)="logOut()">
      <span>Logout</span>
    </button>
    <span class="footer">
      Version: Mercurius Forwarder 1.0.2021.05
    </span>
  </div>
</mat-menu>

<mat-menu #notificationsMenu="matMenu" [overlapTrigger]="false" class="notifications-menu">
  <div (click)="$event.stopPropagation()" class="flex-parent" fxLayout="column" fxLayoutAlign="space-between">
    <div class="notifications-header" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <h2 class="notifications-title">Notifications</h2>
      </div>
      <div>
        <button mat-icon-button [matMenuTriggerFor]="card19Menu" aria-label="more">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu (click)="$event.stopPropagation()" #card19Menu="matMenu">
          <button mat-menu-item (click)="deleteAllNotifications()">
            <mat-icon>delete</mat-icon>
            <span>Remove All Notifications</span>
          </button>
          <div (click)="$event.stopPropagation()">
            <button mat-menu-item (click)="toggleMute(true)" *ngIf="!isMuted">
              <mat-icon>notifications</mat-icon>
              <span>Mute Notifications</span>
            </button>

            <button mat-menu-item (click)="toggleMute(false)" *ngIf="isMuted">
              <mat-icon>notifications_off</mat-icon>
              <span>Unmute Notifications</span>
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div (click)="$event.stopPropagation()" class="px-12 notifications-holder" style="height: 100%;"
         fusePerfectScrollbar>
      <notification (delete)="handleDelete($event)" *ngFor="let n of notifications" [notification]="n"></notification>
    </div>
  </div>
</mat-menu>

<mat-menu #mercuriusApps="matMenu" [overlapTrigger]="false">
  <button mat-menu-item [routerLink]="['/pages/profile']">
    <mat-icon>account_circle</mat-icon>
    <span>My Profile</span>
  </button>

  <button (click)="logOut()" mat-menu-item>
    <mat-icon>exit_to_app</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>