import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../../shared/components/base/base.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {SearchResult, SearchResultDocument} from '../../../../../shared/models/searchResult';
import {SearchService} from '../../../../../shared/services/search.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PdfViewerService} from '../../../../../shared/dialogs/pdf-viewer/pdf-viewer.service';
import {SearchBarService} from './search-bar.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent extends BaseComponent implements OnInit {
  @ViewChild('search') input: ElementRef;
  focused: boolean;
  searchResults: SearchResult[] = [];
  inputForm: FormGroup;

  constructor(
    private searchService: SearchService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private pdf: PdfViewerService,
    public searchBarService: SearchBarService,
    private cd: ChangeDetectorRef,
  ) {
    super(snackBar);
  }

  ngOnInit() {
    this.initForm();
    this.searchBarService.searchResults
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(_ => {
        this.searchResults = _;
        this.cd.detectChanges();
      });
  }

  openDropdown() {
    this.focused = true;
  }

  closeDropdown() {
    this.focused = false;
  }

  private initForm() {
    this.inputForm = this.fb.group({
      search: [''],
    });
    this.inputForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(_ => {
        if (_.search === 'company:' || _.search === 'adress:' || _.search === 'contact:' || _.search === 'order:' || _.search === 'document:') {
          this.searchBarService.clear();
          return;
        }

        if (_.search === '') {
          this.searchBarService.clear();
          return;
        }

        if (this.inputForm.valid) {
          this.searchBarService.search(this.inputForm.controls.search.value).then(() => {
            this.cd.detectChanges();
          });
        }
      });
  }

  setSearchFilter(location: string) {
    this.inputForm.controls.search.setValue(location);
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  openDocument(document: SearchResultDocument) {
    this.pdf.open(document.name, document.file_location);
  }
}
