import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FuseNavigationComponent} from './navigation.component';
import {FuseSharedModule} from '../../shared.module';
import {FuseNavVerticalGroupComponent} from './components/group/group.component';
import {FuseNavVerticalCollapsableComponent} from './components/collapsable/collapsable.component';
import {FuseNavVerticalItemComponent} from './components/item/item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    TranslateModule.forChild(),
    FuseSharedModule,
  ],
  exports: [
    FuseNavigationComponent
  ],
  declarations: [
    FuseNavigationComponent,
    FuseNavVerticalGroupComponent,
    FuseNavVerticalItemComponent,
    FuseNavVerticalCollapsableComponent,
  ]
})
export class FuseNavigationModule {
}
