<div id="main">
  <div class="container" id="container-1">
    <!-- region NAVBAR -->
    <fuse-sidebar
      *ngIf="!fuseConfig.layout.navbar.hidden"
      [folded]="fuseConfig.layout.navbar.folded"
      class="navbar-fuse-sidebar"
      name="navbar">
      <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
    <!-- endregion NAVBAR -->

    <div class="container" id="container-2">
      <!-- region TOOLBAR -->
      <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
               [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true
               ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background
               : fuseConfig.layout.toolbar.position //noinspection InvalidExpressionResultType">
      </toolbar>
      <!-- endregion TOOLBAR -->

      <div [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}"
           class="container" fusePerfectScrollbar id="container-3">
        <div class="layout-router">
          <router-outlet *ngIf="true" style="overflow-y: auto"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
