<div class="grid-container">
  <img style="width: 450px;" src="assets/images/img/lazy.svg" alt="permission denied">

  <span class="secondary-text">You have outdated order status!</span>

  <ngx-datatable
    [columnMode]="ColumnMode.force"
    [footerHeight]="30"
    [headerHeight]="50"
    [limit]="10"
    [reorderable]="false"
    [rowHeight]="'auto'"
    [rows]="message"
    [scrollbarH]="true"
    class="material"
    style="border-radius: 8px; box-shadow: none; width: 100% !important; padding: 5px">

    <ngx-datatable-column [width]="60" [frozenLeft]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <button color="primary" mat-icon-button matTooltip="Solve" (click)="updateAddressStatus(row)">
          <mat-icon>create</mat-icon>
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [frozenLeft]="true" name="Order" prop="orderName" [width]="100"></ngx-datatable-column>

    <ngx-datatable-column name="Location Type" prop="locationType" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Address" prop="locationFull" [width]="400"></ngx-datatable-column>
  </ngx-datatable>
</div>