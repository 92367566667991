import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {InvalidSessionComponent} from './invalid-session.component';

@Injectable({
  providedIn: 'root'
})
export class InvalidSessionService {
  dialogRef: MatDialogRef<InvalidSessionComponent>;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
  ) { }

  open() {
    this.dialogRef = this.dialog.open(InvalidSessionComponent, {
      panelClass: 'no-padding-dialog',
      disableClose: true,
      width: '500px',
    });
  }
}
