import {ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FuseConfigService} from '../../../../../../@fuse/services/config.service';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../../../../shared/components/base/base.component';
import {SearchResult, SearchResultDocument} from '../../../../../shared/models/searchResult';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SearchBarService} from '../search-bar/search-bar.service';
import {PdfViewerService} from '../../../../../shared/dialogs/pdf-viewer/pdf-viewer.service';

@Component({
  selector: 'app-mobile-search-bar',
  templateUrl: './mobile-search-bar.component.html',
  styleUrls: ['./mobile-search-bar.component.scss']
})
export class MobileSearchBarComponent extends BaseComponent implements OnInit {
  @ViewChild('search') input: ElementRef;
  searchResults: SearchResult[] = [];
  inputForm: FormGroup;

  collapsed: boolean;
  fuseConfig: any;

  constructor(
    private _fuseConfigService: FuseConfigService,
    public searchBarService: SearchBarService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private pdf: PdfViewerService,
  ) {
    super();
    this.collapsed = true;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    // this._fuseConfigService.config
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe(
    //     (config) => {
    //       this.fuseConfig = config;
    //     }
    //   );

    this.initForm();
    this.searchBarService.searchResults
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(_ => {
        this.searchResults = _;
        this.cd.detectChanges();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  /**
   * Collapse
   */
  collapse(): void {
    this.collapsed = true;
  }

  /**
   * Expand
   */
  expand(): void {
    this.collapsed = false;
  }

  private initForm() {
    this.inputForm = this.fb.group({
      search: [''],
    });
    this.inputForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(_ => {
        if (_.search === 'company:' || _.search === 'adress:' || _.search === 'contact:' || _.search === 'order:' || _.search === 'document:') {
          this.searchBarService.clear();
          return;
        }

        if (_.search === '') {
          this.searchBarService.clear();
          return;
        }

        if (this.inputForm.valid) {
          this.searchBarService.search(this.inputForm.controls.search.value).then(r => {
            this.cd.detectChanges();
          });
        }
      });
  }

  setSearchFilter(location: string) {
    this.inputForm.controls.search.setValue(location);
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  openDocument(document: SearchResultDocument) {
    this.pdf.open(document.name, document.file_location);
  }

}
