import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {navigation} from 'app/navigation';
import {AuthService} from '../../../shared/services/auth.service';
import {AuthState} from '../../../xstore/states/auth.state';
import {Select, Store} from '@ngxs/store';
import {UserDataToken} from '../../../shared/models/user-data-token';
import {InterfaceState} from '../../../xstore/states/interface.state';
import {Notification} from '../../../shared/models/notification';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationService} from '../../../shared/services/notification.service';
import {PusherService} from '../../../shared/services/pusher.service';
import * as moment from 'moment';
import {ToggleMute} from '../../../xstore/actions/interface.actions';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateBugReportsComponent} from '../create-bug-reports/create-bug-reports.component';
import {ErrorService} from '../../../shared/services/error.service';
import {MessageService} from 'primeng/api';

// noinspection JSIgnoredPromiseFromCall
@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent extends BaseComponent implements OnInit, OnDestroy {
  @Select(AuthState.getUser) user$: Observable<UserDataToken>;
  @Select(InterfaceState.isNotificationMuted) isMuted$: Observable<boolean>;
  isMuted;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];

  bugReportDialogRef: MatDialogRef<CreateBugReportsComponent>;
  today = new Date();
  notifications: Notification[];

  // private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private pusherService: PusherService,
    private _fuseProgressBarService: FuseProgressBarService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private error: ErrorService,
    private messageService: MessageService,
  ) {
    super(snackBar);

    this.isMuted$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.isMuted = value;
    });
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50'
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107'
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336'
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD'
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    // this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});

    this.isMuted$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.isMuted = data;
    });
    this.loadNotifications();
    //
    // // FIXME
    // this.pusherService.notifiChanel0.bind('new', _ => {
    //   this.loadNotifications();
    // });
  }

  /**
   * On destroy
   */
  // ngOnDestroy(): void {
  //   // Unsubscribe from all subscriptions
  //   this._unsubscribeAll.next();
  //   this._unsubscribeAll.complete();
  // }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  search(value): void {
    // Do your search here...
    console.log(value);
  }

  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  loadNotifications() {
    this.notificationService.getAllNotifications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {

        this.notifications = data;
        this.cd.detectChanges();
        for (const n of data) {
          if (n) {
            this.messageService.add({severity: 'info', summary: n.title, detail: n.message});
          }
        }

        if (data?.length > 0) {
          this.snack('You have new message(s)');
          if (!this.isMuted) {
            this.playAudio();
          }
          this.cd.detectChanges();
        }

        this.showLatestNotification(data[0]);
      }, error => {

        this.error.onError(error);
        this.cd.detectChanges();
      });
  }


  playAudio() {
    const audio = new Audio();
    audio.src = 'assets/sounds/notif.mp3';
    audio.load();
    try {
      audio.play();
    } catch (e) {
    }
  }


  logOut() {
    this.authService.doServerLogout();
  }

  deleteAllNotifications() {
    this._fuseProgressBarService.show();
    this.notificationService.deleteAllNotif()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this._fuseProgressBarService.hide();
        delete this.notifications;
        this.cd.detectChanges();
      }, error => {
        this._fuseProgressBarService.hide();
        this.error.onError(error);
      });
  }

  handleDelete($event: any) {
    this.notifications = this.notifications.filter(e => e.id !== $event);
  }

  showLatestNotification(n: Notification) {
    if (n?.message === '' || n?.message === null || !n || typeof n === 'undefined') {
      return;
    }

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      const options = {
        body: n.message,
        icon: ''
      };
      const notification = new Notification(n.title, options);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      // tslint:disable-next-line:only-arrow-functions
      Notification.requestPermission().then(function(permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          const notification = new Notification('Hi there!');
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }

  moment() {
    return moment;
  }

  toggleMute(b: boolean) {
    this.store.dispatch(new ToggleMute(b));
  }

  createCompany() {
    this.router.navigate(['companies', {create: true}]);
    // this.dialog.open(CreateUpdateCompanyComponent, {
    //   panelClass: 'fullscreen-dialog',
    //   data: {
    //     action: 'create',
    //     companyData: {},
    //   }
    // });
  }

  createLocation() {
    this.router.navigate(['locations', {create: true}]);
    // this.dialog.open(CreateUpdateLocationComponent, {
    //   panelClass: 'fullscreen-dialog',
    //   data: {
    //     action: 'create',
    //     locationData: {},
    //   }
    // });
  }

  createOrder() {
    this.router.navigate(['orders', {create: true}]);
    // this.dialog.open(NewOrderComponent, {
    //   panelClass: 'fullscreen-dialog',
    // });
  }

  createBugReport() {
    this.bugReportDialogRef = this.dialog.open(CreateBugReportsComponent, {
      panelClass: 'no-padding-dialog',
      width: '500px',
    });
  }

  openWithSessionKey(url: string) {
    window.open(url + this.authService.user.sessionKey);
  }
}
