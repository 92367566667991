export const locale = {
  lang: 'en',

  data: {
    NAV: {
      BASE: 'Base functions',
      DASHBOARD: 'Dashboard',
      LOCATIONS: 'Locations',
      COMPANIES: 'Companies',
      ORDERS: 'Orders',
      DOCUMENTS: {
        TITLE: 'Documents',
        VIEW: 'View documents',
        UPLOAD: 'Upload documents'
      },
      MY_COMPANY: {
        TITLE: 'My Company',
        FINANCIALS: 'Financials',
        BANK_DETAILS: 'Bank details',
      },
      INVOICING: 'Invoicing',
      STATEMENTS: 'Statements',
      // --
      SYSTEM: 'System functions',
      SETTINGS: 'Settings',
      USERS: 'Users',
      GROUPS: 'Groups',
      EMAIL_TEMPLATES: 'E-Mail templates',

      APPLICATIONS: 'Applications',
      CALENDAR: 'Calendar',
      ECOMMERCE: 'E-Commerce',
      ACADEMY: 'Academy',
    },
    ERROR_CODES: {
      500: 'Unknown error occurred! Please contact the developers!',
      ADDRESS01: 'Not every required field is filled yet, please check the address\'s details!',
      ADDRESS02: 'This nickname is already in our system, please use another one.',
      COMPANIES01: 'Not every required field is filled yet, or you used wrong format',
      COMPANIES02: 'This nickname is already in our system, please use another one.',
      CUSTOM01: 'You have no permission for this operation',
      CUSTOM02: 'The developers turned off this function',
      CUSTOM03: 'Prior to the issuance of the damage invoice, you must have had a claim report',
      CUSTOM04: 'Such a document already exists, so you can\'t upload a new one. If you want to change these please write to the developers',
    },
    ORDERS: {
      azonosito: 'ID',
      allapot: 'Status',
      bestellung_num: 'Incoming Order',
      cegnev: 'Client',
      adoszam: 'Tax Number',
      orszag: 'Country',
      varos: 'City',
      iranyito: 'Postcode',
      address: 'Address',
      sz_cegnev: 'Carrier',
      sz_adoszam: 'Carrier\'s Tax Number',
      sz_orszag: 'Carrier\'s country',
      sz_varos: 'Carrier\'s City',
      sz_iranyito: 'Carrier\'s Postcode',
      sz_address: 'Carrier\'s Address',
      osszeg: 'Sum',
      sz_osszeg: 'Amount of Delivery',
      felrakodas: 'Loading Date',
      kipakolas: 'Unloading Date',
      rendszam: 'Licence Plate',
      p_type: 'Package Type',
      typeofgods: 'Loading Reference',
      p_sz: 'Width',
      p_h: 'Length',
      p_m: 'Height',
      grade: 'Grade',
      anmerkung: 'Carrier Note',
      megjegyzes: 'Internal Note'
    },
    LOCATIONS: {
      address: 'Address',
      alterna: 'Alternate Name',
      companyName: 'Company Name',
      longitude: 'Longitude',
      postcode: 'Postcode',
      comment: 'Comment',
      country: 'Country',
      latitude: 'Latitude',
      city: 'City',
    },
    COMPANIES: {
      address: 'Address',
      adoszam: 'Tax Number',
      aktivmegbizas: '',
      bic: 'BIC',
      cegnev: 'Company Name',
      dokomentum: 'Delivery Notes Compulsory',
      egyediazon: 'ID',
      generated_id: 'Generated ID',
      gyujtoszamla: 'Simplified Invoicing',
      iban: 'IBAN',
      inaktiv: 'Inactive',
      iranyitoszam: 'Postcode',
      limit: 'Credit Limit',
      limit_action: 'Limit Action',
      mail: 'Email',
      megjegyzes: 'Comment',
      merleg: '',
      orszag: 'Country',
      osszmegbizas: '',
      phone: 'Phone',
      site: 'Website',
      skonto: 'Discount',
      skonto_day: 'Discount Day',
      skonto_ertek: 'Discount Value',
      tiltva: '',
      tipus: 'Type',
      utca: 'Street',
      valuta: 'Currency',
      varos: 'City',
      veszteseg: ''
    },
    FINANCIALS: {
      docname: 'Document',
      time: 'Payment deadline',
      status: 'Status',
      skonto: 'Discount %',
      skonto_price: 'Discount Price',
      skonto_date: 'Latest Discount Date',
      value: 'Gross Receivable',
      total: 'Current Net Receivable',
      payment_date: 'Settlement Date',
    },
    COMMON: {
      MONTHS: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
    }
  },
};
