import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';


import {FlexLayoutModule} from '@angular/flex-layout';
import {MatAlertDialogComponent} from './mat-alert-dialog/mat-alert-dialog.component';
import {MatAsyncDialogComponent} from './mat-async-dialog/mat-async-dialog.component';
import {MatInputDialogComponent} from './mat-input-dialog/mat-input-dialog.component';
import {MatLoadingDialogComponent} from './mat-loading-dialog/mat-loading-dialog.component';
import {MatRadioDialogComponent} from './mat-radio-dialog/mat-radio-dialog.component';
import {MatTimerDialogComponent} from './mat-timer-dialog/mat-timer-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatConfirmDialogComponent} from './mat-confirm-dialog/mat-confirm-dialog.component';
import { MatSelectDialogComponent } from './mat-select-dialog/mat-select-dialog.component';
import {MatToolbarModule} from '@angular/material/toolbar';


export {MatAlertDialogComponent} from './mat-alert-dialog/mat-alert-dialog.component';
export {MatAsyncDialogComponent} from './mat-async-dialog/mat-async-dialog.component';
export {MatConfirmDialogComponent} from './mat-confirm-dialog/mat-confirm-dialog.component';
export {MatInputDialogComponent} from './mat-input-dialog/mat-input-dialog.component';
export {MatLoadingDialogComponent} from './mat-loading-dialog/mat-loading-dialog.component';
export {MatRadioDialogComponent} from './mat-radio-dialog/mat-radio-dialog.component';
export {MatSelectDialogComponent} from './mat-select-dialog/mat-select-dialog.component';
export {MatTimerDialogComponent} from './mat-timer-dialog/mat-timer-dialog.component';

export const dialogs = [
  MatAlertDialogComponent,
  MatAsyncDialogComponent,
  MatConfirmDialogComponent,
  MatInputDialogComponent,
  MatLoadingDialogComponent,
  MatRadioDialogComponent,
  MatSelectDialogComponent,
  MatTimerDialogComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatToolbarModule
    ],
  exports: [...dialogs, MatDialogModule, FlexLayoutModule],
  entryComponents: [...dialogs],
  declarations: [...dialogs]
})
export class MatDialogsModule {
}
