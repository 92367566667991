import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  SetDefaultIncomingTableColumns, SetDefaultOutgoingTableColumns, SetSelectedIncomingTableColumns,
  SetSelectedOutgoingTableColumns
} from '../actions/financials.actions';

export class FinancialsStateModel {
  selectedIncomingTableColumns: string[];
  selectedOutgoingTableColumns: string[];
}

@State<FinancialsStateModel>({
  name: 'FinancialsState',
  defaults: {
    selectedIncomingTableColumns: ['docname', 'time', 'status', 'skonto', 'skonto_price', 'skonto_date', 'value', 'total', 'payment_date'],
    selectedOutgoingTableColumns: ['docname', 'time', 'status', 'skonto', 'skonto_price', 'skonto_date', 'value', 'total', 'payment_date'],
  } as FinancialsStateModel,
})

@Injectable()
export class FinancialsState {
  @Selector()
  static getSelectedIncomingTableColumns(state: FinancialsStateModel): any {
    return state.selectedIncomingTableColumns;
  }


  @Selector()
  static getSelectedOutgoingTableColumns(state: FinancialsStateModel): any {
    return state.selectedOutgoingTableColumns;
  }


  @Action(SetSelectedIncomingTableColumns)
  setSelectedIncomingTableColumns({getState, patchState}: StateContext<FinancialsStateModel>, {incoming}: SetSelectedIncomingTableColumns) {
    const state = getState();
    return patchState({
      ...state,
      selectedIncomingTableColumns: incoming,
    });
  }


  @Action(SetSelectedOutgoingTableColumns)
  setSelectedOutgoingTableColumns({getState, patchState}: StateContext<FinancialsStateModel>, {outgoing}: SetSelectedOutgoingTableColumns) {
    const state = getState();
    return patchState({
      ...state,
      selectedOutgoingTableColumns: outgoing,
    });
  }


  @Action(SetDefaultIncomingTableColumns)
  setDefaultIncomingTableColumns({getState, patchState}: StateContext<FinancialsStateModel>) {
    const state = getState();
    return patchState({
      ...state,
      selectedIncomingTableColumns: ['docname', 'time', 'status', 'skonto', 'skonto_price', 'skonto_date', 'value', 'total', 'payment_date'],
    });
  }

  @Action(SetDefaultOutgoingTableColumns)
  setDefaultOutgoingTableColumns({getState, patchState}: StateContext<FinancialsStateModel>) {
    const state = getState();
    return patchState({
      ...state,
      selectedOutgoingTableColumns: ['docname', 'time', 'status', 'skonto', 'skonto_price', 'skonto_date', 'value', 'total', 'payment_date'],
    });
  }
}
