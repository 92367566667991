import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NoPermissionService} from './no-permission.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {ErrorService} from '../../services/error.service';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss']
})
export class NoPermissionComponent implements OnInit {
  message: any;

  constructor(
    public dialogRef: MatDialogRef<NoPermissionComponent>,
    private bencePusherService: NoPermissionService,
    @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpClient,
    private router: Router,
    private error: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.message = this.data;
  }

  send() {
    this.http.post(`${environment.apiUrl}api/bence-pusher`, this.data).subscribe(_ => {
      console.log(_);
      this.dialogRef.close();
    }, error => {
      this.error.onError(error);
      console.log(error);
    });
  }

  openDashboard() {
    this.router.navigate(['dashboard']);
    this.dialogRef.close();
  }
}
