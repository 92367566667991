import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InvalidSessionService} from './invalid-session.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-no-permission',
  templateUrl: './invalid-session.component.html',
  styleUrls: ['./invalid-session.component.scss']
})
export class InvalidSessionComponent implements OnInit {
  message: any;

  constructor(
    public dialogRef: MatDialogRef<InvalidSessionComponent>,
    private bencePusherService: InvalidSessionService,
    @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpClient,
    private router: Router,
    private auth: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.message = this.data;
  }

  logout() {
    this.auth.logout();
    this.dialogRef.close();
  }
}
