import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {LateStatusComponent} from './late-status.component';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LateStatusService {
  dialogRef: MatDialogRef<LateStatusComponent>;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
  ) {
  }

  open(data) {
    this.dialogRef = this.dialog.open(LateStatusComponent, {
      data: data,
      panelClass: 'no-padding-dialog',
      width: '800px',
    });
  }

  getStatus() {
    return this.http.get(`${environment.apiUrl}api/get-user-late-locations`);
  }

  updateStatus(obj, type: number, id: number, expiredId: number) {
    return this.http.post(`${environment.apiUrl}api/set-order-adress-status-on-expired-modal`,
      {type: type, id: id, expired_id: expiredId, status: obj.status, status_data: obj.status_data});
  }
}
