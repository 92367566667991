export class SetSelectedLocationView {
  static readonly type = '[Locations] Set Selected Location View';

  constructor(public payload: number) {
  }
}

export class SetSelectedLocationTableColumns {
  static readonly type = '[Locations] Set Selected Location Table Columns';

  constructor(public payload: string[]) {
  }
}

export class SetDefaultLocationTableColumns {
  static readonly type = '[Interface] Set Selected Location Table Columns';

  constructor() {
  }
}
