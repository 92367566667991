<div fusePerfectScrollbar style="background: url('assets/images/backgrounds/form-background-1.jpg'); background-size: cover;height: 100%;">
  <mat-toolbar class="m-0 " style="

  border-bottom: .0625rem solid #e0e0e0 !important;
  background: white !important;
  padding: 0.5rem 1rem;">
    <button (click)="matDialogRef.close()" aria-label="Close dialog" mat-icon-button
            style="margin-right: 2em; color: #5f6368;">
      <mat-icon>close</mat-icon>
    </button>

    <div style="margin-top: 2px;">
      <span class="mercuri-toolbar-title">Mercurius</span>
      <span class="mercuri-toolbar-subtitle">Forwarder</span>

      <span style="margin-left: 1em;">Share a Document</span>
    </div>

    <span class="spacer"></span>

    <button (click)="sendFile()"
            [disabled]="shareFileForm.invalid"
            aria-label="Add"
            class="save-button"
            color="primary"
            mat-raised-button>
      SEND
    </button>

  </mat-toolbar>


  <div class="auto-padding">
    <form class="p-0-1em" [formGroup]="shareFileForm" fxFlex fxFill fxLayout="column" name="locationForm">
<!--      <div [@animate]="{value:'*',params:{delay:'200ms',x:'-100px'}}" class="p-16 p-sm-24" fxLayout="column"-->
<!--           fxLayoutAlign="start"-->
<!--           style="-->
<!--          max-height: 185px !important;-->
<!--          height: 185px !important;-->
<!--          border-radius: 8px;-->
<!--          margin-top: 15px;-->
<!--          margin-bottom: 15px;-->
<!--          background-color: white;-->
<!--          color: rgba(0, 0, 0, .8);"-->
<!--           fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start flex-start">-->


<!--         APP TITLE-->
<!--        <div fxLayout="column" style="width: 100%;height:100%;">-->


<!--          <div class="logo" fxFlex="50" fxLayoutAlign="flex-start">-->
<!--            <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">-->
<!--              assignment-->
<!--            </mat-icon>-->
<!--            <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">-->
<!--            Share a file-->
<!--          </span>-->
<!--          </div>-->
<!--          <span class="logo-text h4" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">-->
<!--            Kérlek minden mezőt tölts ki figyelmesen, azok a mezők amelyek piros csillaggal vannak jelölve köteleőzen kitöltendőek. Az itt kitöltött módosítások bezásárás esetén nem kerülnek mentésre!-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
      <div style="margin-bottom: 2em;" class="card-lite-forms" fxFlex fxLayout="column">
        <span class="create-edit-form-title">Reciever details</span>
        <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
          <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" name="title" >
            <mat-error *ngIf="shareFileForm.controls.name.invalid">{{getErrorMessage(shareFileForm.controls.name)}}</mat-error>
<!--            <mat-autocomplete #auto="matAutocomplete">-->
<!--              <mat-option *ngFor="let option of filteredContacts | async" [value]="option">-->
<!--                {{option?.osszes}}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100-p">
            <mat-label>Email</mat-label>
            <input formControlName="email"
                   matInput
                   name="title"
                   required>
            <mat-error *ngIf="shareFileForm.controls.email.invalid">{{getErrorMessage(shareFileForm.controls.email)}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div style="margin-bottom: 2em;" class="card-lite-forms" fxFlex fxLayout="column">
        <span class="create-edit-form-title">Email details</span>
        <div style="padding: 1em" fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
            <mat-checkbox color="accent" formControlName="temp" style="width: 400px;text-align: center;">
              <span style="max-width: 200px;word-break: break-all;width: 200px">Temporary</span>
            </mat-checkbox>

            <mat-checkbox color="accent" formControlName="show_creator" style="width: 400px;text-align: center;">
              <span style="max-width: 200px;word-break: break-all;width: 200px">Show creator</span>
            </mat-checkbox>
        </div>
      </div>
    </form>
  </div>
</div>