<ng-container *ngxPermissionsOnly="item.roles">

  <!-- normal collapsable -->
  <a (click)="toggleOpen($event)" *ngIf="!item.url && !item.function" [ngClass]="item.classes"
     class="nav-link">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.url -->
  <a (click)="toggleOpen($event)" *ngIf="item.url && !item.externalUrl && !item.function" [ngClass]="item.classes"
     [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
     [routerLinkActive]="['active', 'accent']" [routerLink]="[item.url]"
     [target]="item.openInNewTab ? '_blank' : '_self'"
     class="nav-link">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a (click)="toggleOpen($event)" *ngIf="item.url && item.externalUrl && !item.function" [href]="item.url"
     [ngClass]="item.classes"
     [target]="item.openInNewTab ? '_blank' : '_self'" class="nav-link">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.function -->
  <span (click)="toggleOpen($event);item.function()" *ngIf="!item.url && item.function" [ngClass]="item.classes"
        class="nav-link">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

  <!-- item.url && item.function -->
  <a (click)="toggleOpen($event);item.function()" *ngIf="item.url && !item.externalUrl && item.function" [ngClass]="item.classes"
     [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
     [routerLinkActive]="['active', 'accent']" [routerLink]="[item.url]"
     class="nav-link">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl && item.function -->
  <a (click)="toggleOpen($event);item.function()" *ngIf="item.url && item.externalUrl && item.function" [href]="item.url"
     [ngClass]="item.classes"
     [target]="item.openInNewTab ? '_blank' : '_self'" class="nav-link">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <mat-icon *ngIf="item.icon" class="nav-link-icon">{{item.icon}}</mat-icon>
    <span [translate]="item.translate" class="nav-link-title"></span>
    <span *ngIf="item.badge" [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}" [translate]="item.badge.translate"
          class="nav-link-badge">
            {{item.badge.title}}
        </span>
    <mat-icon class="collapsable-arrow">keyboard_arrow_right</mat-icon>
  </ng-template>

  <div [@slideInOut]="isOpen" class="children">
    <ng-container *ngFor="let item of item.children">
      <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>
      <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
                                     [item]="item"></fuse-nav-vertical-collapsable>
      <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
    </ng-container>
  </div>

</ng-container>
