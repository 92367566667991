import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SearchResult} from '../../../../../shared/models/searchResult';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchBarService {
  searchResults: BehaviorSubject<SearchResult | any>;
  showResults = false;

  constructor(
    private http: HttpClient,
  ) {
    this.searchResults = new BehaviorSubject({});
  }

  search(input) {
    if (typeof input !== 'string') {
      input = input.toString();
    }

    return new Promise((resolve, reject) => {
      this.http.post<SearchResult[]>(`${environment.apiUrl}api/search`, {input: input})
        .subscribe((response: any) => {
          this.searchResults.next(response);
          this.showResults = true;
          resolve(response);
        }, reject);
    });
  }

  clear() {
    this.searchResults.next({});
    this.showResults = false;
  }
}
