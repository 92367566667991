<mat-dialog-content fxLayout="column">
  <div class="title">
    {{title}}
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="example-full-width" appearance="outline">
      <input aria-label="input" matInput #input [placeholder]="placeholder">
      <mat-icon *ngIf="icon" matSuffix [color]="color">{{icon}}</mat-icon>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="flex-end center">
  <button mat-raised-button [disabled]="!input.value"
          (click)="dialogRef.close(input.value)"
          [color]="color">
    {{textButton}}
  </button>
</mat-dialog-actions>
