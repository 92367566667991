import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {FuseConfigService} from '@fuse/services/config.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PusherService} from '../../shared/services/pusher.service';
import {AuthService} from '../../shared/services/auth.service';
import {NgxPermissionsService} from 'ngx-permissions';
import * as _ from 'underscore';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InvalidSessionService} from '../../shared/dialogs/invalid-session/invalid-session.service';
import {MaintenanceService} from '../../shared/services/maintenance.service';
import {MaintenanceComponent} from '../../shared/dialogs/maintenance/maintenance.component';
import {LateStatusService} from '../../shared/dialogs/late-status/late-status.service';
import {ErrorService} from '../../shared/services/error.service';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent extends BaseComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  notificationChannel: any;
  permissionChannel: void;
  sessionChannel: void;
  maintenanceDialogRef: MatDialogRef<MaintenanceComponent>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    public snackBar: MatSnackBar,
    public pusher: PusherService,
    private auth: AuthService,
    private permissionsService: NgxPermissionsService,
    private http: HttpClient,
    private dialog: MatDialog,
    private sessionDialog: InvalidSessionService,
    private maintenanceService: MaintenanceService,
    private lateStateService: LateStatusService,
    private error: ErrorService,
  ) {
    super(snackBar);
  }

  ngOnInit(): void {
    if (!this.auth.user.sessionKey || this.auth.user.sessionKey === '') {
      this.auth.logout();
    }

    // this._fuseConfigService.setToolbarAbove();

    this._fuseConfigService.config
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    this.authorizeSession()
      .then(() => {
      })
      .catch(() => this.sessionDialog.open());


    this.pusher.maintenanceChannel.bind('status', () => {
      this.isMaintenance();
    });


    this.getStates();
    this.subscribePusherChannels();
    this.setRoles();
    this.isMaintenance();
  }

  private subscribePusherChannels() {
    this.notificationChannel = this.pusher.subscribeNotificationChannel();
    this.permissionChannel = this.pusher.subscribePermissionSyncChannel();
    this.sessionChannel = this.pusher.subscribeSessionChannel();
  }

  private setRoles() {
    const roles = _.pluck(this.auth.user.user.roles, 'name');
    this.permissionsService.loadPermissions(roles);
  }

  private authorizeSession(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const session = this.auth.user.sessionKey;
      this.http.get(`${environment.apiUrl}api/Authorize-Session-By-Session-name/${session}`)
        .pipe(take(1))
        .subscribe(res => {
          resolve(res);
        }, error => reject(error));
    });
  }

  private isMaintenance() {
    this.maintenanceService.getMaintenanceState()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        let show = true;
        const perm = this.permissionsService.getPermissions();
        for (const permission in perm) {
          if (permission === 'Developer') {
            show = false;
          }
        }
        if (result?.Status === 'true' && show) {
          this.maintenanceDialogRef = this.dialog.open(MaintenanceComponent, {
            data: result,
            panelClass: 'no-padding-dialog',
            disableClose: true
          });
        }
      });
  }

  private getStates() {
    this.lateStateService.getStatus()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        console.log(result);

        if (result?.length > 0) {
          this.lateStateService.open(result);
        }

      }, error => {
        this.error.onError(error);
      });
  }
}
