import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';

import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {ToolbarComponent} from 'app/layout/components/toolbar/toolbar.component';
import {AvatarModule} from 'ngx-avatar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SearchBarComponent} from './search-bars/search-bar/search-bar.component';
import {DirectivesModule} from '../../../shared/directives/directives.module';
import {NotificationComponent} from './notification/notification.component';
import {MatDialogModule} from '@angular/material/dialog';
import {CreateBugReportsComponent} from '../create-bug-reports/create-bug-reports.component';
import {MobileSearchBarComponent} from './search-bars/mobile-search-bar/mobile-search-bar.component';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';

@NgModule({
  declarations: [
    ToolbarComponent,
    SearchBarComponent,
    NotificationComponent,
    CreateBugReportsComponent,
    MobileSearchBarComponent,
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatToolbarModule,

    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    AvatarModule,
    MatTooltipModule,
    MatCardModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    DirectivesModule,
    ToastModule
  ],
  exports: [
    ToolbarComponent,
    NotificationComponent,
  ],
  providers: [
    MessageService
  ]
})
export class ToolbarModule {
}
