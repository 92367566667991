import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  SetSelectedLocationView,
  SetSelectedLocationTableColumns,
  SetDefaultLocationTableColumns
} from '../actions/locations.actions';

export class LocationsStateModel {
  // 0 => NATIVE || 1 => TABLE
  selectedLocationView: number;
  selectedTableColumns: string[];
}

@State<LocationsStateModel>({
  name: 'LocationsState',
  defaults: {
    selectedLocationView: 1,
    selectedTableColumns: ['cegnev', 'orszag', 'varos', 'iranyito', 'address'],
  } as LocationsStateModel,
})

@Injectable()
export class LocationsState {
  @Selector()
  static getSelectedLocationView(state: LocationsStateModel): any {
    return state.selectedLocationView;
  }

  @Selector()
  static getSelectedTableColumns(state: LocationsStateModel): any {
    return state.selectedTableColumns;
  }

  @Action(SetSelectedLocationView)
  setSelectedLocationView({getState, patchState}: StateContext<LocationsStateModel>, {payload}: SetSelectedLocationView) {
    return patchState({
      selectedLocationView: payload,
    });
  }

  @Action(SetSelectedLocationTableColumns)
  setSelectedTableColumns({getState, patchState}: StateContext<LocationsStateModel>, {payload}: SetSelectedLocationTableColumns) {
    return patchState({
      selectedTableColumns: payload,
    });
  }

  @Action(SetDefaultLocationTableColumns)
  setDefaultLocationTableColumns({getState, patchState}: StateContext<LocationsStateModel>) {
    return patchState({
      selectedTableColumns: ['cegnev', 'orszag', 'varos', 'iranyito', 'address']
    });
  }
}
