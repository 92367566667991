<div class="navbar-top" style="

/*background-image: url('assets/images/img/background.jpg');background-size: cover;*/

background-position: right !important;
background-size: cover;
          background: radial-gradient(25rem 18.75rem ellipse at bottom right,rgba(25, 118, 210, .5), rgba(46, 125, 50, 0.5)), url('https://www.mercurius.cloud/11.jpg') no-repeat center;

">
  <div *ngIf="user$ | async as u" style="color: white;">
    <ngx-avatar [name]="(u?.user?.lastname + ' ' + u?.user?.firstname)"></ngx-avatar>
    <p style="margin-bottom: 0;font-size: 16px;font-weight: 500;">{{u?.user?.lastname + ' ' + u?.user?.firstname}}</p>
    <p>{{u?.user?.email}}</p>
  </div>
</div>

<div class="navbar-scroll-container"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>
