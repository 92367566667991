import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './maintenance.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {MaterialModule} from "../../modules/material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {NgxChartsModule} from "@swimlane/ngx-charts";

@NgModule({
  declarations: [
    MaintenanceComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    NgxChartsModule
  ]
})
export class MaintenanceModule { }
