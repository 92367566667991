import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {NoPermissionComponent} from './no-permission.component';

@Injectable({
  providedIn: 'root'
})
export class NoPermissionService {
  dialogRef: MatDialogRef<NoPermissionComponent>;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
  ) { }

  open(data) {
    this.dialogRef = this.dialog.open(NoPermissionComponent, {
      data: data,
      panelClass: 'no-padding-dialog',
      disableClose: true,
      width: '500px',
    });
  }
}
