import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AddPermissions, LoginUser, LogoutUser, RemovePermissions, SetToken} from '../actions/auth.actions';
import {Injectable} from '@angular/core';
import {UserDataToken} from '../../shared/models/user-data-token';

export class AuthStateModel {
  user: UserDataToken;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: {} as UserDataToken,
  }
})
@Injectable()
export class AuthState {
  constructor(
    // private rolesService: NgxRolesService,
    // private permissionsService: NgxPermissionsService
  ) {
  }

  @Selector()
  static getUser(state: AuthStateModel) {
    // console.log('STATE, GET USER:');
    // console.log(JSON.stringify(state.user));
    return state.user;
  }

  // TODO PERMISSIONS
  // @Action(RemovePermissions)
  // removePermissions({getState, patchState}: StateContext<AuthStateModel>, {}: RemovePermissions) {
  //   this.permissionsService.flushPermissions();
  // }

  // @Action(AddPermissions)
  // addPermissions({getState, patchState}: StateContext<AuthStateModel>, {}: AddPermissions) {
  //   const state = getState();
  //
  //   for (let i = 0; i < state.user.user.roles.length; i++) {
  //     state.user.user.roles[i].permissions.forEach(p => {
  //       // debugger;
  //       this.permissionsService.addPermission([p.name]);
  //     });
  //   }
  // }

  @Action(LoginUser)
  loginUser({getState, patchState}: StateContext<AuthStateModel>, {user}: LoginUser) {
    patchState({
      user: user,
    });
  }

  @Action(LogoutUser)
  logoutUser({getState, patchState}: StateContext<AuthStateModel>, {}: LogoutUser) {
    patchState({
      user: {} as UserDataToken,
    });
  }

  @Action(LogoutUser)
  setToken({getState, patchState}: StateContext<AuthStateModel>, {token}: SetToken) {
    const state = getState();

    if (state.user.token) {
      state.user.token = token;
    }

  }
}
