import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseSidebarModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {NavbarModule} from 'app/layout/components/navbar/navbar.module';
import {ToolbarModule} from 'app/layout/components/toolbar/toolbar.module';
import {LayoutComponent} from 'app/layout/layout/layout.component';
import {MatDialogsModule} from '../../shared/dialogs/mat-dialogs/mat-dialogs.module';
import {MatDialogModule} from '@angular/material/dialog';
import {InvalidSessionModule} from '../../shared/dialogs/invalid-session/invalid-session.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {LateStatusModule} from '../../shared/dialogs/late-status/late-status.module';

@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    RouterModule,

    FuseSharedModule,
    FuseSidebarModule,

    NavbarModule,

    ToolbarModule,
    MatDialogModule,
    MatDialogsModule,
    InvalidSessionModule,
    LateStatusModule,
    MatSnackBarModule,
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule {
}
