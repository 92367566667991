import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Notification} from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) {
  }

  getAllNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${environment.apiUrl}api/get-notifications`);
  }

  deleteNotification(id) {
    return this.http.get(`${environment.apiUrl}api/del-notification/${id}`);
  }

  deleteAllNotif() {
    return this.http.get(`${environment.apiUrl}api/del-all-notifications`);
  }
}

