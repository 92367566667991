export class ToggleMute {
  static readonly type = '[Interface] Toggle MuteNotification';

  constructor(public payload: boolean) {
  }
}

export class SetIsLoading {
  static readonly type = '[Interface] Set isLoading';

  constructor(public payload: boolean) {
  }
}
