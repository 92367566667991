import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-update-location-status',
  templateUrl: './update-status-dialog.component.html',
  styleUrls: ['./update-status-dialog.component.scss']
})
export class UpdateStatusDialogComponent implements OnInit {
  data: any;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data,
  ) {
  }

  ngOnInit(): void {
    this.data = this._data;
    this.form = this._formBuilder.group({
      status: ['', Validators.required],
      status_data: ['', Validators.required]
    });
  }

  send() {
    this.dialogRef.close(this.form.value);
  }
}
