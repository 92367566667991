import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {BaseComponent} from '../../components/base/base.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UpdateStatusDialogComponent} from '../../../main/pages/orders/components/update-status-dialog/update-status-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {LateStatusService} from './late-status.service';
import {ErrorService} from '../../services/error.service';

@Component({
  selector: 'app-no-permission',
  templateUrl: './late-status.component.html',
  styleUrls: ['./late-status.component.scss']
})
export class LateStatusComponent extends BaseComponent implements OnInit {
  updateStatusDialogRef: MatDialogRef<UpdateStatusDialogComponent>;
  message: any[] = [];
  ColumnMode = ColumnMode;

  constructor(
    public dialogRef: MatDialogRef<LateStatusComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public snackBar: MatSnackBar,
    private lateStatusService: LateStatusService,
    private error: ErrorService,
  ) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.message = [...this.data];
    console.log(this.message);
  }

  updateAddressStatus(data) {
    let type;
    switch (data.locationType) {
      case 'Loading Points':
        type = 0;
        break;
      case 'Unloading Points':
        type = 1;
        break;
      case 'Clearance Points':
        type = 2;
        break;
    }

    this.updateStatusDialogRef = this.dialog.open(UpdateStatusDialogComponent, {
      width: '300px',
      data: {
        action: 'Update',
        type: type
      }
    });

    this.updateStatusDialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(_ => {
        if (_) {
          this.lateStatusService.updateStatus(_, type, data.locationId, data.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.snack('Solved successfully!');
              this.message.splice(this.message.findIndex(i => i.id === data.id), 1);
              this.message = [...this.message];
            }, error => {
              this.error.onError(error);
            });
        }
      });
  }
}
