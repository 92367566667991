import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SearchResult} from '../models/searchResult';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getSearchResults(input): Observable<SearchResult[]> {
    return this.http.post<SearchResult[]>(`${this.apiUrl}/api/search`, {input: input.toString()});
  }
}
