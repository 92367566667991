import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BugReportService {

  constructor(private http: HttpClient) {
  }

  sendBugReport(title: string, message: string, url: string) {
    return this.http.post(`${environment.apiUrl}api/create-bug-report`, {title: title, message: message, url: url});
  }
}
