export class SetSelectedOrderView {
  static readonly type = '[Orders] Set Selected Order View';

  constructor(public payload: number) {
  }
}

export class SetSelectedTableColumns {
  static readonly type = '[Orders] Set Selected Order Table Columns';

  constructor(public payload: string[]) {
  }
}
export class SetDefaultTableColumns {
  static readonly type = '[Interface] Set Selected Order Table Columns';

  constructor() {
  }
}
