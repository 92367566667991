<!--<h1 matDialogTitle>{{title}}</h1>-->
<!--<div mat-dialog-content>{{confirmMessage}}</div>-->
<!--<div mat-dialog-actions class="pt-24">-->
<!--  <button mat-raised-button class="mat-accent mr-16" (click)="dialogRef.close(true)">{{confirmTextButton}}</button>-->
<!--  <button mat-button (click)="dialogRef.close(false)">{{cancelTextButton}}</button>-->
<!--</div>-->


<div [@animate]="{value:'*',params:{delay:'2000ms',y:'-100px'}}" class="parent">
  <mat-icon style="width: 50px; height: 50px; font-size: 50px">{{icon}}</mat-icon>
  <span class="title">{{title}}</span>
  <span class="message">{{confirmMessage}}</span>

  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <button mat-button color="primary" (click)="close(true)" style="margin: 1em; background: #228d18; color: #FFFFFF">{{confirmTextButton}}</button>
    <button mat-button (click)="close(false)" style="margin: 1em; background: #6e6e6e; color: #FFFFFF">{{cancelTextButton}}</button>
  </div>
</div>