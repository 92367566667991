<!--suppress TypeScriptUnresolvedVariable -->
<div class="mobile-search-bar" [ngClass]="{'expanded':!collapsed}">

  <div class="mobile-search-bar-content" [formGroup]="inputForm">

    <label for="mobile-search-bar-input">
      <button mat-icon-button class="mobile-search-bar-expander" aria-label="Expand Search Bar" (click)="expand()"
              *ngIf="collapsed">
        <mat-icon class="s-24 secondary-text">search</mat-icon>
      </button>
    </label>

    <input formControlName="search" #search id="mobile-search-bar-input" class="ml-24" type="text" placeholder="Search" fxFlex>

    <button mat-icon-button class="mobile-search-bar-collapser" (click)="collapse()"
            aria-label="Collapse Search Bar">
      <mat-icon class="s-24 secondary-text">close</mat-icon>
    </button>

  </div>

  <div class="search-dropdown mat-elevation-z1">
    <div *ngIf="inputForm.controls.search" class="content results" fxLayout="column">
      <mat-list *ngIf="!searchBarService.showResults" class="search-type-list">
        <mat-list-item (click)="setSearchFilter('adress:')">Locations</mat-list-item>
        <mat-list-item (click)="setSearchFilter('company:')">Companies</mat-list-item>
        <mat-list-item (click)="setSearchFilter('contact:')">Contact</mat-list-item>
        <mat-list-item (click)="setSearchFilter('order:')">Order</mat-list-item>
        <mat-list-item (click)="setSearchFilter('document:')">Documents</mat-list-item>
      </mat-list>

      <div *ngIf="searchBarService.showResults">
        <div style="max-height: 50vh;overflow: auto;" #resultsDiv *ngIf="searchResults as results">

          <div *ngIf="results?.adresses?.length > 0">
            <span class="create-edit-form-title">Locations</span>
            <mat-list>
              <mat-list-item [routerLink]="'/locations/view-location/' + adress?.id"
                             *ngFor="let adress of results?.adresses">
                {{adress.osszes}}
              </mat-list-item>
            </mat-list>
          </div>

          <div *ngIf="results?.companies?.length > 0">
            <span class="create-edit-form-title">Companies</span>
            <mat-list>
              <mat-list-item [routerLink]="'/companies/view-company/' + company?.id"
                             *ngFor="let company of results?.companies">
                {{company.cegnev}}
              </mat-list-item>
            </mat-list>
          </div>

          <div *ngIf="results?.order?.length > 0">
            <span class="create-edit-form-title">Orders</span>
            <mat-list>
              <mat-list-item [routerLink]="'/orders/view-order/' + order.id" *ngFor="let order of results?.order">
                {{order.azonosito}}
              </mat-list-item>
            </mat-list>
          </div>

          <div *ngIf="results?.document?.length > 0">
            <span class="create-edit-form-title">Documents</span>
            <mat-list>
              <mat-list-item (click)="openDocument(document)" *ngFor="let document of results?.document">
                {{document.name}}
              </mat-list-item>
            </mat-list>
          </div>


          <div *ngIf="results?.contacts?.length > 0">
            <span class="create-edit-form-title">Contacts</span>
            <mat-list>
              <div fxLayout="column" class="card-lite" *ngFor="let contact of results?.contacts">
                <span class="create-edit-form-title">{{contact.name}}</span>

                <div fxLayout="column" style="font-weight: 400">
                  <span><b>Company Name: </b>{{contact.company_name}}</span>
                  <span><b>Email: </b>{{contact.mail}}</span>
                  <span><b>Tel.: </b>{{contact.tel}}</span>
                  <span><b>Langugae: </b>{{contact.language}}</span>

                  <div>
                    <span><b>Works at: </b></span>
                    <span *ngIf="contact.type ===  0">Not defined</span>
                    <span *ngIf="contact.type ===  1">Booking</span>
                    <span *ngIf="contact.type ===  2">Freight forwarding</span>
                    <span *ngIf="contact.type ===  3">Storage</span>
                    <span *ngIf="contact.type ===  4">Office</span>
                    <span *ngIf="contact.type ===  5">Management</span>
                    <span *ngIf="contact.type ===  6">Production</span>
                    <span *ngIf="contact.type ===  7">I.T.</span></div>
                </div>
              </div>
            </mat-list>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>