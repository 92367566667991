import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SetDefaultTableColumns, SetSelectedOrderView, SetSelectedTableColumns} from '../actions/orders.actions';

export class OrdersStateModel {
  // 0 => NATIVE || 1 => TABLE
  selectedOrderView: number;
  selectedTableColumns: string[];
}

@State<OrdersStateModel>({
  name: 'OrdersState',
  defaults: {
    selectedOrderView: 1,
    selectedTableColumns: ['azonosito', 'bestellung_num', 'allapot', 'cegnev', 'sz_cegnev', 'osszeg', 'sz_osszeg'],
  } as OrdersStateModel,
})

@Injectable()
export class OrdersState {
  @Selector()
  static getSelectedOrderView(state: OrdersStateModel): any {
    return state.selectedOrderView;
  }

  @Selector()
  static getSelectedTableColumns(state: OrdersStateModel): any {
    return state.selectedTableColumns;
  }

  @Action(SetSelectedOrderView)
  setSelectedOrderView({getState, patchState}: StateContext<OrdersStateModel>, {payload}: SetSelectedOrderView) {
    return patchState({
      selectedOrderView: payload,
    });
  }

  @Action(SetSelectedTableColumns)
  setSelectedTableColumns({getState, patchState}: StateContext<OrdersStateModel>, {payload}: SetSelectedTableColumns) {
    return patchState({
      selectedTableColumns: payload,
    });
  }

  @Action(SetDefaultTableColumns)
  setDefaultTableColumns({patchState}: StateContext<OrdersStateModel>) {
    return patchState({
      selectedTableColumns: ['azonosito', 'bestellung_num', 'allapot', 'cegnev', 'sz_cegnev', 'osszeg', 'sz_osszeg']
    });
  }
}
