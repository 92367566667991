import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LateStatusComponent} from './late-status.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatIconModule} from '@angular/material/icon';
import {MaterialModule} from '../../modules/material.module';

@NgModule({
  declarations: [
    LateStatusComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    RouterModule,
    NgxDatatableModule,
    MatIconModule,
    MaterialModule
  ]
})
export class LateStatusModule {
}
