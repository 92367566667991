import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileShareService {
  constructor(
    private http: HttpClient
  ) {
  }

  getSharedDocData(token: any) {
    return this.http.get<any>(`${environment.apiUrl}api/get-shared-doc-data/${token}`);
  }

  authSharedDoc(token: string, password: string) {
    return this.http.post(`${environment.apiUrl}api/auth-shared-doc`, {token: token, password: password});
  }


  // tslint:disable-next-line:variable-name
  shareFile(name: string, mail: string, doc_type: number, doc_id: number, temp: boolean, show_creator: boolean) {
    return this.http.post(`${environment.apiUrl}api/create-shared-doc`, {name: name, mail: mail, doc_type: doc_type, doc_id: doc_id, temp: temp, show_creator: show_creator});
  }




}
