<mat-toolbar color="primary">Create Bug Report</mat-toolbar>

<div [formGroup]="form" class="create-bug-report-parent">
  <mat-form-field appearance="outline" class="title">
    <mat-label>Title</mat-label>
    <input aria-label="title" class="data-hj-allow" formControlName="title" matInput required>
    <mat-icon *ngIf="form.controls.title.valid" matSuffix style="color:green;">done</mat-icon>
    <mat-icon *ngIf="!form.controls.title.valid" matSuffix style="color:red;">clear</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="outline" class="description">
    <mat-label>Description</mat-label>
    <textarea aria-label="description" class="data-hj-allow" formControlName="description" matInput required
              type="text"></textarea>
    <mat-icon *ngIf="form.controls.description.valid" matSuffix style="color:green;">done</mat-icon>
    <mat-icon *ngIf="!form.controls.description.valid" matSuffix style="color:red;">clear</mat-icon>
  </mat-form-field>
</div>

<button (click)="sendReport()" [disabled]="form.invalid" color="primary" mat-raised-button
        style="margin: 0 1em 1em 1em; float: right">
  <mat-icon style="margin-right:5px;">send</mat-icon>
  <span>Send Report</span>
</button>
