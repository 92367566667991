import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvalidSessionComponent } from './invalid-session.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    InvalidSessionComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    RouterModule,
    FlexLayoutModule
  ]
})
export class InvalidSessionModule { }
