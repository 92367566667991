import {UserDataToken} from '../../shared/models/user-data-token';

export class SetToken {
  static readonly type = '[AUTH] Set';

  constructor(public token: string) {
  }
}

export class LogoutUser {
  static readonly type = '[AUTH] Logout User';
}

export class LoginUser {
  static readonly type = '[AUTH] Login User';

  constructor(public user: UserDataToken) {
  }
}

export class RemovePermissions {
  static readonly type = '[AUTH] Remove All Permissions';
}

export class AddPermissions {
  static readonly type = '[AUTH] Add Permissions';
}

